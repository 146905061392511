import { View, NumberIncrement } from '@/components'
import { NumberIncrementProps } from '@codeleap/web'

export type CurrencyWidgetType = NumberIncrementProps & {}

export const CurrencyWidget = ({ ...rest }) => (
  <View variants={['column']}>
    <NumberIncrement
      {...rest}
      separator={','}
      prefix='£ '
      variants={['noIncrement']}
    />
  </View>
)
