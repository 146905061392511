import { variantProvider } from '@/app'
import { View, ActionIcon, CompanyStrengthsItem } from '@/components'
import { MiscUtils, useIsMobile } from '@/utils'
import { useBreakpointMatch } from '@codeleap/web'
import Slider from 'react-slick'

const Arrow = (props) => {

  const { className, style, onClick, icon } = props

  return (
    <div className={className} style={{ ...style, ...styles.arrowWrapper }}>
      <ActionIcon
        name={icon}
        variants={['minimal', 'iconSize:4']}
        onPress={onClick}
        debugName='Slider Prev Arrow'
      />
    </div>
  )
}

export const CompanyStrengthsSlider = () => {

  const numColumns = useBreakpointMatch({
    'small': 1,
    'huge': 3,
  })

  const numRows = useBreakpointMatch({
    'mid': 1,
    'huge': 2,
  })

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: numColumns,
    rows: numRows,
    slidesToScroll: numColumns,
    prevArrow: <Arrow icon={'chevron-left'} />,
    nextArrow: <Arrow icon={'chevron-right'} />,
  }

  const renderStrenghtsItem = (item, key) => {
    return (
      <View key={key} variants={['paddingHorizontal:3.75', 'paddingVertical:2.5']}>
        <CompanyStrengthsItem
          title={item?.type}
          description={item?.description}
          icon={item?.icon}
        />
      </View>
    )
  }

  return (
    <Slider {...sliderSettings} style={styles.sliderContainer} >
      {MiscUtils.companyStrenghts.map((item, index) => renderStrenghtsItem(item, index))}
    </Slider>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  arrowWrapper: {
    zIndex: 1,
    height: '100%',
    width: '10%',
    ...theme.presets.flex,
    ...theme.presets.center,
  },
  sliderContainer: {
    ...theme.presets.center,
    ...theme.presets.flex,
    ...theme.presets.center,
  },
}), true)
