import { useEffect, useMemo, useRef, useState } from 'react'
import { ActionIcon, Button, Dropzone, Modal, Text, View } from '@/components'
import { DropzoneFile, DropzoneFileRejection, DropzoneRef, ProgressCircle } from '@codeleap/web'
import { APIClient } from '@/services'
import { ComponentVariants, StylesOf, useDefaultComponentStyle } from '@codeleap/common'
import { UploadSettlementStyles, UploadSettlementComposition } from '@/app/stylesheets/UploadSettlement'
import { useGetStyles } from '@/utils'
import { Dispute } from '@/types'

const thresholds = [
  { limit: 50000000, value: 1500 },
  { limit: 10000000, value: 1000 },
  { limit: 1000000, value: 500 },
  { limit: 0, value: 150 },
]

const UploadingStatus = ({ isDone, file, styles }) => {
  const [progress, setProgress] = useState(0)
  const { getStyles } = useGetStyles(styles)

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isDone) {
      clearInterval(timer)
      setProgress(100)
    } else {
      timer = setInterval(() => {
        setProgress(prev => (prev < 90 ? prev + 5 : prev))
      }, timestamp ?? 150)
    }
    return () => clearInterval(timer)
  }, [isDone, progress])

  const timestamp = useMemo(() => thresholds.find(threshold => file?.size > threshold.limit ?? 0).value, [file])

  return (
    <View css={styles.uploadingWrapper}>
      <ProgressCircle progress={progress} size={100} styles={getStyles('uploadingProgress')} showProgress />
      <Text css={styles.uploadingText} text={'Uploading File'} />
      <Text css={styles.uploadingName} text={file?.name ?? 'File.pdf'} />
    </View>
  )
}

type UploadSettlementProps = ComponentVariants<typeof UploadSettlementStyles> & {
  styles?: StylesOf<UploadSettlementComposition>
  style?: StyleSheet
  visible: boolean
  toggle: () => void
  disputeId: Dispute['id']
}

export function UploadSettlement({ variants, responsiveVariants, styles, style, ...props }: UploadSettlementProps) {
  const { toggle, disputeId } = props
  const dropzoneRef = useRef<DropzoneRef>(null)
  const [uploading, setUploading] = useState<'idle' | 'uploading' | 'done'>('idle')
  const [acceptedFiles, setAcceptedFiles] = useState<DropzoneFile[]>([])
  const [rejectedFiles, setRejectedFiles] = useState<DropzoneFileRejection[]>([])

  const { create } = APIClient.Settlements.settlementsManager.useCreate()

  const hasFile = !!acceptedFiles.length

  const variantStyles = useDefaultComponentStyle<'u:UploadSettlement', typeof UploadSettlementStyles>(
    'u:UploadSettlement',
    {
      variants,
      rootElement: 'wrapper',
      styles,
      responsiveVariants,
    },
  )

  const { getStyles } = useGetStyles(variantStyles)

  const handleClose = () => {
    dropzoneRef.current?.clear()
    setAcceptedFiles([])
    setRejectedFiles([])
    setUploading('idle')
    toggle()
  }

  const handlePress = async () => {
    if (!!hasFile) {
      setUploading('uploading')
      setTimeout(async () => {
        await create({ dispute: disputeId, require_signature: false, file: acceptedFiles[0] })
        setUploading('done')
      }, 2000)
    } else dropzoneRef.current?.open()
  }

  const Header = () => (
    <View css={variantStyles.headerWrapper}>
      <Text css={variantStyles.headerTitle} text={'Upload Document'} />
      <ActionIcon
        debugName={'Icon close modal'}
        name={'x'}
        styles={getStyles('headerActionIcon')}
        variants={['iconSize:3', 'minimal', 'neutral10']}
        onPress={handleClose}
      />
    </View>
  )

  const Footer = () => {
    const text = uploading === 'done' ? 'Done' : hasFile ? 'Upload' : 'Choose'

    return (
      <Button
        styles={getStyles('footerButton')}
        text={text}
        debugName={`Pressed Footer Button ${text}`}
        onPress={() => (uploading === 'done' ? handleClose() : handlePress())}
        disabled={uploading === 'uploading'}
      />
    )
  }

  return (
    <Modal
      {...props}
      showClose={false}
      toggle={toggle}
      styles={getStyles('modal')}
      variants={['centered']}
      css={[variantStyles.wrapper, style]}
    >
      <View variants={['gap:4', 'column']}>
        <Header />
        {uploading !== 'idle' ? (
          <UploadingStatus isDone={uploading === 'done'} file={acceptedFiles[0]} styles={variantStyles} />
        ) : (
          <Dropzone
            ref={dropzoneRef}
            icon={'file-plus_'}
            styles={getStyles('dropzone')}
            variants={['uploadSettlement']}
            acceptedFiles={acceptedFiles}
            setAcceptedFiles={setAcceptedFiles}
            rejectedFiles={rejectedFiles}
            setRejectedFiles={setRejectedFiles}
            onDrop={setAcceptedFiles}
            accept={{ 'application/pdf': ['.pdf'] }}
            placeholder='Please choose a PDF file for upload, or simply drag and drop it here.'
          />
        )}
        <Footer />
      </View>
    </Modal>
  )
}
