import { useAppSelector } from '@/redux'
import { DisputeUtils } from '../disputes'

export const useDisputeCategories = (category?: string) => {
  const disputeCategories = useAppSelector(store => store.AppOptions.disputeCategories)

  const disputeCategory = disputeCategories.find(item => item.type === category)

  return {
    disputeCategories,
    formatedDisputeCategories: DisputeUtils.formatDisputeCategories(disputeCategories),
    disputeCategory,
  }
}

