import { View, Text, Link } from '@/components'
import { StylesOf } from '@codeleap/common'
import { FooterComposition } from '../../../app/stylesheets/Footer'
import { Settings } from '@/app'

export type FooterDisclaimersProps = {
  variantStyles?: StylesOf<FooterComposition>
}

export const FooterDisclaimers = (props: FooterDisclaimersProps) => {
  const { variantStyles } = props

  return (
    <View css={variantStyles.disclaimersWrapper}>
      <Text css={variantStyles.disclaimerText}>
        Hexameron Limited is registered in England & Wales, Company no.
        14336297.
      </Text>
      <Text css={variantStyles.disclaimerText}>
        By using our website you agree to the {''}
        <Link
          css={variantStyles.disclaimerText}
          to={Settings.ContactINFO.TermsAndConditions}
          target='_blank'
        >
          {' '}
          terms of use.
        </Link>
      </Text>
    </View>
  )
}
