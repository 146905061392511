import { api } from '@/app'
import { OTPCode, Profile } from '@/types'

const BASE_URL = 'profiles/'

export const generateOTP = async (body: {
  email: Profile['email']
  verify_email: boolean
}) => {
  const response = await api.post(`${BASE_URL}generate_otp/`, body)
  return response.data
}

export const checkOTP = async (params: {
  email: Profile['email']
  code: OTPCode['code']
}) => {
  const response = await api.get(`${BASE_URL}check_otp/`, {
    params,
  })
  return response.data
}

