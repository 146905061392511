import { PropsOf } from '@codeleap/common'
import { ModalProps } from '@codeleap/web'
import { Modal, View } from '@/components'
import { useAppSelector, ModalName, AppStatus } from '@/redux'
import { AuthModalHeader } from './AuthModalHeader'
import { variantProvider } from '@/app'
import React from 'react'

type AuthModalProps = Partial<ModalProps> & {
  image?: PropsOf<typeof Image>['source']
  name: ModalName
  centered?: boolean
  imageHeight?: number
}

export const AuthModal = (props: AuthModalProps) => {

  const {
    children,
    image,
    name,
    imageHeight,
    closable = true,
    centered = false,
    ...modalProps
  } = props

  const status = useAppSelector(store => store.AppStatus.status)
  const isOpen = useAppSelector(store => store.AppStatus.modals[name])

  return (
    <Modal
      renderHeader={() => <AuthModalHeader imageHeight={imageHeight} showClose={closable} name={name} image={image} centered={centered} />}
      visible={isOpen && !(status === 'loading' || status === 'done')}
      responsiveVariants={{
        small: ['fullscreen'] as any,
      }}
      styles={{
        box: styles.modalBox,
        body: styles.modalBody,
      }}
      {...modalProps}
      toggle={() => {
        modalProps?.toggle?.()
        AppStatus.setModal([name, false])
      }}
    >
      <View css={[styles.formWrapper]}>
        {children}
      </View>
    </Modal>
  )
}

const MODAL_WIDTH = 480

const styles = variantProvider.createComponentStyle((theme) => ({
  modalBox: {
    ...theme.spacing.padding(0),
    // @ts-ignore
    borderRadius: theme.borderRadius.medium,
    maxWidth: MODAL_WIDTH,
  },
  modalBody: {
    flex: 1,
  },
  formWrapper: {
    ...theme.spacing.padding(4),
  },
}), true)
