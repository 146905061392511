import { variantProvider } from '../theme'

export type SettlementListComposition = 'wrapper' | 'header' | 'emptyWrapper' | 'iconWrapper' | 'icon'

const createSettlementListStyle = variantProvider.createVariantFactory<SettlementListComposition>()

export const SettlementListStyles = {
  default: createSettlementListStyle(theme => ({
    wrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      height: window.innerHeight - 257,
      [theme.media.down('midish')]: {
        height: window.innerHeight - 201,
      },
      [theme.media.down('smallish')]: {
        height: window.innerHeight - 161,
      },
    },
  })),
}
