import { Settings } from '../app/Settings'
import { LoggerAnalytics, useEffect, useRef } from '@codeleap/common'

import { getFirebase } from './firebaseApp'
import { QueryKeys } from './api/session/queryKeys'
import { IS_SSR } from '@/app/constants'
import { Dispute } from '@/types'

const enabled = Settings.EnableAnalytics && !IS_SSR

export const AppAnalytics = new LoggerAnalytics.Analytics({
  init: async () => {
    if (!enabled) return
    const fb = await getFirebase()

    const analytics = fb?.analytics()

    analytics.setAnalyticsCollectionEnabled(true)

    analytics.logEvent('page_view', {})

  },
  onEvent: async (eventArgs) => {
    if (!enabled) return
    const fb = await getFirebase()
    const analytics = fb.analytics()
    analytics.logEvent('app_action', eventArgs)

  },
  prepareData: () => {
    if (!enabled) return
    const extraData = {
      user: QueryKeys.me.getData(),
    }

    return extraData
  },
  onInteraction: async (eventArgs) => {
    if (!enabled) return
    const fb = await getFirebase()
    const analytics = fb.analytics()
    analytics.logEvent('user_interaction', eventArgs)

  },
}, Settings)

function viewDispute(dispute: Dispute = null) {
  if (!dispute) return false
  AppAnalytics.event('view_dispute', {
    dispute_id: dispute.id,
    dispute_name: dispute.name,
  })
}

const analyticsEvents = {
  viewDispute,
} as const

type AnalyticsEvent = keyof typeof analyticsEvents

function useAnalyticsEvent<K extends AnalyticsEvent>(name: K, args: Parameters<typeof analyticsEvents[K]>) {
  const sent = useRef(false)
  useEffect(() => {
    if (sent.current) return

    const e = analyticsEvents[name]

    sent.current = e(...args)
  }, [name, ...args])
}

export const AnalyticsEvents = {
  ...analyticsEvents,
  use: useAnalyticsEvent,
}
