import { AppImages } from '@/app'

export const onboardingPages = [
  {
    title: 'Welcome to your Main Dashboard',
    descriptions: [
      'Here you can see your pending cases at a glance.',
      'To get started, click on a case',
    ],
    image: AppImages.Onboarding1,
  },
  {
    title: 'This is your Case Dashboard',
    descriptions: [
      'Here you can find important information about the case, and can message either the mediator privately, or the other parties to the dispute.',
    ],
    image: AppImages.Onboarding2,
  },
  {
    title: 'Key Rules',
    descriptions: [
      'Please remember the following key rules:',
      'Everything that is said or takes place in a MediationX mediation is confidential, and must not be shared with anyone.',
    ],
    image: AppImages.Onboarding4,
  },
]

export const mediatorOnboardingPages = [
  {
    title: 'Welcome to your Main Dashboard',
    descriptions: [
      'Here you can see your pending cases at a glance. Cases allocated to you will appear here. To get started, click on a case.',
    ],
    image: AppImages.Onboarding1,
  },
  {
    title: 'This is your Case Dashboard',
    descriptions: [
      'Here you can find important information about the case, and can message either each party privately, or all parties to the dispute together.',
    ],
    image: AppImages.Onboarding2,
  },
  {
    title: 'Settlement',
    descriptions: [
      'If parties are amenable to settlement, you can invite them to review and electronically sign a settlement agreement which they have prepared.',
      'You will need to upload the settlement agreement PDF into the settlement tab and mark it as ready to sign.',
      'For relatively simple settlements, you can draft and prepare the PDF, after taking approval from Head Office.',
    ],
    image: AppImages.Onboarding3,
  },
  {
    title: 'Key Rules',
    descriptions: [
      'Please remember the following key rules:',
      'Everything that is said or takes place in a MediationX mediation is confidential, and must not be shared with anyone.',
      'You must immediately stop and inform support@mediationx.com if you realise there is a possibility of conflict of interest, or in the event of any other situation requiring ethical or other guidance.',
    ],
    image: AppImages.Onboarding4,
  },
]
