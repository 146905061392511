import { View, Button, Text } from '@/components'

type YesNoWidgetProps = {
  id?: string
  value: boolean
  label?: string
  onValueChange?: (value: boolean) => void
}

export const YesNoWidget = ({
  id,
  label,
  value,
  onValueChange = () => {},
  ...rest
}: YesNoWidgetProps) => {
  const debugName = `YesNoWidget-${id}`

  return (
    <View variants={['column', 'gap:2']} {...rest}>
      {!!label ? <Text text={label} variants={['p2', 'neutral-7']} /> : null}

      <Button
        text='Yes'
        debugName={`${debugName}:Yes`}
        variants={[value ? 'select:selected' : 'select', 'fullWidth']}
        rightIcon={value ? 'check' : null}
        onPress={() => onValueChange(true)}
      />

      <Button
        text='No'
        debugName={`${debugName}:No`}
        variants={[!value ? 'select:selected' : 'select', 'fullWidth']}
        rightIcon={!value ? 'check' : null}
        onPress={() => onValueChange(false)}
      />
    </View>
  )
}
