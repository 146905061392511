import React from 'react'
import { View, Logo, CenterWrapper } from '@/components'
import { useDefaultComponentStyle, ComponentVariants, StylesOf, PropsOf, getNestedStylesByKey } from '@codeleap/common'
import { FooterComposition, AppFooterStyles } from '../../app/stylesheets/Footer'
import { FooterContact, FooterDisclaimers, FooterNavigate } from './Sections'
import { AppImages } from '@/app'

export type FooterProps =
  Omit<PropsOf<typeof CenterWrapper>, 'variants' | 'styles' | 'responsiveVariants'> &
  ComponentVariants<typeof AppFooterStyles> &
  {
    styles?: StylesOf<FooterComposition>
  }

const FooterComponent = (props: FooterProps) => {
  const { responsiveVariants, styles, variants, ...rest } = props

  const variantStyles = useDefaultComponentStyle<'u:Footer', typeof AppFooterStyles>('u:Footer', {
    responsiveVariants,
    styles,
    variants,
  })

  const logoStyles = getNestedStylesByKey('logo', variantStyles)

  return (
    <CenterWrapper
      styles={{
        wrapper: variantStyles.wrapper,
        innerWrapper: variantStyles.innerWrapper,
      }}
      {...rest}
    >
      <Logo
        debugName='Footer Logo'
        styles={logoStyles}
        responsive={false}
        switchServerOnPress
        image={AppImages.DarkLogo}
      />
      <View css={variantStyles.content}>
        <View css={variantStyles.topContent}>
          <FooterNavigate variantStyles={variantStyles} />
          <FooterContact variantStyles={variantStyles} />
        </View>

        <View css={variantStyles.separator} />

        <View css={variantStyles.bottomContent}>
          <FooterDisclaimers variantStyles={variantStyles} />
        </View>
      </View>
    </CenterWrapper>
  )
}

export const Footer = React.memo(FooterComponent, () => true)
