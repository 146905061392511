import React from 'react'
import { Button, Text, View } from '@/components'
import { Navigation } from '@/utils'
import { AnyFunction, TypeGuards, useI18N } from '@codeleap/common'
import { AppStatus } from '@/redux'
import { variantProvider } from '@/app'
import { APIClient } from '@/services'

type ForgotPasswordAfterFormProps = {
  onResend?: AnyFunction
}

export const ForgotPasswordAfterForm = ({
  onResend,
}: ForgotPasswordAfterFormProps) => {
  const { t } = useI18N()
  const { isLoggedIn } = APIClient.Session.useSession()

  const onTryResendEmail = () => {
    //Navigation.navigate('Auth.ForgotPassword')
    if (TypeGuards.isFunction(onResend)) onResend()

    AppStatus.transitionModals(['forgotAfter', 'forgotBefore'])
  }

  const toLogin = () => {
    AppStatus.setModal('forgotAfter')
    Navigation.navigate('Auth.Login')
  }

  return (
    <View variants={['flex', 'column', 'gap:4']}>
      <View variants={['column', 'gap:1', 'marginBottom:15']}>
        <Text variants={['h1', 'extraBold']} text={t('forgotAfter.title')} />

        <Text
          variants={['p1']}
          text={t('forgotAfter.description')}
          style={styles.description}
        />
      </View>

      <View
        variants={[
          'column',
          'gap:2',
          'marginTop:auto',
          'alignSelfCenter',
          'fullWidth',
        ]}
      >
        <Button
          onPress={onTryResendEmail}
          debugName={'Resend email'}
          variants={[
            'outline',
            'large',
            'row',
            'gap:0.5',
            'alignCenter',
            'justifyCenter',
            'paddingHorizontal:2',
          ]}
        >
          <Text
            text={t('forgotAfter.resendTxt1')}
            variants={['medium', 'alignSelfCenter', 'row', 'color:neutral10']}
          />
          <Text
            text={t('forgotAfter.resendTxt2')}
            variants={['medium', 'color:primary3']}
          />
        </Button>

        {!isLoggedIn ? (
          <Button
            text={t('actions.login')}
            variants={['minimal']}
            onPress={toLogin}
            debugName='Go to Login'
          />
        ) : null}
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    description: {
      letterSpacing: '-0.16px',
    },
  }),
  true,
)
