import { createForm, yup } from '@codeleap/common'
import { disputeInfo } from './disputeInfo'
import { partyInfo } from './partyInfo'

export const editDispute = () => {
  const disputeInfoForm = disputeInfo()
  const partyInfoForm = partyInfo()

  return createForm('editDispute', {
    category: {
      type: 'select',
      label: 'Type of dispute',
      placeholder: 'Personal Dispute',
      validate: yup
        .mixed()
        .required('This is a required field'),
    },
    price: {
      ...disputeInfoForm.config.price,
      label: 'Dispute value',
      placeholder: '10000',
    },
    description: disputeInfoForm.config.description,
    status: {
      type: 'select',
      label: 'Status',
      placeholder: 'Open',
      validate: yup
        .mixed()
        .required('This is a required field'),
    },
    first_party_name: partyInfoForm.config.full_name,
    first_party_email: partyInfoForm.config.email,
    first_party_phone: partyInfoForm.config.phone,
    first_party_address: {
      ...partyInfoForm.config.address,
      placeholder: 'Enter address',
    },
    second_party_name: partyInfoForm.config.full_name,
    second_party_email: partyInfoForm.config.email,
    second_party_phone: partyInfoForm.config.phone,
    second_party_address: {
      ...partyInfoForm.config.address,
      placeholder: 'Enter address',
    },
  })
}
