import { View, Text, TextInput, Select } from '@/components'
import { Dispute } from '@/types'
import { DisputeUtils, useDisputeCategories } from '@/utils'
import { React, variantProvider } from '@/app'

type DisputeDetailsFormProps = {
  dispute: Dispute
  form: any
}

type FieldWrapperProps = {
  children: React.ReactNode
  errorMessage: string
}

const FieldWrapper = (props: FieldWrapperProps) => {
  const {
    children,
    errorMessage,
  } = props

  return (
    <View variants={['column']}>
      {children}
      <Text text={errorMessage} variants={['paddingLeft:2', 'p4', 'color:destructive2', 'marginTop:1']} />
    </View>
  )

}

export const DisputeDetailsForm = (props: DisputeDetailsFormProps) => {
  const { dispute, form } = props

  const { formatedDisputeCategories } = useDisputeCategories(
    dispute.category?.type,
  )

  const statusOptions = DisputeUtils.availableStatus.map((item) => ({
    label: item.text,
    value: item.value,
    debugName: item.debugName,
  })).filter(item => item.value !== 'pending')

  const isFieldValid = (field: string) => {
    return form.validateField(field).valid
  }

  const getFieldErrorMessage = (field: string) => {
    return form.validateField(field).message
  }

  return (
    <View variants={['column', 'flex', 'gap:2', 'paddingTop:1']}>
      <Text text={'About the case'} variants={['p2', 'color:neutral7']} />

      <View
        variants={[
          'flex',
          'backgroundColor:neutral1',
          'padding:2',
          'column',
          'border-radius:small',
          'gap:2',
        ]}
        responsiveVariants={{
          small: ['gap:0', 'paddingBottom:0'],
        }}
      >
        <FieldWrapper errorMessage={getFieldErrorMessage('description')}>
          <TextInput
            {...form.register('description')}
            _error={!isFieldValid('description')}
            variants={['noError']}
            multiline
          />
        </FieldWrapper>

        <Select
          {...form.register('category')}
          options={formatedDisputeCategories}
        />

        <View variants={['row', 'gap:2']}>
          <Select
            {...form.register('status')}
            options={statusOptions}
            variants={['fullWidth']}
            styles={{ wrapper: styles.selectInput }}
          />

          <FieldWrapper errorMessage={getFieldErrorMessage('price')}>
            <TextInput
              {...form.register('price')}
              styles={{ wrapper: styles.selectInput }}
              leftIcon={{ name: 'pound' }}
              _error={!isFieldValid('price')}
              variants={['noError']}
            />
          </FieldWrapper>
        </View>
      </View>

      <Text text={'Main disputers'} variants={['p2', 'color:neutral7']} />

      <View
        variants={[
          'flex',
          'backgroundColor:neutral1',
          'padding:2',
          'column',
          'border-radius:small',
          'gap:2',
        ]}
        responsiveVariants={{
          small: ['gap:0', 'paddingBottom:0'],
        }}
      >
        <Text text={'First party'} variants={['h5', 'color:neutral8']} />

        <FieldWrapper errorMessage={getFieldErrorMessage('first_party_name')}>
          <TextInput
            {...form.register('first_party_name')}
            _error={!isFieldValid('first_party_name')}
            variants={['noError']}
          />
        </FieldWrapper>

        <View
          variants={['row', 'gap:2']}
          responsiveVariants={{ small: ['column', 'gap:0'] }}
        >
          <FieldWrapper errorMessage={getFieldErrorMessage('first_party_email')}>
            <TextInput
              {...form.register('first_party_email')}
              _error={!isFieldValid('first_party_email')}
              variants={['noError']}
            />
          </FieldWrapper>

          <FieldWrapper errorMessage={getFieldErrorMessage('first_party_phone')}>
            <TextInput
              {...form.register('first_party_phone')}
              _error={!isFieldValid('first_party_phone')}
              variants={['noError']}
            />
          </FieldWrapper>
        </View>

        <FieldWrapper errorMessage={getFieldErrorMessage('first_party_address')}>
          <TextInput
            {...form.register('first_party_address')}
            _error={!isFieldValid('first_party_address')}
            variants={['noError']}
          />
        </FieldWrapper>
      </View>

      <View
        variants={[
          'flex',
          'backgroundColor:neutral1',
          'padding:2',
          'column',
          'border-radius:small',
          'gap:2',
        ]}
        responsiveVariants={{
          small: ['gap:0', 'paddingBottom:0'],
        }}
      >
        <Text text={'Second party'} variants={['h5', 'color:neutral8']} />

        <FieldWrapper errorMessage={getFieldErrorMessage('second_party_name')}>
          <TextInput
            {...form.register('second_party_name')}
            _error={!isFieldValid('second_party_name')}
            variants={['noError']}
          />
        </FieldWrapper>

        <View
          variants={['row', 'gap:2']}
          responsiveVariants={{ small: ['column', 'gap:0'] }}
        >
          <FieldWrapper errorMessage={getFieldErrorMessage('second_party_email')}>
            <TextInput
              {...form.register('second_party_email')}
              _error={!isFieldValid('second_party_email')}
              variants={['noError']}
            />
          </FieldWrapper>

          <FieldWrapper errorMessage={getFieldErrorMessage('second_party_phone')}>
            <TextInput
              {...form.register('second_party_phone')}
              _error={!isFieldValid('second_party_phone')}
              variants={['noError']}
            />
          </FieldWrapper>
        </View>

        <FieldWrapper errorMessage={getFieldErrorMessage('second_party_address')}>
          <TextInput
            {...form.register('second_party_address')}
            _error={!isFieldValid('second_party_address')}
            variants={['noError']}
          />
        </FieldWrapper>
      </View>
    </View>
  )
}

const SELECT_MAX_WIDTH = 180

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    selectInput: {
      maxWidth: SELECT_MAX_WIDTH,
    },
  }),
  true,
)
