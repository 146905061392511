import { createForm, yup } from '@codeleap/common'

export const partyInfo = () => {
  return createForm('partyInfo', {
    full_name: {
      type: 'text',
      label: 'Full Name*',
      placeholder: 'First and last name',
      validate: yup
        .string()
        .matches(/^.{1,100}$/, 'This field has to be maximum 100 characters')
        .required()
        .max(256),
    },
    email: {
      type: 'text',
      label: 'Email',
      placeholder: 'example@email.com',
      validate: yup.string().email().max(256),
    },
    phone: {
      type: 'text',
      label: 'Phone number',
      placeholder: '+44 1234-123-123',
      masking: {
        type: 'custom',
        options: {
          mask: '+44 9999-999-999',
        },
      },
      validate: yup.string(),
    },
    address: {
      type: 'text',
      label: 'Address',
      placeholder: 'Disputer address',
      validate: yup.string().max(256),
    },
  })
}
