import { createForm, yup } from '@codeleap/common'

export const disputeInfo = () => {

  return createForm('disputeInfo', {
    category: {
      type: 'text',
      validate: yup.string().required('This is a required field'),
    },
    description: {
      type: 'text',
      label: 'Please provide more details about your dispute',
      placeholder: 'Enter information',
      validate: yup.string().max(4096),
    },
    ideal_solution: {
      type: 'text',
      placeholder: 'What would be your ideal solution?',
      validate: yup.string().max(4096),
    },
    additional_info: {
      type: 'text',
      placeholder: 'Feel free to tell us any further information',
      validate: yup.string().max(4096),
    },
    is_legal_rep: {
      type: 'checkbox',
      label: "I'm a legal representative",
    },
    price: {
      type: 'number',
      label: 'Enter the value of your dispute',
      placeholder: 'E.g. £1,000',
      defaultValue: null,
    },
  })
}
