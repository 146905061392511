import { APIClient } from '@/services'
import { useEffect, useState } from '@codeleap/common'

export const useUserInformation = () => {
  const [ipAddress, setIpAdress] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [userAgent, setUserAgent] = useState('')
  const [connection, setConnection] = useState('')
  const [language, setLanguage] = useState('')
  const [languages, setLanguages] = useState<string[]>([''])

  const onGeolocationSuccess = (pos) => {
    const coords = pos.coords
    setLatitude(coords.latitude)
    setLongitude(coords.longitude)
  }

  const onGeolocationErrors = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`)
  }

  const geolocationOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  }

  const handleUserLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((result) => {
          if (['granted', 'prompt'].includes(result.state)) {
            navigator.geolocation.getCurrentPosition(onGeolocationSuccess, onGeolocationErrors, geolocationOptions)
          } else if (result.state === 'denied') {
            console.log('Geolocation Permission denied')
          }
        })
    } else {
      console.log('Geolocation is not supported by this browser.')
    }
  }

  useEffect(() => {
    handleUserLocation()
    setUserAgent(navigator.userAgent)
    setConnection(navigator.connection?.effectiveType)
    setLanguage(navigator.language)
    setLanguages([...navigator.languages])
    const fetchIp = async () => {
      try {
        const data = await APIClient.UserData.fetchIpAddress()
        setIpAdress(data?.ip)
      } catch (error) {
        console.error('Error fetching IP address:', error)
      }
    }
    fetchIp()
  }, [])

  return ({
    latitude,
    longitude,
    ipAddress,
    userAgent,
    connection,
    languages,
    language,
  })
}
