import { api } from '@/app'
import { Dispute, DisputeSettlement, Profile } from '@/types'
import { PaginationResponse, QueryManager, TypeGuards } from '@codeleap/common'
import { queryClient } from '../queryClient'

const BASE_URL = 'disputes/settlements/'

type RequestSignaturesData = {
  first_party_profiles: Profile['id'][]
  second_party_profiles: Profile['id'][]
}

export async function requestSignatures(id: DisputeSettlement['id'], data: RequestSignaturesData) {
  const response = await api.patch(`${BASE_URL}${id}/request_signatures/`, { data }, { multipart: true })
  return response.data
}

export async function signDocument(id: DisputeSettlement['id'], data) {
  const response = await api.post(`${BASE_URL}${id}/sign/`, { data }, { multipart: true })
  return response.data
}
export async function revokeSign(id: DisputeSettlement['id']) {
  const response = await api.delete(`${BASE_URL}${id}/revoke_signatures/`)
  return response.data
}

export const settlementsManager = new QueryManager({
  itemType: {} as DisputeSettlement,
  name: 'Settlements',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<DisputeSettlement>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
      },
    })

    return response.data
  },
  createItem: async data => {
    const { file, ...rest } = data
    const requestData = {
      data: rest,
      file: null,
    }

    if (!!file && !TypeGuards.isString(file)) requestData.file = file
    else requestData.file = 'null'

    const response = await api.post(BASE_URL, requestData, { multipart: true })
    return response.data
  },
  updateItem: async data => {
    const { file, ...rest } = data
    const requestData = {
      data: rest,
      file: null,
    }

    if (!!file && !TypeGuards.isString(file)) requestData.file = file
    else requestData.file = 'null'

    const response = await api.patch<DisputeSettlement>(`${BASE_URL}${data.id}/`, requestData, { multipart: true })
    return response.data
  },
  retrieveItem: async id => {
    const response = await api.get<DisputeSettlement>(`${BASE_URL}${id}/`)

    return response.data
  },
  deleteItem: async item => {
    if (settlementsManager.meta?.throwErrorForDelete) {
      throw new Error('Erro deleting settlement')
    }

    await api.delete(`${BASE_URL}${item.id}/`)
    return item
  },
  actions: {},
})

export const useSettlements = (id: Dispute['id']) => {
  const settlements = settlementsManager.use({
    filter: {
      dispute: id,
    },
  })

  const settlementToSign = settlements.items?.find(settlement => settlement.require_signature)

  return {
    settlements,
    settlementToSign,
  }
}
