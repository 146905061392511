function formatToPound(number) {
  const poundFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  })

  return poundFormatter.format(number)
}

export const CurrencyUtils = { formatToPound }
