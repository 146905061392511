import { Modal, View, Text, Button, Image } from '@/components'
import { AppImages, variantProvider } from '@/app'
import { APIClient } from '@/services'
import { useLocation } from '@reach/router'
import { Navigation } from '@/utils'

const _AuthWallModal = () => {
  const { pathname } = useLocation()
  const visible = Navigation.isPathAuthenticated(pathname)

  return (
    <Modal visible={visible} variants={['fullscreen']} closable={false}>
      <View css={[styles.wrapper]}>
        <Image source={AppImages.Confidential} css={styles.image} />
        <Text
          text='Sign In Required'
          variants={['h1', 'textCenter', 'marginBottom:2']}
        />
        <Text
          variants={['neutral-7', 'textCenter']}
          text='Access Denied. Sign in required to access this page. Please sign in to continue'
        />

        <Button
          debugName='sign-in-button'
          text='Sign in'
          variants={['marginTop:6', 'large', 'fullWidth']}
          css={styles.button}
          onPress={() => Navigation.navigate('Auth.Login')}
        />
        <Button
          debugName='sign-in-button'
          text='Return home'
          variants={['marginTop:2', 'large', 'fullWidth', 'minimal']}
          css={styles.button}
          onPress={() => Navigation.navigate('Home')}
        />
      </View>
    </Modal>
  )
}

export const AuthWallModal = () => {
  const { isLoggedIn } = APIClient.Session.useSession()
  if (isLoggedIn) return null

  return <_AuthWallModal />
}

const BUTTON_WIDTH = 400
const IMAGE_WIDTH = 400

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    image: {
      width: '50%',
      maxWidth: IMAGE_WIDTH,
      ...theme.spacing.marginBottom(8),
    },
    button: {
      maxWidth: BUTTON_WIDTH,
    },
    wrapper: {
      ...theme.presets.justifyCenter,
      ...theme.presets.alignCenter,
      ...theme.presets.column,
      ...theme.presets.fullWidth,
      ...theme.spacing.padding(4),
      ...theme.spacing.marginBottom(10),
      flex: 1,
    },
  }),
  true,
)
