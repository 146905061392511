import { IS_SSR, Theme } from '@/app'
import { View, Text } from '@/components'
import { StylesOf } from '@codeleap/web'
import { useMemo } from 'react'

const TEXT_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'br', 'strong']
const SPACING_TAGS = ['span', 'ol', 'ul', 'li']

export type RenderRichTextComposition = 'wrapper' | 'text' | 'image'

const mountTextComponent = (element: HTMLElement, styles = {}) => {
  const variants = []
  const componentProps: any = {}
  const classNames = element.className

  if (classNames === 'p1') variants.push(...['h2', 'color:neutral8'])

  componentProps.text = element.innerText
  componentProps.id = element.id
  componentProps.style = styles

  return <Text variants={variants} {...componentProps} />
}

type RenderComponentFromHtmlProps = {
  element: HTMLElement
  styles?: StylesOf<RenderRichTextComposition>
}

const RenderComponentFromHtml = (props: RenderComponentFromHtmlProps) => {
  const { element, styles } = props

  const tag = element.localName

  if (TEXT_TAGS.includes(tag)) {
    return mountTextComponent(element, styles?.text)
  } else if (SPACING_TAGS.includes(tag)) {
    if (tag === 'li') {
      return <element.localName>{<Text text={element.textContent} />}</element.localName>
    }
    return (
      <element.localName style={tag === 'ol' || tag === 'ul' ? { ...Theme.spacing.marginLeft(3) } : {}}>
        {Array.from(element.children).map((element, key) => (
          <RenderComponentFromHtml key={`RCHElement_2_${key}`} element={element} />
        ))}
      </element.localName>
    )
  }

  logger.warn('Unhandled HTML tag', { tag }, 'RenderRichText')

  return null
}

type RenderRichTextProps = {
  content: string
  styles?: StylesOf<RenderRichTextComposition>
}

export const RenderRichText = ({ content, styles = {}}: RenderRichTextProps) => {
  const elements = useMemo(() => {
    if (IS_SSR) return null
    const div = document.createElement('div')
    div.innerHTML = content
    return Array.from(div.children as unknown as NodeListOf<HTMLElement>)
  }, [IS_SSR])

  if (!elements) return null
  return (
    <View variants={['column']} css={styles?.wrapper}>
      {elements.map((element, key) => (
        <RenderComponentFromHtml key={`RCHElement_${key}`} element={element} styles={styles} />
      ))}
    </View>
  )
}
