import { View, Checkbox } from '@/components'
import { CheckboxProps, ViewProps } from '@codeleap/web'
import { Settings, variantProvider } from '../app'
import { assignTextStyle } from '../app/stylesheets/Text'

export type TermsCheckboxesProps = ViewProps<'div'> & {
  termsCheckboxProps?: CheckboxProps
  mediationxCheckboxProps?: CheckboxProps
}

export const TermsCheckboxes = ({
  mediationxCheckboxProps,
  termsCheckboxProps,
  variants = [],
}: TermsCheckboxesProps) => {
  return (
    <View variants={['column', ...variants]}>
      <View variants={['alignCenter']}>
        <Checkbox {...termsCheckboxProps} label={null} />
        <p style={styles.p}>
          I agree to the{' '}
          <a
            style={styles.a}
            href={Settings.ContactINFO.TermsAndConditions}
            target='_blank'
          >
            Terms of Service
          </a>
          {' '}and{' '}
          <a
            style={styles.a}
            href={Settings.ContactINFO.PrivacyPolicy}
            target='_blank'
          >
            Privacy policy
          </a>
        </p>
      </View>

      <View variants={['alignCenter']}>
        <Checkbox {...mediationxCheckboxProps} label={null} />
        <p style={styles.p}>
          I agree to the{' '}
          <a
            style={styles.a}
            href={Settings.ContactINFO.TermsAgreement}
            target='_blank'
          >
            MediationX Mediation Agreement
          </a>
        </p>
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    p: {
      ...assignTextStyle('p1')(theme).text,
      whiteSpace: 'break-spaces',
      ...theme.spacing.marginLeft(1),
    },
    a: {
      color: theme.colors.primary4,
    },
  }),
  true,
)
