const formatFileName = (fileName: string) => {
  const name = fileName?.split('/').pop() ?? 'File.pdf'

  return decodeURIComponent(name) ?? name
}

const downloadURL = (fileUrl: string, fileName = 'File') => {
  fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const anchorElement = document.createElement('a')
      const url = window.URL.createObjectURL(blob)
      anchorElement.href = url
      anchorElement.download = fileName

      anchorElement.click()

      document.body.removeChild(anchorElement)
      window.URL.revokeObjectURL(url)
    })
    .catch((error) => {
      console.error('Error downloading file:', error)
    })
}

const download = (file: string) => {
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = file
  a.target = '_blank'
  a.download = formatFileName(file)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

const isPdf = (fileName: string) => {
  return fileName?.includes('.pdf')
}

const formatFileSize = (bytes: number, decimals = 1) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const FileUtils = {
  formatFileName,
  download,
  downloadURL,
  isPdf,
  formatFileSize,
}
