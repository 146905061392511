import React, { PropsWithChildren, useMemo } from 'react'
import { OptionsDropDownStyles } from '@/app/stylesheets'
import { OptionsDropDownComposition } from '@/app/stylesheets/OptionsDropDown'
import { Button, View, Touchable, Text, Tooltip } from '@/components'
import { ComponentVariants, getNestedStylesByKey, PropsOf, StylesOf, useDefaultComponentStyle } from '@codeleap/common'
import { Icon, IconProps, TooltipProps } from '@codeleap/web'
import { AppIcon } from '@/app'

export type OptionsDropDownProps = PropsWithChildren & ComponentVariants<typeof OptionsDropDownStyles>
  & Omit<PropsOf<typeof Touchable>, 'styles' | 'variants'>
  & {
    styles?: StylesOf<OptionsDropDownComposition>
    icon?: AppIcon
    iconProps?: Partial<IconProps>
    text?: string
    options: PropsOf<typeof Button>[]
    tooltipProps?: Partial<TooltipProps>
  }

export const OptionsDropDown = ({
  variants = [],
  styles = {},
  style,
  responsiveVariants = {},
  icon,
  children,
  text,
  iconProps,
  options,
  tooltipProps,
  ...rest
}: OptionsDropDownProps) => {
  const variantStyles = useDefaultComponentStyle<'u:OptionsDropDown', typeof OptionsDropDownStyles>('u:OptionsDropDown', {
    variants,
    rootElement: 'outerWrapper',
    styles,
    responsiveVariants,
  })

  const getStyles = (key: OptionsDropDownComposition) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  })

  const renderOptions = useMemo(() => {
    return (
      <>
        {options?.map((option, index) => (
          <Button key={index} styles={getStyles('option')} {...option} />
        ))}
      </>
    )
  }, [])

  return (
    <View
      {...rest}
      css={[getStyles('outerWrapper'), style]}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        e.nativeEvent.stopImmediatePropagation()
      }}
    >
      <Tooltip
        {...tooltipProps}
        content={tooltipProps?.content ?? renderOptions}
        side='bottom'
        styles={getStyles('tooltip')}
        closeOnClickOutside={true}
        openOnPress={true}
        openOnHover={false}
        debugName={`Options DropDown`}
        arrowProps={{ style: getStyles('tooltipArrow') }}
      >
        <View css={[getStyles('tooltipInnerWrapper')]}>
          {children}
          {icon ? (
            <Icon
              name={icon}
              debugName={`Options DropDown Icon`}
              style={getStyles('icon')}
              {...iconProps}
            />
          ) : null}
          {text ? (
            <Text
              text={text}
              style={getStyles('text')}
            />
          ) : null}
        </View>
      </Tooltip>
    </View>
  )
}

