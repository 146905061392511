/* eslint-disable max-len */
import { Navigation } from '@/utils'
import { APIClient } from '@/services'
import { onUpdate } from '@codeleap/common'
import { useSearchParams } from '@codeleap/web'
import { AppStatus, useAppSelector } from '@/redux'
import { Image, Modal, Text, View, Button } from '@/components'
import { AppImages, LocalStorageKeys, Settings, assignTextStyle, variantProvider } from '@/app'

export const DisputeJoinModal = () => {
  const [params] = useSearchParams()
  const modals = useAppSelector((store) => store.AppStatus.modals)
  const { isLoggedIn } = APIClient.Session.useSession()
  const firstParty = params?.firstParty

  onUpdate(() => {
    if (!!params?.disputeInvite) AppStatus.setModal('disputeInvite')
  }, [params?.disputeInvite])

  onUpdate(() => {
    const disputeInvite = localStorage.getItem(LocalStorageKeys.DISPUTE_INVITE)

    if (isLoggedIn && !!disputeInvite) {
      APIClient.Disputes.join({
        code: disputeInvite,
      }).finally(() => localStorage.removeItem(LocalStorageKeys.DISPUTE_INVITE))
    }
  }, [isLoggedIn])

  const toggle = () => AppStatus.setModal(['disputeInvite', false])

  const handleDecline = () => {
    toggle()
    APIClient.Disputes.decline({ code: params?.disputeInvite })
  }

  const handleAccept = () => {
    toggle()
    if (!isLoggedIn) {
      Navigation.navigate('Auth.Login')
      localStorage.setItem(LocalStorageKeys.DISPUTE_INVITE, params?.disputeInvite)
    } else {
      AppStatus.set('loading')
      APIClient.Disputes.join({
        code: params?.disputeInvite,
      }).finally(() => {
        AppStatus.set('done')
      })
    }
  }

  const visible = modals.disputeInvite

  const descriptions = [
    'Dear Sir/Madam,',
    `We would like to invite you to participate in a modern dispute resolution process that has been initiated on the MediationX platform by ${firstParty}.`,
    'Our mediations are fast, confidential and convenient. Furthermore, our trained mediators are objective and neutral, and work tirelessly to help parties resolve their differences.',
    `Fees for the proposed mediation have already been paid by ${firstParty}, and hence no fees will be sought from you.`,
    <Text variants={['neutral-7']}>
      Terms and Conditions apply, including the
      <a style={styles.a} href={Settings.ContactINFO.TermsAndConditions} target='_blank'>
        MediationX Mediation Agreement.
      </a>
    </Text>,
  ]

  return (
    <Modal
      visible={visible}
      renderHeader={() => (
        <View css={styles.header}>
          <Image source={AppImages.WhiteLogo} css={styles.logo} />
        </View>
      )}
      variants={['noPadding']}
      styles={{ box: styles.modalBox, innerWrapper: styles.innerWrapper }}
    >
      <View css={styles.wrapper}>
        <Image source={AppImages.Balance} css={styles.balance} />

        {descriptions.map((description, index) => (
          <Text key={index} text={description} variants={['neutral-7', index !== 0 && 'marginTop:2']} />
        ))}

        <View css={styles.footer} variants={['justifyCenter']}>
          <Button
            debugName='DisputeJoinModal:Decline'
            text='Decline'
            variants={['large', 'outline', 'flex']}
            onPress={handleDecline}
            css={styles.button}
          />
          <Button
            debugName='DisputeJoinModal:AcceptDispute'
            text='Accept'
            variants={['large', 'flex']}
            onPress={handleAccept}
            css={styles.button}
          />
        </View>
      </View>
    </Modal>
  )
}

const LOGO_MIN_WIDTH = 175
const BALANCE_MAX_WIDTH = 120
const BUTTON_MAX_WIDTH = 190

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    header: {
      backgroundColor: theme.colors.primary3,
      height: 80,
      ...theme.presets.alignCenter,
      ...theme.spacing.paddingHorizontal(5),
      ...theme.spacing.paddingVertical(3),
    },
    modalBox: { maxWidth: 800 },
    wrapper: {
      ...theme.presets.column,
      ...theme.presets.fullWidth,
      ...theme.spacing.padding(4),
    },
    innerWrapper: {
      [theme.media.down('midish')]: {
        ...theme.spacing.paddingHorizontal(4),
      },
    },
    logo: {
      width: '35%',
      minWidth: LOGO_MIN_WIDTH,
    },
    balance: {
      width: '30%',
      maxWidth: BALANCE_MAX_WIDTH,
      marginBottom: theme.spacing.value(4),
    },
    footer: {
      ...theme.presets.fullWidth,
      gap: theme.spacing.value(4),
      ...theme.presets.alignCenter,
      marginTop: theme.spacing.value(4),
      ...theme.spacing.paddingHorizontal(5),
    },
    button: {
      maxWidth: BUTTON_MAX_WIDTH,
    },
    p: {
      ...assignTextStyle('p1')(theme).text,
      whiteSpace: 'break-spaces',
    },
    a: {
      color: theme.colors.neutral7,
      ...theme.spacing.marginLeft(0.5),
    },
  }),
  true,
)
