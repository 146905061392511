import { IS_SSR } from '@/app'
import { onUpdate } from '@codeleap/common'
import { useState } from 'react'

export function useWindow() {
  const [_window, setWindow] = useState(null)

  onUpdate(() => {
    if (!IS_SSR) setWindow(window)
  }, [])

  return _window
}
