import { AppForms, AppImages } from '@/app'
import { TextInput, Button, View, Text, ImageModal } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { onUpdate, useBooleanToggle, useForm, useState } from '@codeleap/common'
import { ModalProps } from '@codeleap/web'

const SEND_TIMEOUT = 24000

export type OTPModalProps = Pick<ModalProps, 'visible' | 'toggle'> & {

}

export const OTPModal = ({
  ...rest
}: OTPModalProps) => {
  const otp = useAppSelector(store => store.AppStatus.modals).OTP
  const { visible, onSubmit, onResend, onClose, isInvalidCode } = otp
  const toggle = () => AppStatus.setModal(['OTP', false])
  const form = useForm(AppForms.validateOTPCode, {})

  const [isSendTimeout, toggleSendTimeout] = useBooleanToggle(false)
  const [resendSeconds, setResendSeconds] = useState(SEND_TIMEOUT / 1000)

  const resendText = isSendTimeout ? `Try again in ${resendSeconds} seconds...` : 'Resend code'

  const handleSendTimeout = () => {
    toggleSendTimeout(true)
    setTimeout(() => {
      toggleSendTimeout(false)
    }, SEND_TIMEOUT)
  }

  const handleResend = () => {
    onResend()
    handleSendTimeout()
  }

  const handleClose = () => {
    toggle()
    form.setFormValues({ code: '' })
    onClose()
  }

  onUpdate(() => {
    if (!visible) form.setFormValues({ code: '' })
  }, [visible])

  onUpdate(() => {
    let timer: NodeJS.Timeout
    if (isSendTimeout) {
      timer = setInterval(() => {
        setResendSeconds(prev => prev - 1)
      }, 1000)
    } else {
      setResendSeconds(SEND_TIMEOUT / 1000)
      clearInterval(timer)
    }
    return () => clearInterval(timer)
  }, [isSendTimeout])

  return (
    <ImageModal
      debugName='OTP Modal'
      title={'Enter your code'}
      description={'To confirm your identity, we have sent a code to your email inbox. Please enter the code below to proceed with the desired action.'}
      visible={visible}
      toggle={toggle}
      image={AppImages.CheckOTP}
      variants={['otp']}
      responsiveVariants={{ small: ['fullscreen'] as any }}
      showClose={false}
      showCloseButton
      closeButtonProps={{
        onPress: handleClose,
      }}
      {...rest}
      footer={
        <View variants={['fullWidth', 'column', 'paddingHorizontal:4', 'paddingBottom:4']}>
          <View variants={['column', 'gap:1', 'marginBottom:5']}>
            <TextInput
              {...form.register('code')}
              variants={['noLabel', 'centered', 'noError', isInvalidCode && 'error']}
            />

            {isInvalidCode ? (
              <Text
                text='Invalid code. Please resend the code to continue.'
                variants={['p4', 'color:destructive2']}
              />
            ) : null}
          </View>
          <Button
            debugName='ParticipantInviteModal:Send'
            text='Submit'
            variants={['large', 'marginBottom:2']}
            disabled={!form.isValid}
            debounce={500}
            onPress={() => onSubmit(form.values.code)}
          />

          <Button
            debugName='ParticipantInviteModal:Send'
            text={resendText}
            variants={['large']}
            disabled={isSendTimeout}
            onPress={handleResend}
          />
        </View>
      }
    />
  )
}
