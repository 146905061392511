import { ImageModalPresets, ImageModalStyles } from '@/app/stylesheets/ImageModal'
import {
  ComponentVariants,
  PropsOf,
  getNestedStylesByKey,
  useDefaultComponentStyle,
} from '@codeleap/common'
import { ButtonProps, ModalProps } from '@codeleap/web'
import {
  Modal,
  Image,
  Button,
  ImageProps,
  View,
  Text,
  ActionIcon,
} from '@/components'
import { ReactElement } from 'react'

export type ImageModalProps = Omit<PropsOf<typeof Modal>, 'variants'> &
  ComponentVariants<typeof ImageModalStyles> & {
    image?: ImageProps['source']
    footer?: ReactElement
    buttonProps?: ButtonProps
    showCloseButton?: boolean
  }

export const ImageModal = ({
  variants,
  styles,
  responsiveVariants,
  image,
  title,
  description,
  buttonProps,
  showCloseButton = false,
  footer,
  ...rest
}: ImageModalProps) => {
  const variantStyles = useDefaultComponentStyle<
    'u:ImageModal',
    typeof ImageModalPresets
  >('u:ImageModal', {
    variants,
    styles,
    responsiveVariants,
  })

  const getStyles = (key) => getNestedStylesByKey(key, variantStyles)

  return (
    <Modal {...rest} styles={getStyles('modal')}>
      {image && (
        <View css={variantStyles.imageWrapper}>
          {showCloseButton ? (
            <ActionIcon
              name='x'
              styles={getStyles('closeButton')}
              onPress={rest?.closeButtonProps?.onPress || rest.toggle}
              debugName='close modal icon'
            />
          ) : null}
          <Image source={image} css={variantStyles.image} />
        </View>
      )}

      <View css={variantStyles.body}>
        <Text text={title} css={variantStyles.title} />
        <Text text={description} css={variantStyles.description} />

        {buttonProps && (
          <Button
            css={variantStyles.button}
            variants={['large']}
            {...buttonProps}
          />
        )}
      </View>

      {footer}
    </Modal>
  )
}
