import { APIClient } from '@/services'
import { Dispute } from '@/types'

export const useRoleInDispute = (dispute: Dispute) => {
  const { profile } = APIClient.Session.useSession()
  const id = profile?.id

  const {
    first_parties,
    first_parties_legal_reps,
    second_parties,
    second_parties_legal_reps,
    mediator,
  } = dispute

  const isFirstPartie = [
    ...first_parties,
    ...first_parties_legal_reps,
  ]?.some((p) => p.id === id)
  const isSecondPartie = [
    ...second_parties,
    ...second_parties_legal_reps,
  ]?.some((p) => p.id === id)
  const isMediator = id === mediator?.id

  let role = ''
  if (isFirstPartie) {
    role = 'first_parties'
  } else if (isSecondPartie) {
    role = 'second_parties'
  } else if (isMediator) {
    role = 'mediator'
  }

  return {
    profile,
    isFirstPartie,
    isSecondPartie,
    isMediator,
    role,
  }
}
