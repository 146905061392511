import { View, Thread, Text, Button, Tag, DisputeStatusTag, DisputeSelectStatus } from '@/components'
import { ComponentVariants, TypeGuards, useDefaultComponentStyle } from '@codeleap/common'
import { Dispute, DisputeThread } from '@/types'
import { DisputeInfoStyles } from '@/app/stylesheets'
import { DisputeCategoryTag } from './DisputeCategoryTag'
import { DateUtils, DisputeUtils, formatPrice, Navigation, useGetStyles, useSettlements, useWindow } from '@/utils'
import { APIClient } from '@/services'
import { AppStatus } from '@/redux'

type DisputeInfoProps = ComponentVariants<typeof DisputeInfoStyles> & {
  styles?: Partial<typeof DisputeInfoStyles>
  style?: StyleSheet
  dispute: Dispute
  threads: DisputeThread[]
}

export function DisputeInfo({
  dispute,
  threads,
  variants,
  responsiveVariants,
  styles,
  style,
  ...props
}: DisputeInfoProps) {
  const { isMediator } = APIClient.Session.useSession()
  const { refresh } = APIClient.Disputes.disputesManager.useRetrieve({ id: dispute.id })

  const Window = useWindow()
  const { showSignButton, NavigateToSignButton, allIsSigned, DownloadFinalVersionButton } = useSettlements({ dispute })

  const variantStyles = useDefaultComponentStyle<'u:DisputeInfo', typeof DisputeInfoStyles>('u:DisputeInfo', {
    variants,
    styles,
    responsiveVariants,
  })

  const { getStyles } = useGetStyles(variantStyles)

  const { thread = null } = Navigation.getSearchParams()
  const path = Window?.location.pathname.split('/').reverse().find(Boolean)
  const statusOptions = DisputeUtils.availableStatus.filter((item) => item.value !== 'pending')
  const tabsButtons = [
    {
      text: 'Case details',
      //@ts-ignore
      onPress: () => Navigation.goToDispute({ id: dispute.id, path: 'details' }),
      selected: path === 'case_details',
    },
    {
      text: 'Settlements',
      onPress: () => Navigation.goToDispute({ id: dispute.id, path: 'settlements' }),
      selected: path === 'settlements',
    },
    {
      text: 'Participants',
      onPress: () => Navigation.goToDispute({ id: dispute.id, path: 'participants' }),
      selected: path === 'participants',
    },
  ]

  const onUpdateStatus = async (status: Dispute['status']) => {
    if (status !== dispute?.status) {
      AppStatus.set('loading')
      const data = {
        ...dispute,
        status,
        category: TypeGuards.isString(dispute?.category) ? dispute?.category : dispute?.category?.type,
      }
      try {
        await APIClient.Disputes.disputesManager.options.updateItem(data)
        await refresh()
      } catch (e) {
        console.error('Error updating dispute', e, 'Dispute Details')
      }
      AppStatus.set('done')
    }
  }

  return (
    <View css={[getStyles('wrapper'), style]} {...props}>
      <Text css={getStyles('disputeTitle')}>
        <span style={getStyles('disputeNumber')}>{'#' + dispute.id}</span> {dispute.name}
      </Text>
      <View css={getStyles('tagsWrapper')}>
        {isMediator ? (
          <DisputeSelectStatus
            options={statusOptions}
            value={dispute?.status}
            onValueChange={onUpdateStatus}
            variants={['details']}
            showBadge
            debugName='Select category'
          />
        ) : (
          <DisputeStatusTag status={dispute.status} styles={getStyles('statusTag')} />
        )}

        <DisputeCategoryTag category={dispute.category} styles={getStyles('categoryTag')} />
        {!!dispute.price ? (
          <Tag
            leftIcon='pound'
            text={formatPrice(dispute.price)}
            debugName='price tag'
            styles={getStyles('priceTag')}
          />
        ) : null}
      </View>
      {showSignButton && <NavigateToSignButton />}
      {allIsSigned && <DownloadFinalVersionButton />}
      <Text css={getStyles('description')} text={dispute.description} />
      <Text variants={['p4']} text={`Dispute created on ${DateUtils.formatCreatedAt(dispute.created_datetime)}`} />
      {tabsButtons.map((item, index) => {
        return (
          <Button
            {...item}
            key={index}
            styles={getStyles('button', [item.selected && 'selected'])}
            rightIcon='chevron-right'
            debugName={`Pressed ${item.text}`}
          />
        )
      })}
      <View css={getStyles('chatWrapper')}>
        <Text css={getStyles('chatTitle')} text='Chat' />
        {threads?.map((item, index) => (
          <Thread
            thread={item}
            dispute={dispute}
            key={item.id}
            selected={thread === String(item.id)}
            onPress={() => Navigation.goToDispute({ id: dispute.id, path: 'chat', params: { thread: String(item.id) }})}
            debugName={`Thread DisputeInfo ${index}`}
            isLast={index === threads.length - 1}
          />
        ))}
      </View>
    </View>
  )
}
