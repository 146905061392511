import { SideBarMenuStyles } from '@/app/stylesheets'
import { Navigator, View, NavigationLinkProps, NavigationLink } from '@/components'
import { APIClient } from '@/services'
import { ComponentVariants, useDefaultComponentStyle } from '@codeleap/common'

type SideBarMenuProps = ComponentVariants<typeof SideBarMenuStyles> & {
  styles?: typeof SideBarMenuStyles
  style?: StyleSheet
  options?: NavigationLinkProps[]
}

const defaultOptions: NavigationLinkProps[] = [
  {
    title: 'My Disputes',
    icon: 'briefcase',
    to: 'Disputes.List',
  },
  {
    title: 'My Profile',
    icon: 'user',
    to: 'Profile.Edit',
  },
]

function _SideBarMenu({
  responsiveVariants,
  variants,
  styles,
  style,
  options = defaultOptions,
  ...props
}: SideBarMenuProps) {
  const { isMediator } = APIClient.Session.useSession()

  const variantStyles = useDefaultComponentStyle<'u:SideBarMenu', typeof SideBarMenuStyles>('u:SideBarMenu', {
    responsiveVariants,
    variants,
    styles,
  })

  return (
    <View css={variantStyles.wrapper} {...props}>
      {!isMediator && <NavigationLink icon='plus' title='New Dispute' to='Disputes.CreateDispute' minimal />}

      {options.map((option, index) => (
        <NavigationLink {...option} key={index} />
      ))}
    </View>
  )
}

export function SideBarMenu(props: SideBarMenuProps) {
  return (
    <Navigator.Container basepath='/'>
      <_SideBarMenu {...props} />
    </Navigator.Container>
  )
}
