import { AppImages, variantProvider } from '@/app'
import { View, Text, Image, CenterWrapper, CompanyStrengthsSlider } from '@/components'
import { useI18N } from '@codeleap/common'

export const StrenghtsSection = () => {

  const { t } = useI18N()

  return (
    <View
      variants={['fullWidth', 'backgroundColor:neutral1', 'paddingVertical:10', 'relative']}
      responsiveVariants={{
        smallish: ['paddingTop:5'],
      }}
    >
      <Image source={AppImages.StrengthsBackground} style={styles.backgroundImage} />
      <CenterWrapper variants={['column']}>
        <View
          variants={['row', 'center', 'fullWidth', 'marginBottom:10']}
          responsiveVariants={{
            small: ['row', 'center', 'fullWidth', 'marginBottom:5'],
          }}
          style={{ zIndex: 1 }}
        >
          <Text
            text={t('Homepage.strenghts.title1')}
            variants={['hx', 'color:primary3', 'marginRight:2']}
            responsiveVariants={{
              small: ['h1', 'color:primary3', 'marginRight:2'],
            }}
          />
          <Text
            text={t('Homepage.strenghts.title2')}
            variants={['hx', 'color:neutral8']}
            responsiveVariants={{
              small: ['h1', 'color:neutral8'],
            }}
          />
        </View>

        <CompanyStrengthsSlider />
      </CenterWrapper>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  backgroundImage: {
    ...theme.presets.absolute,
    top: 0,
    left: 0,
    ...theme.presets.fullWidth,
    height: '95%',
    objectFit: 'cover',
    zIndex: 0,
  },
}), true)
