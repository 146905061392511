import React from 'react'

export function useScrollRef() {
  const scrollRef = React.useRef(null)

  function handleScrollToTop(callback) {
    if (scrollRef.current.scrollTop === 0) callback()
  }

  function scrollToBottom() {
    scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight)
  }

  return { scrollRef, handleScrollToTop, scrollToBottom }
}
