import { assignTextStyle, includePresets } from '@codeleap/common'
import { variantProvider } from '../theme'
import { ActionIconComposition, ModalComposition } from '@codeleap/web'

export type ImageModalComposition =
  | 'image'
  | 'imageWrapper'
  | `modal${Capitalize<ModalComposition>}`
  | 'body'
  | 'title'
  | 'description'
  | 'button'
  | `closeButton${Capitalize<ActionIconComposition>}`

const createImageStyle =
  variantProvider.createVariantFactory<ImageModalComposition>()

export const ImageModalPresets = includePresets((styles) => createImageStyle(() => ({ modalWrapper: styles })),
)

export const ImageModalStyles = {
  ...ImageModalPresets,
  default: createImageStyle((theme) => ({
    modalBox: {
      padding: 0,
      width: 800,
    },
    modalHeader: {
      display: 'none',
    },
    modalInnerWrapper: {
      ...theme.presets.alignCenter,
      padding: theme.spacing.value(2),
    },
    imageWrapper: {
      background: theme.colors.primary1,
      ...theme.presets.justifyCenter,
      ...theme.presets.alignCenter,
      height: 512,
    },
    image: {
      height: 300,
    },
    body: {
      padding: theme.spacing.value(4),
      ...theme.presets.column,
      ...theme.presets.alignCenter,
      ...theme.presets.alignSelfCenter,
      maxWidth: 800,
      width: '100%',
    },
    title: {
      ...assignTextStyle('h2')(theme).text,
      color: theme.colors.neutral10,
    },
    description: {
      textAlign: 'center',
      ...theme.presets.fullWidth,
      color: theme.colors.neutral8,
      ...theme.spacing.marginTop(1),
      ...assignTextStyle('p1')(theme).text,
    },
    button: {
      marginTop: theme.spacing.value(5),
      ...theme.presets.fullWidth,
    },
    closeButtonTouchableWrapper: {
      padding: theme.spacing.value(0),
      position: 'absolute',
      top: 0,
      right: 0,
      ...theme.spacing.margin(2),
      background: theme.colors.transparent,
    },
    closeButtonIcon: {
      color: theme.colors.primary3,
      ...theme.sized(3),
    },
  })),
  fullscreen: createImageStyle((theme) => ({
    modalInnerWrapper: {
      padding: 0,
    },
    modalBox: {
      ...theme.presets.fullHeight,
      ...theme.presets.fullWidth,
      maxWidth: 'none',
      borderRadius: 0,
    },
    modalBody: {
      ...theme.presets.fullHeight,
      ...theme.presets.fullWidth,
      maxHeight: `100vh`,
    },
    imageWrapper: {
      height: '100%',
    },
  })),
  payWall: createImageStyle((theme) => ({
    body: {
      maxWidth: 440,
      ...theme.spacing.paddingHorizontal(0),
    },
    title: {
      ...theme.presets.textCenter,
    },
    button: {
      maxWidth: 320,
    },
  })),
  otp: createImageStyle((theme) => ({
    modalWrapper: {
      zIndex: 100000,
    },
    modalBox: {
      maxWidth: 480,
    },
    modalInnerWrapper: {
    },
    body: {
      ...theme.spacing.paddingHorizontal(4),
      ...theme.presets.alignStart,
    },
    imageWrapper: {
      height: 211,
    },
    image: {
      height: 138,
    },
    title: {
      ...assignTextStyle('h1')(theme).text,
    },
    description: {
      textAlign: 'left',
      ...theme.presets.fullWidth,
      color: theme.colors.neutral7,
    },
  })),
}
