import { DisputeCategory } from '@/types'
import { createSlice } from '@codeleap/common'

export type AppOptionsState = {
  disputeCategories: DisputeCategory[]
}

const initialState: AppOptionsState = {
  disputeCategories: [],
}

export const appOptionsSlice = createSlice({
  name: 'AppOptions',
  initialState,
  reducers: {
    clear: () => initialState,
    setDisputeCategories: (_, disputeCategories: DisputeCategory[]) => {

      return {
        disputeCategories,
      }
    },
  },
  asyncReducers: {},
})
