import React from 'react'
import { ComponentVariants, getNestedStylesByKey, StylesOf, TypeGuards, useCallback, useDefaultComponentStyle } from '@codeleap/common'
import { ButtonProps, CreateOSAlert, GlobalAlertComponentProps, GlobalAlertType, ModalProps, useGlobalAlertComponent } from '@codeleap/web'
import { Modal, Text, View, Button } from '@/components'
import { AlertPresets, AlertComposition } from '@/app/stylesheets/Alert'

type CommonProps = {
  title: string
  body?: string
} & Partial<DismissButtonProps>

type AlertType = 'info' | 'error' | 'warn' | 'destructive' | string

type AlertProps = & CommonProps & ModalProps & ActionButtonProps
  & ComponentVariants<typeof AlertPresets> & {
    type: AlertType
    orientation?: 'row' | 'column'
    options?: ButtonProps[]
    styles?: StylesOf<AlertComposition>
    closeOnDismiss?: boolean
    closeOnAction?: boolean
    optionsOnly?: boolean
  }

type ActionButtonProps = {
  onAction?: () => void
  actionButtonProps?: ButtonProps
}

type DismissButtonProps = {
  onDismiss?: () => void
  dismissButtonProps?: ButtonProps
}

function verifyType(type: AlertType) {
  return {
    isInfo: type === 'info',
    isWarn: type === 'warn',
    isError: type === 'error',
    isDestructive: type === 'destructive',
  }
}

function ActionButton({ onAction, actionButtonProps, isDestructive }: ActionButtonProps & { isDestructive: boolean }) {
  const initialText = isDestructive ? 'Delete' : 'Accept'
  return (
    <Button
      text={initialText}
      debugName={`${initialText} button from alert modal`}
      variants={['flex', isDestructive && 'destructive']}
      {...actionButtonProps}
      onPress={onAction}
    />
  )
}

function DismissButton({ onDismiss, dismissButtonProps }: DismissButtonProps) {
  return (
    <Button
      text='Dismiss'
      debugName='Dismiss button from alert modal'
      variants={['outline', 'flex']}
      {...dismissButtonProps}
      onPress={onDismiss}
    />
  )
}

export const Alert = (props: AlertProps) => {
  const {
    body,
    children,
    variants = [],
    styles,
    options = [],
    orientation = 'row',
    responsiveVariants = {},
    closeOnDismiss = true,
    closeOnAction = true,
    type,
    onAction,
    actionButtonProps,
    dismissButtonProps,
    onDismiss,
    optionsOnly,
    ...modalProps
  } = props

  const { isWarn, isDestructive } = React.useMemo(() => verifyType(type), [])

  const variantStyles = useDefaultComponentStyle<'u:Alert', typeof AlertPresets>('u:Alert', {
    variants,
    responsiveVariants,
    styles,
  })

  const modalStyles = React.useMemo(() => {
    return getNestedStylesByKey('modal', variantStyles)
  }, [variantStyles])

  const isRow = orientation === 'row'

  const showActionButton = isWarn || isDestructive

  const handleDismiss = () => {
    if (TypeGuards.isFunction(onDismiss)) onDismiss?.()

    if (closeOnDismiss) modalProps?.toggle?.()
  }

  const handleAction = () => {
    if (TypeGuards.isFunction(onAction)) onAction?.()

    if (closeOnAction) modalProps?.toggle?.()
  }

  const ActionComponent = useCallback(() => {
    return (
      <View variants={['flex']}>
        <ActionButton onAction={handleAction} actionButtonProps={actionButtonProps} isDestructive={isDestructive} />
      </View>
    )
  }, [handleAction])

  return (
    <Modal showClose={false} {...modalProps} styles={modalStyles} variants={['centered', type, ...variants]}>
      {body ? <Text text={body} css={variantStyles.body} /> : null}

      {children}

      <View
        variants={[orientation]}
        css={variantStyles.actions}
      >
        {(showActionButton && !isRow && !optionsOnly) ? <ActionComponent /> : null}

        {
          !optionsOnly && (
            <View variants={['flex']}>
              <DismissButton onDismiss={handleDismiss} dismissButtonProps={dismissButtonProps} />
            </View>

          )
        }

        {options.length > 0 && options.map((button) => (
          <Button {...button} key={button.text} css={variantStyles.option} />
        ))}

        {(showActionButton && isRow && !optionsOnly) ? <ActionComponent /> : null}
      </View>
    </Modal>
  )
}

export function GlobalAlert(props: GlobalAlertComponentProps) {
  const { toggle, visible } = useGlobalAlertComponent(props)
  const { title, body, onDismiss, onAction, options, type: customType, ...alertProps } = props.args

  const typeCast: Record<Exclude<GlobalAlertType, 'custom'>, AlertType> = {
    ask: 'destructive',
    info: 'info',
    warn: 'warn',
    error: 'error',
  }

  const type = typeCast[props.type]
  return <Alert
    debugName='Global alert'
    title={title}
    toggle={toggle}
    visible={visible}
    body={body}
    type={customType || type}
    onAction={onAction}
    onDismiss={onDismiss}
    options={options?.map(o => ({
      debugName: o.text,
      ...o,
    }))}
    optionsOnly={!onAction && !onDismiss && !!options?.length}
    {...alertProps}
  />
}

export const OSAlert = CreateOSAlert(GlobalAlert)
