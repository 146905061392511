import { OSAlert } from '@/components'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { OTPCode, Profile } from '@/types'
import { AnyFunction } from '@codeleap/common'

type useOTPProps = {
  email: Profile['email']
  onSuccess: AnyFunction
  verify_email?: boolean
}

export const useOTP = (props: useOTPProps) => {
  const { email, onSuccess, verify_email = true } = props

  const onClose = () => {
    AppStatus.setModal(['OTP', false, { onSubmit: checkCode, onResend: generateCode, isInvalidCode: false }])
  }

  const generateCode = async () => {
    await APIClient.OTP.generateOTP({ email, verify_email })
  }

  const checkCode = async (code: OTPCode['code']) => {
    AppStatus.set('loading')
    try {
      await APIClient.OTP.checkOTP({ email: email, code })
      await onSuccess?.()
      onClose()
    } catch (error) {
      AppStatus.setModal(['OTP', true, { onSubmit: checkCode, onResend: generateCode, isInvalidCode: true }])
      console.error('Error while trying to validate the code', error)
    }
    AppStatus.set('idle')
  }

  const sendCode = async () => {
    AppStatus.set('loading')
    try {
      await generateCode()
      AppStatus.set('idle')
      AppStatus.setModal(['OTP', true, { onSubmit: checkCode, onResend: generateCode, onClose, isInvalidCode: false }])
    } catch (err) {
      console.error('Error while generaing OTP code', err)
      AppStatus.set('idle')
      OSAlert.error({
        title: 'Login error',
        body: err.data,
      })
    }
  }

  return {
    email,
    sendCode,
    checkCode,
  }
}
