import {
  format,
  isToday,
  isYesterday,
  isSameDay as _isSameDay,
} from 'date-fns'
import moment from 'moment'
import { hasValue } from './misc'

const formatHour = (date: Date) => {
  const _date = new Date(date)
  return format(_date, 'hh:mm aaa')
}

const formatDate = (date: Date) => moment(date).format('DD/MM/YY')

const formatFullDate = (date: Date) => moment(date).format('DD MMMM YYYY')

const formatCreatedAt = (date: Date) => format(new Date(date), 'dd MMM yyyy')

const formatChatDate = (date: Date) => {
  const _date = new Date(date)
  if (isToday(_date)) return `Today ${format(_date, 'hh:mm aaa')}`
  if (isYesterday(_date)) return `Yesterday ${format(_date, 'hh:mm aaa')}`
  return format(_date, 'MM-dd')
}

const isSameDay = (date1: Date, date2: Date) => {
  return hasValue(date1, date2)
    ? _isSameDay(new Date(date1), new Date(date2))
    : false
}

export const DateUtils = {
  formatHour,
  formatDate,
  formatChatDate,
  isSameDay,
  formatCreatedAt,
  formatFullDate,
}
