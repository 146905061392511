import { variantProvider } from '../theme'

export type SideBarMenuComposition = 'wrapper'

const createSideBarMenuStyle = variantProvider.createVariantFactory<SideBarMenuComposition>()

export const SideBarMenuStyles = {
  default: createSideBarMenuStyle((theme) => ({
    wrapper: {
      ...theme.presets.column,
      gap: theme.spacing.value(1),
      minWidth: 212,
    },
  })),
}
