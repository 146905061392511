import { variantProvider } from '@/app'
import { OptionsDropDown, OptionsDropDownProps } from '@/components'
import { TypeGuards } from '@codeleap/common'

export type SettlementOptionsProps = Omit<
  OptionsDropDownProps,
  'options' | 'debugName'
> & {
  onDelete?: () => void
  onMarkAsReady?: () => void
  onDownload?: () => void
}

export const SettlementOptions = ({
  onDelete,
  onMarkAsReady,
  onDownload,
  ...props
}: SettlementOptionsProps) => {
  const options = [
    // TypeGuards.isFunction(onMarkAsReady) && {
    //   text: 'Mark as ready to sign',
    //   onPress: onMarkAsReady,
    //   debugName: 'Ready to sign Option',
    // },
    TypeGuards.isFunction(onDownload) && {
      text: 'Download',
      onPress: onDownload,
      debugName: 'Download Option',
    },
    TypeGuards.isFunction(onDelete) && {
      text: 'Delete',
      onPress: onDelete,
      debugName: 'Delete Option',
    },
  ]

  return (
    <OptionsDropDown
      {...props}
      debugName='Options DropDown'
      icon={'more-horizontal' as any}
      styles={{ icon: customStyles.icon }}
      variants={['settlement']}
      options={options.filter(Boolean)}
    />
  )
}

const customStyles = variantProvider.createComponentStyle(
  (theme) => ({
    icon: {
      color: theme.colors.primary3,
    },
  }),
  true,
)
