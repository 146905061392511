import { createSlice, TypeGuards } from '@codeleap/common'
import { api, LocalStorage, Settings } from '@/app'

const modals = {
  test: false,
  network: false,
  debug: false,
  auth: false,
  forgotBefore: false,
  forgotAfter: false,
  disputeInvite: false,
  paywall: false,
  onboarding: false,
  contact: false,
  participantInfo: {
    visible: false,
    participant: null,
    dispute: null,
    quiz: null,
  },
  signaturesRequested: {
    visible: false,
    dispute: null,
    settlement: null,
  },
  OTP: {
    visible: false,
    onSubmit: () => null,
    onResend: () => null,
    onClose: () => null,
    isInvalidCode: false,
  },
}

export type TAppStatus = {
  status: 'loading' | 'done' | 'idle' | 'splash' | 'blank'
  ready: boolean
  modals: Record<keyof typeof modals, any>
  isDev: boolean
  canGoBlank: boolean
  authFinished: boolean
  hasResolvedInitialAuth: boolean
  hasFetchedDisputeCategories: boolean
}

const initialState: TAppStatus = {
  status: 'splash',
  ready: false,
  modals,
  canGoBlank: true,
  authFinished: false,
  isDev: false,
  hasResolvedInitialAuth: false,
  hasFetchedDisputeCategories: false,
}

export type ModalName = keyof TAppStatus['modals']

export const appStatusSlice = createSlice({
  name: 'AppStatus',
  initialState,
  reducers: {
    initialAuthResolved() {
      return {
        hasResolvedInitialAuth: true,
      }
    },
    setModal(state, modal: [ModalName, boolean, any?] | ModalName) {
      const [name, value, data] = Array.isArray(modal) ? modal : [modal, modals[modal] instanceof Object ? !state.modals[modal]?.visible : !state.modals[modal]]

      if (data instanceof Object || modals[name] instanceof Object) {
        return {
          modals: { [name]: { ...state.modals[name], visible: value, ...data }},
        }
      } else {
        return { modals: { [name]: value }}
      }
    },
    setReady(state, to:boolean) {
      return {
        ready: to,
      }
    },
    toggleDevMode(state, setTo = null) {
      let newState = !state.isDev

      if (TypeGuards.isBoolean(setTo)) {
        newState = setTo
      }

      api.setConfig({
        baseURL: newState ? Settings.Fetch.DevelopmentURL : Settings.Fetch.ProductionURL,
      })

      LocalStorage.setItem('SESSION_IS_DEV', newState ? 'true' : 'false')

      return {
        isDev: newState,
      }
    },
    clearModals(state) {
      return {
        modals,
      }
    },
    setFetchedDisputeCategories(state, to: boolean) {
      return {
        hasFetchedDisputeCategories: true,
      }
    },

  },
  asyncReducers: {
    async authFinished(state, setState, to = true) {
      setState({ status: 'idle', modals: initialState.modals })
    },
    async set(state, setState, status: TAppStatus['status']) {

      // HACK this fixes the animation transition blinking between the splash screen and idle
      if (status === 'idle' && state.status === 'splash') {
        setTimeout(() => setState({ status: 'idle' }), 500)
        setState({ status: 'blank' })
      } else {
        setState({ status })
      }
    },
    async transitionModals(state, setState, [from, to]: [ModalName, ModalName]) {
      const timeout = state?.status !== 'idle' ? 2500 : 400

      const modals = { ...state.modals }
      modals[from] = false

      setState({ modals })

      setTimeout(() => {
        setState({ status: 'idle' })

        modals[to] = true
        setState({ modals })
      }, timeout)
    },
  },
})
