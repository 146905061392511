import { assignTextStyle } from '@codeleap/common'
import { variantProvider } from '../theme'
import { CardBaseComposition } from './CardBase'

export type DisputeTypeComposition =
 | `card${Capitalize<CardBaseComposition>}`
 | 'title'
 | 'text'
 | 'fakeButton'
 | 'fakeButtonText'
 | 'icon'

const createDisputeTypeStyle = variantProvider.createVariantFactory<DisputeTypeComposition>()

const HEIGHT = 220

export const DisputeTypeStyles = {
  default: createDisputeTypeStyle((theme) => ({
    cardWrapper: {
      ...theme.presets.flex,
      ...theme.presets.fullHeight,
      ...theme.spacing.padding(3),
      backgroundColor: theme.colors.neutral2,
      borderRadius: theme.borderRadius.medium,
      maxHeight: HEIGHT,
      minHeight: HEIGHT,
      transitionProperty: 'background',
      transitionDuration: '0.2s',
      '&:hover': {
        backgroundColor: theme.colors.primary4,
      },
    },
    cardInnerWrapper: {
      ...theme.presets.flex,
    },
    title: {
      ...assignTextStyle('h2')(theme).text,
      color: theme.colors.primary4,
      transitionProperty: 'color',
      transitionDuration: '0.2s',
      wordBreak: 'break-word',
    },
    text: {
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral7,
      transitionProperty: 'color',
      transitionDuration: '0.2s',
      lineHeight: '20px',
      ...theme.spacing.marginBottom(1),
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': '3',
    },
    fakeButton: {
      ...theme.presets.hidden,
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.paddingVertical(1),
      ...theme.spacing.paddingHorizontal(2),
      borderRadius: theme.borderRadius.small,
      ...theme.spacing.gap(1),
      ...theme.presets.alignSelfEnd,
      ...theme.spacing.marginTop('auto'),
      ...theme.spacing.marginLeft('auto'),
    },
    fakeButtonText: {
      ...assignTextStyle('p2'),
      fontWeight: 500,
      lineHeight: '16px',
    },
    icon: {
      ...theme.sized(2),
      color: theme.colors.primary4,
    },
  })),
}
