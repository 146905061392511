import { assignTextStyle } from '@codeleap/common'
import { variantProvider } from '../theme'

export type PlaceholderComposition = 'wrapper'
 | 'iconWrapper'
 | 'icon'
 | 'text'
 | 'image'
 | 'button'

const createPlaceholderStyle = variantProvider.createVariantFactory<PlaceholderComposition>()

export const PlaceholderStyles = {
  default: createPlaceholderStyle((theme) => ({
    wrapper: {
      ...theme.presets.flex,
      ...theme.presets.column,
      ...theme.presets.center,
      ...theme.spacing.gap(1),
    },
    iconWrapper: {
      width: theme.spacing.value(15),
      height: theme.spacing.value(15),
      ...theme.presets.center,
      backgroundColor: theme.colors.neutral2,
      borderRadius: theme.borderRadius.rounded,
    },
    icon: {
      ...theme.sized(8),
    },
    image: {
      width: '60%',
    },
    text: {
      ...theme.presets.textCenter,
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral8,
    },
    button: { marginTop: theme.spacing.value(1) },
  })),
  compact: createPlaceholderStyle((theme) => ({
    wrapper: {
      maxWidth: 330,
      ...theme.spacing.gap(2),
      margin: 'auto',
    },
    text: {
      ...assignTextStyle('p3')(theme).text,
    },
    icon: {
      color: theme.colors.neutral5,
      ...theme.sized(8),
    },
  })),
  image: createPlaceholderStyle((theme) => ({
    image: {
      maxWidth: 375,
      ...theme.presets.fullWidth,
      marginBottom: theme.spacing.value(1),
    },
    iconWrapper: {
      flex: 1,
      ...theme.presets.fullWidth,
      backgroundColor: 'unset',
    },
  })),
}
