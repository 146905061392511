import { LoginForm } from './LoginForm'
import { SignupForm } from './SignupForm'
import { ForgotPasswordBeforeForm } from './ForgotPasswordBeforeForm'
import { ForgotPasswordAfterForm } from './ForgotPasswordAfterForm'

export const AuthForms = {
  LoginForm,
  SignupForm,
  ForgotPasswordBeforeForm,
  ForgotPasswordAfterForm,
}
