import { Profile } from '@/types'
import { View, Text, ParticipantItem, ParticipantItemProps } from '@/components'

const getVariants = (index, length) => [
  !(index === length - 1) && 'border-bottom',
  index === 0 && 'border-radius:top',
  index === length - 1 && 'border-radius:bottom',
  length === 1 && 'border-radius:small',
]

type ParticipantsListProps = Omit<ParticipantItemProps, 'debugName'> & {
  participants: Profile[]
  title: string
  participantItemProps?: (participant: Profile) => ParticipantItemProps
}

export function ParticipantsList({ participants, title, participantItemProps, ...props }: ParticipantsListProps) {
  if (!participants.length) return null
  return (
    <View variants={['column']}>
      <Text text={title} variants={['marginBottom:1', 'p2']} />
      <View variants={['marginBottom:2', 'card', 'padding:0', 'border-none']}>
        {participants?.map((participant, index) => {
          const participantProps = participantItemProps?.(participant)

          return (
            <ParticipantItem
              key={`ParticipantItem:${participant?.id ?? 'id'}-${index}`}
              debugName={`ParticipantItem:${participant?.id ?? 'id'}`}
              participant={participant}
              {...participantProps}
              {...props}
              variants={[
                ...(getVariants(index, participants.length) as any),
                ...(participantProps?.variants ?? []),
                ...(props.variants ?? []),
              ]}
            />
          )
        })}
      </View>
    </View>
  )
}
