import { AppImages } from '@/app'
import { Alert, DisputeItem, List, Placeholder } from '@/components'
import { APIClient } from '@/services'
import { Dispute } from '@/types'
import { Navigation, usePaywall } from '@/utils'
import { useBooleanToggle } from '@codeleap/common'
import { ListProps } from '@codeleap/web'
import React from 'react'

type DisputeListProps = {
  disputeListProps: ListProps
  status?: Dispute['status'] | 'all'
  loading: boolean
}

export const DisputeList = (props: DisputeListProps) => {
  const { disputeListProps, loading } = props
  const { isMediator, profile } = APIClient.Session.useSession()
  const [selectedDispute, setSelectedDispute] = React.useState<Dispute | null>(
    null,
  )
  const [completeQuizAlert, toggleCompleteQuiz] = useBooleanToggle(false)

  const disputeItem = React.useCallback(
    ({ item }: { item: Dispute }) => {
      if (item?.id === undefined) return null // For some reason, without this, the app crashes caused by an undefined item

      const { checkPayment } = usePaywall(item)

      const hasNewMessages = item.has_new_messages
      const hasAnsweredQuiz = item.has_answered_quiz

      const onPress = () => {
        setSelectedDispute(item)
        if (!hasAnsweredQuiz) {
          toggleCompleteQuiz(true)
          return
        }

        Navigation.goToDispute({ id: item.id })
      }

      return (
        <DisputeItem
          key={item?.id}
          dispute={item}
          onPress={() => checkPayment(onPress)}
          debugName={`DisputeItem id: ${item?.id}`}
          isMediator={isMediator}
          variants={[hasNewMessages && 'newMessages']}
        />
      )
    },
    [isMediator],
  )

  const placeholder = () => {
    return (
      <Placeholder
        image={AppImages.DisputePlaceholder}
        variants={['marginTop:22', 'image']}
        responsiveVariants={{
          small: ['marginHorizontal:4', 'marginTop:0'] as any,
        }}
        text={'Click below to begin a MediationX journey'}
        debugName='Disputes Placeholder'
        buttonProps={
          !isMediator
            ? {
              text: 'Begin',
              debugName: 'Disputes Placeholder button',
              variants: ['large', 'paddingHorizontal:4'],
              onPress: () => Navigation.navigate('Disputes.CreateDispute', {
                params: { clear: true },
              }),
            }
            : null
        }
      />
    )
  }

  return (
    <>
      <List
        {...disputeListProps}
        renderItem={disputeItem}
        ListFooterComponent={null}
        responsiveVariants={{
          small: ['fullHeight'],
        }}
        rowItemsSpacing={0}
        placeholder={{
          debugName: 'Disputes placeholder',
          loading: loading,
          renderEmpty: placeholder,
        }}
      />

      <Alert
        type='warn'
        debugName='Complete Quiz Alert'
        visible={completeQuizAlert}
        toggle={toggleCompleteQuiz}
        title='Confidential Questions'
        body='To assist your MediationX Mediator, please answer the following questions. All answers will be kept fully confidential unless you give permission otherwise.'
        optionsOnly
        options={[
          {
            debugName: 'CompleteQuizAlert:Okay',
            text: 'Cancel',
            variants: ['outline'],
            onPress: toggleCompleteQuiz,
          },
          {
            debugName: 'CompleteQuizAlert:Okay',
            text: 'Continue',
            onPress: () => Navigation.navigate('Disputes.CreateQuiz', {
              params: {
                disputeId: String(selectedDispute.id),
                isCaseCreator: profile?.id === selectedDispute?.owner?.id,
              },
            }),
          },
        ]}
      />
    </>
  )
}
