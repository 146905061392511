import { PageSectionStyles } from '@/app/stylesheets'
import { PageSectionComposition } from '@/app/stylesheets/PageSection'
import { View, Text, ActionIcon } from '@/components'
import { useMediaQueryDown } from '@/utils'
import { ComponentVariants, StylesOf, useDefaultComponentStyle } from '@codeleap/common'
import { ReactNode } from 'react'

const BREAKPOINT = 'midish'

export type PageSectionProps = {
  styles?: StylesOf<PageSectionComposition>
  style?: React.CSSProperties
  children?: ReactNode
  title: string
  headerRightComponent?: ReactNode
  onBack?: () => void
} & ComponentVariants<typeof PageSectionStyles>

export const PageSection = ({
  children,
  title,
  headerRightComponent,
  onBack = () => null,
  responsiveVariants,
  styles,
  variants,
  ...rest
}: PageSectionProps) => {
  const isMobile = useMediaQueryDown(BREAKPOINT)

  const variantStyles = useDefaultComponentStyle<'u:PageSection', typeof PageSectionStyles>('u:PageSection', {
    responsiveVariants,
    styles,
    variants,
  })

  return (
    <View css={[variantStyles.wrapper]} {...rest}>
      <View style={variantStyles.header}>
        {isMobile ? (
          <ActionIcon
            name='chevron-left'
            debugName='on back action icon'
            variants={['minimal', 'neutral1', 'iconSize:4']}
            onPress={onBack}
          />
        ) : null}

        <Text css={variantStyles.title} text={title} />

        {headerRightComponent ?? <View css={variantStyles.void} />}
      </View>
      <View css={[variantStyles.contentWrapper]}>{children}</View>
    </View>
  )
}
