import { variantProvider } from '@/app'
import { View, DisputeType, ActionIcon, ActivityIndicator } from '@/components'
import { Dispute, useAppSelector } from '@/redux'
import { Navigation, useDisputeCategories, useIsMobile } from '@/utils'
import { useBreakpointMatch } from '@codeleap/web'
import Slider from 'react-slick'
import '../../app/stylesheets/css/slick.css'
import '../../app/stylesheets/css/slick-theme.css'
import React from 'react'

const Arrow = (props) => {
  const { className, style, onClick, icon } = props

  return (
    <div
      className={className}
      style={{ ...style, ...styles.arrowWrapper }}
      onClick={onClick}
    >
      <ActionIcon
        name={icon}
        variants={['minimal', 'iconSize:4']}
        onPress={onClick}
        debugName='Slider Prev Arrow'
      />
    </div>
  )
}

export const DisputeTypeSlider = () => {
  const numColumns = useBreakpointMatch({
    small: 1,
    mid: 2,
    huge: 3,
  })

  const numRows = useBreakpointMatch({
    small: 1,
    mid: 1,
    huge: 2,
  })

  const isMobile = useIsMobile()

  const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: !isMobile,
    speed: 500,
    slidesToShow: numColumns,
    centerMode: isMobile,
    rows: numRows,
    slidesToScroll: numColumns,
    prevArrow: <Arrow icon={'chevron-left'} />,
    nextArrow: <Arrow icon={'chevron-right'} />,
  }

  const { disputeCategories } = useDisputeCategories()

  const renderDisputeGrid = (item, key) => {
    const name = item?.name?.replace(/dispute/gi, '')
    return (
      <View
        variants={['paddingHorizontal:3.75', 'paddingVertical:2.5']}
        responsiveVariants={{
          large: ['paddingHorizontal:1', 'paddingVertical:2'],
        }}
      >
        <DisputeType
          key={key}
          title={name}
          text={item?.description}
          onPress={() => {
            Dispute.setDispute({
              // category: disputeCategories.filter((category) => category.type === item?.type)[0],
              description: undefined,
            })
            const category = disputeCategories.filter(
              (category) => category.type === item?.type,
            )[0]

            Navigation.navigate('Disputes.CreateDispute', {
              params: {
                from: 'home',
                page: 'description',
                clear: true,
                categoryType: category?.type,
              },
            })
          }}
          debugName={`${item?.type} Dispute Card`}
        />
      </View>
    )
  }

  const isFetched = useAppSelector(
    (store) => store.AppStatus.hasFetchedDisputeCategories,
  )

  if (!isFetched) {
    return (
      <View variants={['center']}>
        <ActivityIndicator debugName='Dispute Slider indicator' />
      </View>
    )
  }

  return (
    <Slider {...sliderSettings} style={styles.sliderContainer}>
      {disputeCategories.map((item, index) => renderDisputeGrid(item, index))}
    </Slider>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    arrowWrapper: {
      zIndex: 1,
      height: '100%',
      ...theme.presets.flex,
      ...theme.presets.column,
      ...theme.presets.center,
    },
    sliderContainer: {
      ...theme.presets.center,
      ...theme.presets.flex,
    },
  }),
  true,
)
