import { createForm, yup } from '@codeleap/common'
import { DisputeQuizUtils } from '../../../utils/quizzes'

const { getLabelByKey } = DisputeQuizUtils

export const disputeQuiz = () => {
  return createForm('disputeQuiz', {
    perspective_description: {
      type: 'text',
      label: getLabelByKey('perspective_description'),
      placeholder: 'Please provide a concise overview from your perspective',
      validate: yup.string().required(),
    },

    ideal_solution: {
      type: 'text',
      label: getLabelByKey('ideal_solution'),
      placeholder: 'Describe your preferred resolution or ideal outcome',
    },

    is_urgent: {
      type: 'checkbox',
      label: getLabelByKey('urgency_description'),
    },

    urgency_description: {
      type: 'text',
      placeholder: 'Please provide a brief explanation',
      label: 'Describe the urgency',
    },

    recent_position: {
      type: 'text',
      placeholder:
        'Describe the current status or position in the dispute (e.g., pending negotiation, awaiting resolution).',
      label: getLabelByKey('recent_position'),
    },

    requirements_description: {
      type: 'text',
      label: getLabelByKey('requirements_description'),
      placeholder:
        'Share a brief overview of yourself, and the key aspects of the dispute (e.g. your role, needs, and major concerns).',
    },

    context_description: {
      type: 'text',
      label: getLabelByKey('context_description'),
      placeholder:
        'Share insights (e.g. specific incidents, concerns, or dynamics)',
    },

    settlements_description: {
      type: 'text',
      placeholder: 'Consider what holds significance to the other party',
      label: getLabelByKey('settlements_description'),
    },

    roadblocks_description: {
      type: 'text',
      label: getLabelByKey('roadblocks_description'),
      placeholder:
        'Identify potential obstacles or challenges you anticipate encountering',
    },

    authorised_to_settle: {
      type: 'checkbox',
      defaultValue: true,
      label: getLabelByKey('authorised_to_settle'),
    },
    take_lead_description: {
      type: 'text',
      placeholder: 'Let us know your preference',
      label: getLabelByKey('take_lead_description'),
    },

    is_pending_lawsuit: {
      type: 'checkbox',
      label: getLabelByKey('is_pending_lawsuit'),
      defaultValue: false,
    },

    pending_lawsuit_court: {
      type: 'text',
      label: 'Please specify the court',
      placeholder: 'Court name or jurisdiction.',
    },

    pending_lawsuit_case_number: {
      type: 'text',
      label: 'Provide the case number',
      placeholder: 'Enter the assigned case number.',
    },

    strengths_weaknesses_description: {
      type: 'text',
      placeholder: 'e.g. communication issues, documentation gaps, etc.',
      label: getLabelByKey('strengths_weaknesses_description'),
    },
    financial_value: {
      type: 'number',
      label: getLabelByKey('financial_value'),
      placeholder: 'e.g. £100',
    },
    spent_so_far: {
      type: 'number',
      label: getLabelByKey('spent_so_far'),
      placeholder: 'e.g. £100',
    },
    suggestions: {
      type: 'text',
      placeholder:
        'e.g. preferred communication method, confidentiality concerns, etc.',
      label: getLabelByKey('suggestions'),
    },
    additional_info: {
      type: 'text',
      placeholder:
        'Feel free to share specific details, concerns, or preferences',
      label: getLabelByKey('additional_info'),
    },

    agree_terms_conditions: {
      type: 'checkbox',
      defaultValue: false,
    },

    agree_mediationx_terms: {
      type: 'checkbox',
      defaultValue: false,
    },
  })
}
