import React from 'react'
import {
  DisputeSelectStatusComposition,
  DisputeSelectStatusStyles,
} from '@/app/stylesheets/DisputeSelectStatus'
import {
  Badge,
  Button,
  Icon,
  OptionsDropDown,
  OptionsDropDownProps,
  Text,
  View,
} from '@/components'
import {
  ComponentVariants,
  StylesOf,
  getNestedStylesByKey,
  useBooleanToggle,
  useDefaultComponentStyle,
} from '@codeleap/common'
import { variantProvider } from '@/app'

const defaultStatus: OptionsDropDownProps['options'] = [
  { text: 'All Disputes', debugName: '', value: 'all' },
  { text: 'Pending', debugName: '', value: 'pending' },
  { text: 'Open', debugName: '', value: 'open' },
  { text: 'Settled', debugName: '', value: 'settled' },
  { text: 'Closed', debugName: '', value: 'closed' },
]

type DisputeSelectStatusProps = Omit<
  OptionsDropDownProps,
  'variants' | 'styles' | 'style' | 'options'
> &
  ComponentVariants<typeof DisputeSelectStatusStyles> & {
    styles?: StylesOf<DisputeSelectStatusComposition>
    options?: OptionsDropDownProps['options']
    style?: StyleSheet
    debugName?: string
    value: string
    showBadge?: boolean
    onValueChange: (value: string) => void
  }

export function DisputeSelectStatus({
  value,
  onValueChange,
  options = defaultStatus,
  variants = [],
  styles = {},
  style,
  responsiveVariants,
  debugName,
  showBadge = false,
  ...props
}: DisputeSelectStatusProps) {
  const [visible, toggle] = useBooleanToggle(false)

  const variantStyles = useDefaultComponentStyle<
    'u:DisputeSelectStatus',
    typeof DisputeSelectStatusStyles
  >('u:DisputeSelectStatus', {
    variants,
    styles,
    responsiveVariants,
  })

  const getStyles = (key, extraStyles = {}) => {
    return {
      ...variantStyles[key],
      ...getNestedStylesByKey(key, variantStyles),
      ...extraStyles,
    }
  }

  return (
    <OptionsDropDown
      {...props}
      options={[]}
      debugName='Options DropDown'
      children={
        <View css={getStyles('wrapper')}>
          {showBadge ? (
            <Badge
              debugName='Select status badge'
              styles={getStyles('badge')}
              css={_styles[value]}
            />
          ) : (
            <Icon
              name='funnel'
              debugName='Funnel icon'
              style={getStyles('funnel')}
            />
          )}
          <Text
            text={
              options.find(({ value: _value }) => _value === value)?.text ??
              'All Disputes'
            }
            css={getStyles('text')}
          />
          <Icon
            name='chevron-down'
            debugName='Down icon'
            style={getStyles('chevron')}
          />
        </View>
      }
      styles={getStyles('dropdown')}
      tooltipProps={{
        visible,
        toggle,
        content: options.filter(Boolean).map((option) => {
          const selected = !value
            ? option.value === 'all'
            : option.value === value

          return (
            <Button
              {...option}
              debugName={`Option ${option.text}`}
              rightIcon={selected ? 'check' : ''}
              styles={getStyles('option')}
              selected={selected}
              onPress={() => {
                onValueChange(`${option.value}`)
                toggle()
              }}
            />
          )
        }),
      }}
    />
  )
}

const _styles = variantProvider.createComponentStyle(
  () => ({
    open: {
      backgroundColor: '#42C586',
    },
    closed: {
      backgroundColor: '#F33F3F',
    },
    pending: {
      backgroundColor: '#FA8700',
    },
    settled: {
      backgroundColor: '#0095D6',
    },
  }),
  true,
)
