import { ParticipantItemStyles } from '@/app/stylesheets'
import { ParticipantItemComposition } from '@/app/stylesheets/ParticipantItem'
import {
  Avatar,
  View,
  Touchable,
  Text,
  Tag,
  ParticipantOptions,
  ParticipantOptionsProps,
  Checkbox,
} from '@/components'
import { Dispute, Profile } from '@/types'
import { DisputeUtils, useGetStyles, useMediaQueryDown } from '@/utils'
import { ComponentVariants, PropsOf, StylesOf, TypeGuards, useDefaultComponentStyle } from '@codeleap/common'
import { PropsWithChildren } from 'react'

export type ParticipantItemProps = ComponentVariants<typeof ParticipantItemStyles> &
  ParticipantOptionsProps &
  PropsWithChildren &
  Omit<PropsOf<typeof View>, 'styles' | 'variants'> &
  Omit<PropsOf<typeof Touchable>, 'styles' | 'variants'> & {
    styles?: StylesOf<ParticipantItemComposition>
    participant: Profile
    dispute: Dispute
    showTag?: boolean
    showCheckbox?: boolean
    checkboxValue?: string
    onCheckboxChange?: (value: string) => void
    footer?: React.ReactElement
    showOptions?: boolean
    breakpoint?: string
  }

export const ParticipantItem = ({
  variants = [],
  styles = {},
  style,
  responsiveVariants = {},
  onPress = null,
  participant,
  dispute,
  onCopyEmail,
  onViewInformation,
  onRemove,

  showTag = true,
  showCheckbox = false,
  showOptions = false,

  checkboxValue,
  onCheckboxChange,
  footer,
  children,

  breakpoint = 'large',
  ...otherProps
}: ParticipantItemProps) => {
  const options = { onCopyEmail, onViewInformation, onRemove }
  const downBreakpoint = useMediaQueryDown(breakpoint)

  const variantStyles = useDefaultComponentStyle<'u:ParticipantItem', typeof ParticipantItemStyles>(
    'u:ParticipantItem',
    {
      variants,
      rootElement: 'wrapper',
      styles,
      responsiveVariants,
    },
  )

  const { getStyles } = useGetStyles(variantStyles)

  const roleText = DisputeUtils.getParticipantRoleName(dispute, participant)
  const name = participant?.first_name ?? participant?.full_name
  const isSelected = checkboxValue === participant?.id

  const isPressable = TypeGuards.isFunction(onPress)
  const Wrapper = isPressable ? Touchable : View
  const pressableProps = isPressable ? { onPress } : {}

  return (
    <Wrapper
      debugName='Participant Item'
      {...pressableProps}
      css={[getStyles('wrapper', [isSelected && ':selected']), style]}
      styles={styles}
      {...otherProps}
    >
      <View css={[variantStyles.innerWrapper]}>
        {showCheckbox && (
          <Checkbox
            debugName='Select Participant'
            value={isSelected}
            onValueChange={() => onCheckboxChange(participant?.id)}
          />
        )}
        {!downBreakpoint && <Avatar debugName='Participant Avatar' name={name} styles={getStyles('avatar')} />}
        <Text text={name} css={[variantStyles.name]} />

        <View style={variantStyles.rightWrapper}>
          {showTag && <Tag debugName='Tag Role' text={roleText} styles={getStyles('tag')} />}
          {children}
          {!downBreakpoint ? <Text text={participant?.email} css={[variantStyles.email]} /> : null}
          {showOptions && <ParticipantOptions dispute={dispute} participant={participant} {...options} />}
          {footer}
        </View>
      </View>
      {downBreakpoint ? <Text text={participant?.email} css={[variantStyles.email]} /> : null}
    </Wrapper>
  )
}
