import { ButtonComposition, TagComposition } from '@codeleap/web'
import { variantProvider, assignTextStyle } from '..'
import { CardBaseComposition } from './CardBase'

export type CardPricingComposition =
  | `card${Capitalize<CardBaseComposition>}`
  | 'wrapper'
  | 'innerWrapper'
  | 'header'
  | `tag${Capitalize<TagComposition>}`
  | 'value'
  | 'description'
  | 'divider'
  | 'list'
  | 'itemWrapper'
  | 'iconWrapper'
  | 'icon'
  | 'itemChecked'
  | 'itemText'
  | `button${Capitalize<ButtonComposition>}`

const createCardPricingStyle =
  variantProvider.createVariantFactory<CardPricingComposition>()

export const CardPricingStyles = {
  default: createCardPricingStyle((theme) => ({
    wrapper: {
      ...theme.spacing.padding(3),
      borderRadius: theme.borderRadius.medium,
      ...theme.presets.elevated,
    },
    cardInnerWrapper: {
      ...theme.presets.column,
      ...theme.spacing.gap(4),
    },
    header: {
      ...theme.presets.column,
      ...theme.spacing.gap(2),
    },
    tagWrapper: {
      backgroundColor: theme.colors.neutral2,
    },
    tagText: {
      ...assignTextStyle('p3')(theme).text,
    },
    value: {
      ...assignTextStyle('h1')(theme).text,
    },
    description: {
      ...assignTextStyle('p3')(theme).text,
    },
    divider: {
      ...theme.presets.fullWidth,
      backgroundColor: theme.colors.neutral5,
      height: 1,
    },
    list: {
      ...theme.presets.column,
      ...theme.spacing.gap(2),
      minHeight: 0,
    },
    itemWrapper: {
      ...theme.presets.row,
      ...theme.spacing.gap(2),
    },
    iconWrapper: {
      ...theme.sized(2.5),
      ...theme.presets.center,
      borderRadius: theme.borderRadius.tiny,
      backgroundColor: theme.colors.neutral3,
    },
    icon: {
      ...theme.sized(2),
      color: theme.colors.neutral1,
    },
    itemChecked: {
      backgroundColor: theme.colors.primary3,
    },
  })),
  emphasis: createCardPricingStyle((theme) => ({
    wrapper: {
      border: `2px solid ${theme.colors.primary3}`,
    },
  })),
}
