import { Button, Modal, Placeholder, Text, View } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { CurrencyUtils, DisputeUtils, FileUtils } from '@/utils'
import { DisputeQuizUtils } from '@/utils/quizzes'
import { TypeGuards } from '@codeleap/common'

const Content = ({ quiz, participant, dispute }) => {
  const { isOwner } = DisputeUtils.getParticipantInfo(dispute, participant)

  const fields = Object.entries(DisputeQuizUtils.fieldsToShow).map(([key, label]) => {
    let _value = quiz[key]
    const isFile = key === 'file'

    if (DisputeQuizUtils.priceFields.includes(key) && !!_value) {
      _value = CurrencyUtils.formatToPound(_value)
    }

    if (TypeGuards.isBoolean(_value)) {
      _value = _value ? 'Yes' : 'No'
    }

    if (isOwner && Object.keys(dispute).includes(key)) {
      _value = dispute[key]
    }

    return (
      <View variants={['column', 'marginBottom:4']}>
        <Text text={label} variants={['h5', 'neutral-8', 'marginBottom:0.5', 'bold']} />

        {isFile && !!_value ? (
          <Button
            icon='download'
            text='Download file'
            debugName='ParticipantInformationModal:Download'
            variants={['minimal', 'marginRight:auto', 'padding:0']}
            onPress={() => FileUtils.downloadURL(_value as string, DisputeQuizUtils.formatFileName(quiz))}
          />
        ) : (
          <Text text={_value || '- -'} variants={['p1', 'neutral-8', 'regular']} />
        )}
      </View>
    )
  })

  return <View variants={['column', 'marginTop:4']}>{fields}</View>
}

export const ParticipantInformationModal = () => {
  const { visible, dispute, participant, quiz } = useAppSelector((state) => state.AppStatus.modals.participantInfo)
  const toggle = () => AppStatus.setModal('participantInfo')

  const participantName = participant?.first_name

  return (
    <Modal
      scroll
      visible={visible}
      toggle={toggle}
      showClose={false}
      variants={['scroll', 'participant-information']}
      title={`${participantName}'s information`}
      description={`This is the information ${participantName} has confidentially provided`}
      footer={
        !!quiz ? (
          <View variants={['justifyCenter', 'fullWidth']}>
            <Button
              variants={['fullWidth']}
              debugName='ParticipantInformationModal:Done'
              text='Done'
              onPress={toggle}
              styles={{ wrapper: { maxWidth: 320 }}}
            />
          </View>
        ) : null
      }
    >
      {!!quiz ? (
        <Content quiz={quiz} participant={participant} dispute={dispute} />
      ) : (
        <View style={{ height: '35vh' }}>
          <Placeholder
            icon='info'
            variants={['compact']}
            text={`${participantName} didn't answer the confidence questionary yet!`}
          />
        </View>
      )}
    </Modal>
  )
}
