import React from 'react'
import { ForgotAfterModal } from './ForgotAfterModal'
import { ForgotBeforeModal } from './ForgotBeforeModal'
import { AuthWallModal } from './AuthWallModal'

export const AuthModals = () => {

  return <>
    <ForgotBeforeModal />
    <ForgotAfterModal />
    <AuthWallModal />
  </>
}

