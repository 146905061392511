import './services/firebaseApp'

import {
  StyleProvider,
  ReactQuery,
  I18NProvider,
  onUpdate,
  onMount,
} from '@codeleap/common'
import {
  Settings,
  variantProvider,
  React,
  I18N,
  logger,
  LocalStorageKeys,
} from './app'
import {
  AppStatusOverlay,
  ContactModal,
  DisputeJoinModal,
  GlobalStyle,
  OTPModal,
  variants,
} from './components'
import { Provider } from 'react-redux'
import { store } from './redux'
import { APIClient } from './services'
import { AuthenticatedOverlays, Overlays } from './pages'
import { AlertOutlet } from '@codeleap/web'
import { useUserTimezone } from './utils'

const App = ({ children }) => {
  const { isLoggedIn, updateProfile, profile } =
    APIClient.Session.useSession(true)

  onMount(() => {
    const storedPassword = localStorage.getItem(LocalStorageKeys.PASSWORD)
    const urlSearchParams = new URLSearchParams(window.location.search)
    const passwordFromURL = urlSearchParams.get('password')
    const password = storedPassword || passwordFromURL

    if (password) {
      localStorage.setItem(LocalStorageKeys.PASSWORD, password)
    }
  })

  useUserTimezone({
    isLoggedIn,
    onChange: (timezone) => {
      if (profile?.timezone !== timezone) {
        updateProfile.mutateAsync({
          id: profile.id,
          zip_code: profile.zip_code,
          first_name: profile.first_name,
          last_name: profile.last_name,
          email: profile.email,
          timezone,
        })
      }
    },
  })

  onUpdate(APIClient.Disputes.fetchDisputeCategories)

  return (
    <>
      <GlobalStyle />
      {children}
      <AppStatusOverlay />
      <Overlays />
      <AuthenticatedOverlays isLoggedIn={isLoggedIn} />
      <AlertOutlet />
      <DisputeJoinModal />
      <ContactModal />
      <OTPModal />
    </>
  )
}

export const Root = ({ children }) => {
  return (
    <ReactQuery.QueryClientProvider client={APIClient.queryClient.client}>
      <Provider store={store}>
        <StyleProvider
          settings={Settings}
          variantProvider={variantProvider}
          variants={variants}
          logger={logger}
        >
          <I18NProvider i18n={I18N}>
            <App>{children}</App>
          </I18NProvider>
        </StyleProvider>
      </Provider>
    </ReactQuery.QueryClientProvider>
  )
}
