import React, { useRef } from 'react'
import { FileInputProps, FileInputRef } from '@codeleap/web'
import { FileInput, Button, View, Text } from '@/components'

export type FileButton = FileInputProps & {
  files?: File[]
  onFileRemove?: (file: File) => void
  accept?: FileInputProps['accept']
  label?: string
}

export const FileButton = ({
  files = [],
  label,
  onFileSelect,
  onFileRemove,
  accept,
  ...rest
}: FileButton) => {
  const fileInputRef = useRef<FileInputRef>(null)
  const hasFiles = files?.length > 0

  return (
    <View variants={['column']} {...rest}>
      {!!label && <Text text={label} variants={['p2', 'neutral-7', 'marginBottom:2']}/>}
      <FileInput ref={fileInputRef} onFileSelect={onFileSelect} accept={accept} />
      {hasFiles ? (
        files.map((file) => (
          <Button
            debugName={`FilePreview:${file.name}`}
            icon='clip'
            rightIcon='x'
            text={file.name}
            variants={['filePreview']}
            onPress={() => onFileRemove?.(file)}
          />
        ))
      ) : (
        <Button
          icon='clip'
          debugName='File input showcase'
          text='Attach file'
          onPress={() => fileInputRef.current.openFilePicker()}
          variants={['outline', 'large']}
        />
      )}
    </View>
  )
}
