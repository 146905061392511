import { api } from '@/app'
import { DisputeMessage } from '@/types'
import { PaginationResponse, QueryManager } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { useFlatlistProps } from '@/utils'

const BASE_URL = 'crm/dispute_messages/'

export const messagesManager = new QueryManager({
  itemType: {} as DisputeMessage,
  name: 'Disputes Messages',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<DisputeMessage>>(
      BASE_URL,
      {
        params: {
          limit: 20,
          offset,
          ...filters,
        },
      },
    )
    return response.data
  },
  createItem: async (data) => {
    const response = await api.post(BASE_URL, data)
    return response.data
  },
  actions: {
    async markRead(manager, threadId: DisputeMessage['thread']) {
      await api.patch('crm/read_dispute_messages/', {
        thread: threadId,
      })
    },
  },
})

export function useMessages(id: DisputeMessage['id']) {
  const messages = messagesManager.use({
    filter: {
      thread: id,
    },
  })

  const messagesListProps = useFlatlistProps(messages)

  function loadMoreMessages() {
    if (
      !messagesListProps.isFetchingNextPage &&
      messagesListProps.hasNextPage
    ) {
      messagesListProps.fetchNextPage()
    }
  }

  async function loadNewMessages() {
    const lastMessage = messages?.items?.[0]

    const newMessages = await api.get<PaginationResponse<DisputeMessage>>(
      BASE_URL,
      {
        params: {
          limit: 20,
          thread: id,
          time: lastMessage ? new Date(lastMessage?.created_datetime).toISOString() : null,
        },
      },
    )

    const messagesToAppend = []
    for (const message of newMessages.data.results) {
      if (!Object.keys(messages.itemMap).includes(`${message.id}`)) {
        messagesToAppend.push(message)
      } else break
    }

    return {
      newMessages: [...messagesToAppend, ...messages.items],
      hasNewMessages: !!messagesToAppend.length,
    }
  }

  return {
    messages,
    loadMoreMessages,
    loadNewMessages,
  }
}
