import React, { useEffect, useState } from 'react'
import { ChatStyles } from '@/app/stylesheets'
import { ChatComposition } from '@/app/stylesheets/Chat'
import {
  ActivityIndicator,
  ChatMessage,
  Icon,
  PageSection,
  Placeholder,
  Scroll,
  ToolBar,
  View,
  Text,
} from '@/components'
import { APIClient } from '@/services'
import { Dispute, DisputeMessage, DisputeThread } from '@/types'
import { ComponentVariants, StylesOf, useDefaultComponentStyle } from '@codeleap/common'
import { ChatUtils, Navigation, useGetStyles, useInterval, useScrollRef } from '@/utils'
import { I18N } from '@/app'

type ChatProps = ComponentVariants<typeof ChatStyles> & {
  styles?: StylesOf<ChatComposition>
  style?: StyleSheet
  dispute?: Dispute
  threadId?: DisputeThread['id']
  threads?: DisputeThread[]
}

export function Chat({ dispute, threadId, threads, ...props }: ChatProps) {
  const { variants, styles, responsiveVariants } = props

  const thread = threadId ? threads.find((t) => t.id === threadId) : threads.find((t) => t.type === 'all')
  const placeholderText = I18N.t(`Chat.placeholder.${thread.type === 'all' ? 'all' : 'withMediator'}`)
  const [messagesToShow, setMessagesToShow] = useState<DisputeMessage[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const { scrollRef, scrollToBottom, handleScrollToTop } = useScrollRef()

  const { isMediator } = APIClient.Session.useSession()
  const { create } = APIClient.Threads.messagesManager.useCreate()
  const { messages, loadMoreMessages, loadNewMessages } = APIClient.Threads.useMessages(thread.id)

  const variantStyles = useDefaultComponentStyle<'u:Chat', typeof ChatStyles>('u:Chat', {
    variants,
    styles,
    responsiveVariants,
  })

  const { getStyles } = useGetStyles(variantStyles)

  async function sendMessage(content) {
    if (content.trim()) {
      setIsLoading?.(true)
      scrollToBottom()
      await create({ content, thread: thread.id })
      const { hasNewMessages, newMessages } = await loadNewMessages()
      if (hasNewMessages) setMessagesToShow(ChatUtils.reverseMessage(newMessages))
      setIsLoading(false)
    }
  }

  useInterval(async () => {
    const { hasNewMessages, newMessages } = await loadNewMessages()
    if (hasNewMessages) setMessagesToShow(ChatUtils.reverseMessage(newMessages))
  }, 5000)

  useEffect(() => {
    setMessagesToShow(ChatUtils.reverseMessage(messages?.items))
  }, [messages.items])

  useEffect(() => {
    APIClient.Threads.messagesManager.actions.markRead(thread.id)
    if (messagesToShow?.length <= 30) scrollToBottom()
  }, [messagesToShow])

  const hasMessagesToShow = !!messagesToShow?.length

  return (
    <PageSection
      title={ChatUtils.chatTitles({ dispute, thread: thread.type, isMediator })}
      styles={getStyles('pageSection')}
      onBack={() => Navigation.goToDispute({ id: dispute.id })}
    >
      <View css={getStyles('wrapper')}>
        <Scroll css={[getStyles('innerWrapper')]} onScroll={() => handleScrollToTop(loadMoreMessages)} ref={scrollRef}>
          {hasMessagesToShow ? (
            <View css={getStyles('bannerWrapper')}>
              <Icon name='lock' style={getStyles('bannerIcon')} debugName='banner icon' />
              <Text variant={`p1`} text={placeholderText} css={getStyles('bannerText')} />
            </View>
          ) : null}
          {messagesToShow?.length < 20 && <View css={{ marginTop: 'auto' }} />}
          {hasMessagesToShow ? (
            messagesToShow?.map((message, index) => (
              <ChatMessage
                key={message.id}
                message={message}
                dispute={dispute}
                nextMessage={messagesToShow[index + 1] ?? null}
                prevMessage={messagesToShow[index - 1] ?? null}
              />
            ))
          ) : (
            <Placeholder variants={['compact']} icon={'chat' as any} text={placeholderText} />
          )}
          {isLoading && <ActivityIndicator variants={['alignSelfCenter']} debugName='' />}
        </Scroll>
        <ToolBar
          disabled={['pending', 'closed'].includes(dispute?.status)}
          styles={getStyles('toolbar')}
          onSend={sendMessage}
        />
      </View>
    </PageSection>
  )
}
