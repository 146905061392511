import { variantProvider } from '@/app'
import { Icon, Text } from '@/components'
import { DisputeMessage } from '@/types'

export function ContentMessage({ deleted = false, message = {} as DisputeMessage, getStyles, showName = false }) {
  return (
    <Text css={getStyles('text')}>
      {showName && message.owner ? <strong>{`${message?.owner?.first_name}:\u00A0`}</strong> : ''}
      {deleted && <Icon name='x-circle' debugName='deleted message' style={styles.icon} />}
      {deleted ? 'Deleted message' : message.content}
    </Text>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  icon: {
    color: theme.colors.neutral8,
    ...theme.sized(2),
    ...theme.spacing.marginRight(0.5),
    ...theme.spacing.marginBottom(-0.25),
  },
}), true)
