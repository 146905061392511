import React, { useState } from 'react'
import {
  View,
  Button,
  TextInput,
  DisputeForms,
  FileButton,
  NumberIncrement,
  OSAlert,
  Text,
  FormPageProps,
  Touchable,
} from '@/components'
import { FormUtils, FileUtils, Navigation } from '@/utils'
import { TFunction, WebInputFile, onMount, useUnmount } from '@codeleap/common'
import { Dispute, DisputeCategory } from '@/types'
import { Dispute as DisputeRedux, useAppSelector } from '@/redux'
import { useSearchParams } from '@codeleap/web'
import { Theme } from '@/app'

type UseDisputeFormPages = {
  form: any
  firstyPartyForm: any
  secondPartyForm: any
  isLoggedIn: boolean

  disputeCategories: DisputeCategory[]
  t: TFunction<string> | ((key: string) => string)
}

type Page = Partial<FormPageProps> & {
  name: string
  canSkip?: boolean
  authWall?: boolean
}

export const useDisputeFormPages = ({
  form,
  firstyPartyForm,
  secondPartyForm,
  isLoggedIn,
  t,
  disputeCategories = [],
}: UseDisputeFormPages) => {
  const [params] = useSearchParams()
  const { homeDescription } = useAppSelector((store) => store.Dispute)
  const [file, setFile] = useState<File>(null)
  const { is_legal_rep: isLegalRep, category: selectedCategory } = form.values

  const categoryType = params?.categoryType
  const jumpFirstParty = !isLegalRep
  const hasHomeCategory = !!categoryType

  const setIsLegalRep = (value) => {
    form.setFieldValue('is_legal_rep', value)
  }

  const handleFileSelect = (files: WebInputFile[]) => {
    const isPdf = FileUtils.isPdf(files[0]?.file?.name)

    if (!isPdf) {
      OSAlert.error({
        title: 'Error',
        body: 'You can only upload PDF files.',
      })
      return
    }
    setFile(files[0].file)
  }

  const handleSelectCategory = (category: DisputeCategory) => {
    form.setFieldValue('category', category.type)
  }

  const handleHomeDescriptionEdit = () => {
    DisputeRedux.setHomeDescription('')
  }

  useUnmount(() => {
    DisputeRedux.setHomeDescription('')
  })

  const pages: Record<string, Page> = {
    category: {
      name: 'category',
      title: 'Select a category',
      description: 'Please choose the category that best describes your dispute below.',
      jump: hasHomeCategory,
      hide: hasHomeCategory,
      scroll: true,
      disabled: !selectedCategory,
      children: (
        <>
          {disputeCategories?.map((category) => {
            const isSelected = selectedCategory === category.type

            return (
              <Button
                text={category.name}
                key={category.type}
                debugName={category.type}
                variants={[
                  isSelected ? 'select:selected' : 'select',
                  'fullWidth',
                ]}
                onPress={() => handleSelectCategory(category)}
                rightIcon={isSelected ? 'check' : null}
              />
            )
          })}
        </>
      ),
    },
    description: {
      name: 'description',
      title: 'Describe the dispute',
      jump: !hasHomeCategory,
      hide: !hasHomeCategory,
      disabled: !form.validateField('description')?.valid,
      children: (
        <View variants={['column']}>
          <TextInput
            {...form.register('description')}
            variants={['marginTop:2']}
            multiline
            style={{ minHeight: 120 }}
          />
        </View>
      ),
    },
    begin: {
      name: 'begin',
      title:
        'Thank you for beginning your MediationX dispute resolution journey',
      jump: hasHomeCategory,
      hide: hasHomeCategory,
      disabled: !form.validateField('description')?.valid,
      children: (
        <View variants={['column']}>
          {!!homeDescription ? (
            <View variants={['column']}>
              <Text
                variants={['marginBottom:2']}
                text={`So far you have told us:`}
              />

              <Text variants={['marginVertical:1']} text={homeDescription} />
            </View>
          ) : (
            <TextInput
              {...form.register('description')}
              variants={['marginTop:2']}
              multiline
              style={{ minHeight: 120 }}
            />
          )}
        </View>
      ),
      footer: !!homeDescription ? (
        <Touchable
          debugName='DisputeForm:Edit'
          onPress={handleHomeDescriptionEdit}
          variants={['marginTop:1']}
        >
          <Text variants={['p4']}>
            You will have further opportunities to provide more information. If you would like to amend what you have told us so far, you can do so by clicking <span css={{ color: '#6CAE75' }}>here</span>
          </Text>
        </Touchable>
      ) : null,
    },
    areYouLegalRep: {
      name: 'areYouLegalRep',
      title: 'Are you a legal representative?',
      description:
        'Please tell us if you are telling us about this dispute in the capacity of a legal representative.',
      children: (
        <View variants={['column', 'gap:2']}>
          <Button
            text='Yes'
            debugName='isLegalRep:Yes'
            variants={[isLegalRep ? 'select:selected' : 'select', 'fullWidth']}
            rightIcon={isLegalRep ? 'check' : null}
            onPress={() => setIsLegalRep(true)}
          />
          <Button
            text='No'
            debugName='isLegalRep:No'
            variants={[!isLegalRep ? 'select:selected' : 'select', 'fullWidth']}
            rightIcon={!isLegalRep ? 'check' : null}
            onPress={() => setIsLegalRep(false)}
          />
        </View>
      ),
    },
    firstPartyInfo: {
      name: 'firstPartyInfo',
      title: t('CreateDispute.FirstPartyInfo.title'),
      description: t(
        `CreateDispute.FirstPartyInfo.subtitle${isLegalRep ? 'LegalRep' : ''}`,
      ),
      disabled: !firstyPartyForm.isValid,
      children: <DisputeForms.Party form={firstyPartyForm} />,
      jump: jumpFirstParty,
    },
    secondPartyInfo: {
      name: 'secondPartyInfo',
      title: t('CreateDispute.SecondPartyInfo.title'),
      description: t('CreateDispute.SecondPartyInfo.subtitle'),
      disabled: !secondPartyForm.isValid,
      children: <DisputeForms.Party form={secondPartyForm} />,
    },
    disputeValue: {
      name: 'disputeValue',
      title: t('CreateDispute.DisputeValue.title'),
      description: t('CreateDispute.DisputeValue.subtitle'),
      canSkip: true,
      disabled: !FormUtils.isFieldValid(form, 'price'),
      authWall: !isLoggedIn,
      children: (
        <View variants={['column']}>
          <NumberIncrement
            {...form.register('price')}
            separator={','}
            prefix='£ '
            variants={['noIncrement']}
          />
        </View>
      ),
    },
    idealSolution: {
      name: 'idealSolution',
      title: t('CreateDispute.IdealSolution.title'),
      description: t('CreateDispute.IdealSolution.subtitle'),
      canSkip: true,
      disabled: !form.validateField('ideal_solution')?.valid,
      children: (
        <TextInput
          {...form.register('ideal_solution')}
          label={null}
          multiline
          style={{ minHeight: 120 }}
        />
      ),
    },
    additionalInfo: {
      name: 'additionalInfo',
      title: t('CreateDispute.AdditionalInformation.title'),
      description: t('CreateDispute.AdditionalInformation.subtitle'),
      disabled: !form.validateField('additional_info')?.valid,
      children: (
        <View variants={['column', 'gap:5']}>
          <TextInput
            {...form.register('additional_info')}
            label={null}
            multiline
            style={{ minHeight: 120 }}
            variants={['noError']}
          />

          <FileButton
            files={file ? [file] : undefined}
            onFileRemove={() => setFile(null)}
            onFileSelect={handleFileSelect}
            accept={'application/pdf'}
          />
        </View>
      ),
    },
  }

  return { pages, file, setFile }
}
