import { Button, Modal, Text } from '@/components'
import { Dispute, DisputeSettlement } from '@/types'
import { Navigation } from '@/utils'

type RequireSignatureModalProps = {
  disputeId: Dispute['id']
  settlementId: DisputeSettlement['id']
  visible: boolean
  toggle: () => void
}

export function RequireSignatureModal({ disputeId, settlementId, visible, toggle }: RequireSignatureModalProps) {
  return (
    <Modal
      toggle={toggle}
      visible={visible}
      debugName='RemoveParticipantAlert'
      title='Request signatures'
      variants={['centered', 'requestSignatureConfirmation']}
    >
      <Text text='The platform will retrieve the latest uploaded document to be signed by the users.' />
      <Button
        variants={['fullWidth']}
        debugName='RequireSignatureModalRequestSignaturesButton'
        text='Continue'
        onPress={() => {
          toggle()
          // @ts-ignore
          Navigation.navigate('RequestSignatures', { params: { dispute: disputeId, settlement: settlementId }})
        }}
      />
    </Modal>
  )
}
