import { OptionsDropDown } from '@/components'
import { APIClient } from '@/services'
import { Dispute, Profile } from '@/types'
import { DisputeUtils } from '@/utils'
import { TypeGuards } from '@codeleap/common'

export type ParticipantOptionsProps = {
  dispute: Dispute
  participant: Profile
  onCopyEmail?: (participant: Profile) => void
  onViewInformation?: (participant: Profile) => void
  onRemove?: (participant: Profile) => void
}

export function ParticipantOptions(props: ParticipantOptionsProps) {
  const {
    dispute,
    onCopyEmail,
    onViewInformation,
    onRemove,
    participant,
    ...rest
  } = props
  const { profile } = APIClient.Session.useSession()
  const { isMediator } = DisputeUtils.getParticipantInfo(dispute, profile)
  const { isMediator: isParticipantMediator, isOwner: isParticipantOwner } =
    DisputeUtils.getParticipantInfo(dispute, participant)

  if (!isMediator) return null

  const options = {
    mediator: [
      {
        text: 'Copy email',
        onPress: () => onCopyEmail(participant),
        debugName: 'Copy email Option',
      },
    ],
  }

  if (!isParticipantMediator && TypeGuards.isFunction(onViewInformation)) {
    options.mediator.push({
      text: 'View information',
      onPress: () => onViewInformation(participant),
      debugName: 'View information Option',
    })
  }

  if (!isParticipantOwner && !isParticipantMediator && TypeGuards.isFunction(onRemove)) {
    options.mediator.push({
      text: 'Remove',
      onPress: () => onRemove(participant),
      debugName: 'Remove Option',
      variants: ['destructive:minimal'],
    })
  }

  return (
    <OptionsDropDown
      {...rest}
      icon={'more-horizontal'}
      debugName='Options DropDown'
      options={options.mediator}
    />
  )
}
