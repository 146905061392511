import { LocalStorage as _LocalStorage } from '@codeleap/web'

export const LocalStorageKeys = {
  SESSION_IS_DEV: '@Session.isDevelopment',
  TESTER: '@CodeleapTester',
  LOCALE: '@locale',
  PERSIST_AUTH: '@auth.has_user',
  IN_REAUTHENTICATION: '@auth.in_reauthentication',
  DISPUTE_INVITE: '@dispute.invite',
  ONBOARDING: '@onboarding',
  PASSWORD: '@password',
  CREATE_DISPUTE: '@dispute.create',
}

export const LocalStorage = new _LocalStorage(LocalStorageKeys)
