import { AppImages } from '@/app'
import { AuthModal } from './AuthModal'
import { AuthForms } from '@/components'

export const ForgotAfterModal = () => {

  return (
    <AuthModal
      name='forgotAfter'
      image={AppImages.CheckEmail}
      imageHeight={221}
    >
      <AuthForms.ForgotPasswordAfterForm />
    </AuthModal>
  )
}
