import { variantProvider } from '@/app'
import { ThreadStyles } from '@/app/stylesheets'
import { ThreadComposition } from '@/app/stylesheets/Thread'
import { Avatar, CardBase, View, Text, CardBaseProps } from '@/components'
import { APIClient } from '@/services'
import { Dispute, DisputeThread } from '@/types'
import { ChatUtils, DateUtils, useGetStyles, useInterval } from '@/utils'
import { ComponentVariants, StylesOf, useDefaultComponentStyle, useMemo } from '@codeleap/common'

export type ThreadProps = ComponentVariants<typeof ThreadStyles> &
  Omit<CardBaseProps, 'variants' | 'styles'> & {
    styles?: StylesOf<ThreadComposition>
    thread: DisputeThread
    dispute: Dispute
    selected?: boolean
    isLast?: boolean
    hideBadge?: boolean
  }

export const Thread = ({
  variants = [],
  styles = {},
  style,
  thread,
  dispute,
  selected,
  isLast,
  hideBadge,
  responsiveVariants = {},
  ...otherProps
}: ThreadProps) => {
  const { isMediator } = APIClient.Session.useSession()
  const { data, refresh } = APIClient.Threads.threadsManager.useItem({
    id: thread.id,
  })

  useInterval(() => refresh().catch(), selected ? 5000 : 15000)

  const { last_message, type } = data ?? thread

  const { hasUnreadMessages, hasMessages } = useMemo(() => ({
    hasUnreadMessages: !!last_message?.content && !last_message?.read,
    hasMessages: !!last_message?.content,
  }), [data])

  const titleText = ChatUtils.chatTitles({ dispute, thread: type, isMediator })
  const avatarName = dispute[type]?.[0]?.first_name ?? 'Mediator'
  const avatarIcon = type === 'all' ? 'group' : null
  const name = last_message?.owner?.role === 'mediator' ? 'Mediator' : last_message?.owner?.first_name

  const variantStyles = useDefaultComponentStyle<'u:Thread', typeof ThreadStyles>('u:Thread', {
    variants,
    styles,
    responsiveVariants,
  })

  const { getStyles } = useGetStyles(variantStyles)

  return (
    <CardBase
      debugName='Chat Preview'
      css={[variantStyles.wrapper, style, selected && selectedStyles.wrapper, isLast && selectedStyles.last]}
      styles={{ ...getStyles('card'), ...styles }}
      {...otherProps}
    >
      <Avatar
        debugName='Avatar Chat'
        icon={avatarIcon as any}
        name={avatarName}
        badge={!hideBadge && hasUnreadMessages}
        badgeProps={{ styles: getStyles('badge') }}
        styles={getStyles('avatar')}
        style={!hasMessages && variantStyles.empty}
      />
      <View css={[variantStyles.content, !hasMessages && variantStyles.empty]}>
        <View css={[variantStyles.header]}>
          <Text text={titleText} css={[variantStyles.title]} />
          {hasMessages ? (
            <Text text={DateUtils.formatHour(last_message?.created_datetime)} css={[variantStyles.hour]} />
          ) : null}
        </View>

        {hasMessages ? (
          <View css={[variantStyles.body]}>
            <Text css={[variantStyles.message]}>
              <span css={[variantStyles.name]}>{`${name}: `}</span>
              {last_message?.content}
            </Text>
          </View>
        ) : null}
      </View>
    </CardBase>
  )
}

const selectedStyles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      backgroundColor: theme.colors.primary2,
    },
    last: {
      borderBottomLeftRadius: theme.borderRadius.medium,
      borderBottomRightRadius: theme.borderRadius.medium,
    },
  }),
  true,
)
