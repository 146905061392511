import { React, AppForms, variantProvider } from '@/app'
import { APIClient } from '@/services'
import { useForm, useI18N, useState } from '@codeleap/common'
import {
  Button,
  Text,
  TextInput,
  View,
  AuthFormComponents,
  OSAlert,
  TermsCheckboxes,
} from '@/components'
import { Navigation, useOTP, useUserInformation } from '@/utils'
import { useKeydown } from '@codeleap/web'
import { AppStatus, useAppSelector } from '@/redux'

type SignupFormProps = {
  title?: string
  asMediator?: boolean
}

const passwordRules = [
  'Password rules:',
  '- At least 12 characters',
  '- At least one uppercase letter',
  '- At least one lowercase letter',
  '- At least one special character',
]

export const SignupForm = ({
  title = null,
  asMediator = false,
}: SignupFormProps) => {
  const [signUpSource, setSignUpSource] = useState('email')
  const { creatingDispute } = useAppSelector((store) => store.Dispute)

  const form = useForm(
    signUpSource === 'email' ? AppForms.signup : AppForms.socialSignup,
  )

  const { t } = useI18N()

  const { signup } = APIClient.Session.useSignup()

  const isSocial = signUpSource !== 'email'

  const { ipAddress, latitude, longitude, ...rest } = useUserInformation()

  const handleSubmit = async (isSocialSignup = false, userValues = null) => {
    const additional_info = {
      ip: ipAddress,
      location: {
        latitude,
        longitude,
      },
      ...rest,
    }
    const isMediator = asMediator
    const values =
      userValues ?? isMediator
        ? {
          ...form?.values,
          role: 'mediator',
          additional_info,
        }
        : {
          ...form?.values,
          role: 'default',
          additional_info,
        }

    const result = await signup(values, signUpSource as any)

    if (result?.user) {
      if (creatingDispute) {
        Navigation.navigate('Disputes.CreateDispute', { params: { page: 'disputeValue' }})
        return
      }
      Navigation.navigate('Disputes.List')
      setTimeout(() => {
        AppStatus.setModal('onboarding')
      }, 1000)
    }
  }

  const { sendCode } = useOTP({
    email: form.values.email,
    onSuccess: handleSubmit,
    verify_email: false,
  })

  useKeydown(
    'Enter',
    () => {
      const isInvalidSubmit = !form.isValid
      if (isInvalidSubmit) return
      sendCode?.()
    },
    [form?.values],
  )

  return (
    <View variants={['column', 'fullWidth']}>
      <Text
        variants={['h1', 'extraBold', 'marginBottom:4']}
        responsiveVariants={{
          small: ['h2'],
        }}
        text={title || t('SignUp.title')}
      />
      <Text
        variants={['p1', 'marginBottom:4', 'marginTop:-3']}
        text={t('SignUp.subtitle') + ' '}
      />
      <View variants={['flex', 'column', 'gap:4', 'fullWidth']}>
        <View variants={['column', 'gap:1']}>
          <TextInput {...form.register('first_name')} />
          <TextInput {...form.register('last_name')} />
          <TextInput {...form.register('email')} disabled={isSocial} />
          {isSocial ? null : (
            <>
              <View variants={['column']}>
                {passwordRules.map((rule) => <Text variants={['p2', 'color:neutral7']} text={rule}/>)}
              </View>
              <TextInput {...form.register('password')} visibilityToggle />
              <TextInput
                {...form.register('repeatPassword')}
                visibilityToggle
              />
            </>
          )}
          <TextInput
            {...form.register('zip_code')}
            styles={{ innerWrapper: styles.zipInput }}
          />

          <TermsCheckboxes
            termsCheckboxProps={form.register('agree_terms_conditions')}
            mediationxCheckboxProps={form.register('agree_mediationx_terms')}
          />
        </View>

        {/*<AuthFormComponents.SocialProviders onPress={handleSocial} />*/}

        <View variants={['column', 'gap:2']}>
          <Button
            onPress={sendCode}
            disabled={!form.isValid}
            debugName={`submit Signup`}
            text={t('SignUp.submit')}
            variants={[
              'marginHorizontal:auto',
              'fullWidth',
              'large',
              'paddingHorizontal:2',
              !form.isValid && 'outline',
            ]}
            style={styles.submitBtn}
          />

          <AuthFormComponents.RedirectLink
            route={'Auth.Login'}
            text={`${t('SignUp.txt1')}`}
            textAction={`${t('actions.login')}`}
          />
        </View>
      </View>
    </View>
  )
}

const MAX_WIDTH = 320
const ZIP_WIDTH = 184

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    submitBtn: {
      maxWidth: MAX_WIDTH,
    },
    zipInput: {
      maxWidth: ZIP_WIDTH,
    },
  }),
  true,
)
