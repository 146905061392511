import React, { useState } from 'react'
import { TextInput } from '@/components'
import { AnyFunction } from '@codeleap/common'
import { TextInputProps } from '@codeleap/web'

type ToolBarProps = Omit<TextInputProps, 'id' | 'debugName'> & {
  onSend: AnyFunction
}

export function ToolBar({
  onSend,
  ...props
}: ToolBarProps) {
  const [message, setMessage] = useState('')

  async function handleSend() {
    const _message = message
    setMessage('')
    onSend(_message)
  }

  async function handleKeyDown(e) {
    if (e.key === 'Enter') {
      if (e.ctrlKey) return setMessage((prev) => prev + '\n')
      e.preventDefault()
      await handleSend()
    }
  }

  return (
    <TextInput
      {...props}
      debugName='Chat TextInput'
      placeholder='Send a message'
      value={message}
      onKeyDown={handleKeyDown}
      onChangeText={setMessage}
      multiline
      rightIcon={{
        debugName: 'Chat Send Message',
        name: 'send',
        onPress: handleSend,
        disabled: !message.trim(),
      }}
      variants={['noError']}
    />
  )
}
