import { TagComposition } from '@codeleap/web'
import { variantProvider, assignTextStyle } from '..'
import { CardBaseComposition } from './CardBase'
import { DisputeItemOptionsComposition } from './DisputeItemOptions'

export type DisputeItemComposition =
  | `card${Capitalize<CardBaseComposition>}`
  | 'titleWrapper'
  | 'innerWrapper'
  | 'index'
  | 'title'
  | `statusTag${Capitalize<TagComposition>}`
  | `categoryTag${Capitalize<TagComposition>}`
  | 'description'
  | `optionsBtn${Capitalize<DisputeItemOptionsComposition>}`

const createDisputeItemStyle =
  variantProvider.createVariantFactory<DisputeItemComposition>()

const overflowControl: any = {
  maxWidth: 300,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

export const DisputeItemStyles = {
  default: createDisputeItemStyle((theme) => ({
    cardWrapper: {
      ...theme.presets.fullWidth,
      borderRadius: 'none',
      borderBottom: `1px solid ${theme.colors.neutral3}`,
      [theme.media.down('midish')]: {
        minWidth: window.innerWidth,
      },
    },
    cardInnerWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.flex,
      ...theme.presets.alignCenter,
      ...theme.presets.row,
      ...theme.spacing.gap(3),
      gap: theme.spacing.value(3),
      [theme.media.down('small')]: {
        flexDirection: 'column',
        gap: theme.spacing.value(0.5),
        ...theme.presets.alignStart,
      },
    },
    innerWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.flex,
      ...theme.presets.alignCenter,
      ...theme.presets.row,
      ...theme.spacing.gap(3),
      gap: theme.spacing.value(3),
    },
    titleWrapper: {
      ...theme.presets.alignCenter,
      ...theme.presets.row,
      gap: theme.spacing.value(1),
      ...theme.spacing.marginRight('auto'),
    },
    index: {
      ...assignTextStyle('p1')(theme).text,
    },
    title: {
      ...theme.presets.flex,
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral9,
      lineHeight: '20px',
      ...overflowControl,
    },
    categoryTagText: {
      whiteSpace: 'nowrap',
    },
    description: {
      ...assignTextStyle('p3')(theme).text,
      ...overflowControl,
      maxWidth: '50%',
      [theme.media.down('largeish')]: {
        display: 'none',
      },
    },
  })),
  newMessages: createDisputeItemStyle((theme) => ({
    cardWrapper: {
      backgroundColor: theme.colors.primary1,
    },
    index: {
      ...assignTextStyle('h5')(theme).text,
    },
    title: {
      ...assignTextStyle('h5')(theme).text,
    },
    categoryTagWrapper: {
      border: `1px solid ${theme.colors.neutral3}`,
      backgroundColor: theme.colors.neutral1,
    },
    statusTagWrapper: {
      border: `1px solid ${theme.colors.neutral3}`,
      backgroundColor: theme.colors.neutral1,
    },
  })),
}
