import { api } from '@/app'
import { Dispute, DisputeQuiz, Profile } from '@/types'
import { PaginationResponse, QueryManager } from '@codeleap/common'
import { queryClient } from '../queryClient'
import { useFlatlistProps } from '@/utils'
import { AnyFile } from '@codeleap/common/dist/tools/Form/types'

const BASE_URL = 'disputes/quizzes/'

export const createQuiz = async (form?: {
  data: Partial<DisputeQuiz>
  file?: AnyFile
}) => {
  const response = await api.post(BASE_URL, form, { multipart: true })
  return response.data
}

export const updateQuiz = async (form?: {
  data: Partial<DisputeQuiz>
  file?: AnyFile
  id: DisputeQuiz['id']
}) => {
  const response = await api.patch(`${BASE_URL}${form?.id}/`, form, { multipart: true })
  return response.data
}

type ListParams = {
  dispute?: Dispute['id']
  owner?: Profile['id']
}

export const listQuizzes = async (params?: ListParams) => {
  const response = await api.get(BASE_URL, { params })
  return response.data
}

export const disputesManager = new QueryManager({
  itemType: {} as DisputeQuiz,
  name: 'Disputes',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<DisputeQuiz>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
      },
    })

    return response.data
  },
  actions: {},
})

type UseQuizzesProps = {
  dispute?: Dispute['id']
  owner?: Profile['id']
}

export const useQuizzes = ({ dispute, owner }: UseQuizzesProps) => {
  const quizzes = disputesManager.use({
    filter: { dispute, owner },
  })

  const quizzesListProps = useFlatlistProps(quizzes, {
    noMoreItemsText: null,
  })

  return {
    quizzes,
    quizzesListProps,
  }
}
