import { ActionIconComposition, TagComposition } from '@codeleap/web'
import { variantProvider } from '../theme'
import { assignTextStyle } from './Text'

type SettlementStates = '' | ':isLast' | ':isFirst' | ':isOnly'

export type SettlementItemComposition =
  | `wrapper${SettlementStates}`
  | 'innerWrapper'
  | 'leftContent'
  | 'leftIcon'
  | 'rightContent'
  | 'name'
  | `tag${Capitalize<TagComposition>}`
  | 'date'
  | `rightIcon${Capitalize<ActionIconComposition>}`

const createSettlementItemStyle =
  variantProvider.createVariantFactory<SettlementItemComposition>()

export const SettlementItemStyles = {
  default: createSettlementItemStyle((theme) => ({
    wrapper: {
      ...theme.presets.fullWidth,
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.padding(2),
      borderBottom: `1px solid ${theme.colors.neutral3}`,
      [theme.media.down('largeish')]: {
        ...theme.presets.column,
        ...theme.spacing.gap(2),
      },
    },
    'wrapper:isFirst': {
      borderTopLeftRadius: theme.borderRadius.small,
      borderTopRightRadius: theme.borderRadius.small,
    },
    'wrapper:isLast': {
      borderBottomLeftRadius: theme.borderRadius.small,
      borderBottomRightRadius: theme.borderRadius.small,
      borderBottom: 'none',
    },
    'wrapper:isOnly': {
      borderRadius: theme.borderRadius.small,
    },
    innerWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      ...theme.spacing.gap(2),
    },
    leftContent: {
      ...theme.presets.flex,
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      ...theme.spacing.gap(1),
    },
    leftIcon: {
      color: theme.colors.primary3,
    },
    name: {
      ...theme.presets.flex,
      width: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    tagWrapper: {
      backgroundColor: theme.colors.transparent,
      whiteSpace: 'nowrap',
      [theme.media.down('largeish')]: {
        backgroundColor: theme.colors.neutral2,
      },
    },
    tagText: {
      ...assignTextStyle('p3')(theme).text,
    },
    date: {
      ...theme.presets.textCenter,
    },
    rightIconIcon: {
      color: theme.colors.primary3,
    },
  })),
  caseDetails: createSettlementItemStyle((theme) => ({
    wrapper: {
      borderBottom: 'none',
      ...theme.spacing.padding(0),
    },
    name: {
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral7,
    },
    leftIcon: {
      ...theme.sized(2.5),
      color: theme.colors.neutral7,
    },
  })),
}
