import { api } from '@/app'
import { Dispute } from '@/types'
import { DisputeUtils, useFlatlistProps } from '@/utils'
import { PaginationResponse, QueryManager, TypeGuards } from '@codeleap/common'
import { queryClient } from '../queryClient'

const BASE_URL = 'disputes/'

export const disputesManager = new QueryManager({
  itemType: {} as Dispute,
  name: 'Disputes',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<Dispute>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
      },
    })

    return response.data
  },
  createItem: async (dispute: Dispute & { file?: File }) => {

    const formData = {
      data: {
        ...dispute,
        name: DisputeUtils.createDisputeName({
          firstPartyFullName: dispute?.first_party_info?.full_name,
          secondPartyFullName: dispute?.second_party_info?.full_name,
        }),
      },
      file: null,
    }

    if (!!dispute.file && !TypeGuards.isString(dispute.file)) {
      formData.file = dispute.file
    }

    const response = await api.post(BASE_URL, formData, { multipart: true })
    return response.data
  },
  updateItem: async (data) => {
    const formData = {
      data: {
        ...data,
        category: data.category,
        status: data.status,
      },
      file: data.file,
    }
    const response = await api.patch<Dispute>(`${BASE_URL}${data.id}/`, formData, { multipart: true })
    return response.data
  },
  retrieveItem: async (id) => {
    const response = await api.get<Dispute>(`${BASE_URL}${id}/`)

    return response.data
  },
  deleteItem: async (item) => {
    if (disputesManager.meta?.throwErrorForDelete) {
      throw new Error('Erro deleting dispute')
    }

    await api.delete(`${BASE_URL}${item.id}/`)
    return item
  },
  actions: {},
})

type UseDisputesProps = {
  status?: string
  name?: Dispute['name']
  interval?: number | false
}

export const useDisputes = ({ status = 'all', name, interval = false }: UseDisputesProps) => {
  const disputes = disputesManager.use({
    filter: {
      status: status === 'all' ? null : status,
      name,
    },
    listOptions: {
      queryOptions: {
        refetchInterval: interval,
      },
    },
  })

  const disputesListProps = useFlatlistProps(disputes, {
    noMoreItemsText: null,
  })

  return {
    disputes,
    disputesListProps,
  }
}

export * from './participants'
export * from './categories'
export * from './invites'
export * from './quizzes'
