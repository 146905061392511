import { Dispute } from '@/types'
import { createSlice, TypeGuards } from '@codeleap/common'

export type DisputeState = {
  dispute: Partial<Dispute> & { is_legal_rep: boolean }
  creatingDispute: boolean
  homeDescription: string
}

const initialState: DisputeState = {
  dispute: null,
  creatingDispute: false,
  homeDescription: '',
}

export const disputeSlice = createSlice({
  name: 'Dispute',
  initialState,
  reducers: {
    clear: () => initialState,
    setDispute: (_, dispute: Partial<Dispute>) => {

      return {
        dispute,
      }
    },
    setHomeDescription: (_, homeDescription: string) => ({ homeDescription }),
    toggleCreatingDispute: ({ creatingDispute }, to?: boolean) => ({ creatingDispute: TypeGuards.isBoolean(to) ? to : !creatingDispute }),
  },
  asyncReducers: {},
})
