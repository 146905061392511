import { I18N } from '@/app'
import { Dispute, DisputeCategory, DisputePartyInfo, Profile } from '@/types'
import { TypeGuards } from '@codeleap/common'

const defaultStatusOptions = [
  { label: 'All Disputes', value: 'all' },
  { label: 'Pending', value: 'pending' },
  { label: 'Open', value: 'open' },
  { label: 'Settled', value: 'settled' },
  { label: 'Closed', value: 'closed' },
]

const formatDisputeCategories = (categories: DisputeCategory[]) => {
  const formatedCategories = categories.map((category) => ({
    value: category.type,
    label: category.name,
  }))

  return formatedCategories
}

type CreateDisputeNameProps = {
  firstPartyFullName: DisputePartyInfo['full_name']
  secondPartyFullName: DisputePartyInfo['full_name']
}

const createDisputeName = ({
  firstPartyFullName,
  secondPartyFullName,
}: CreateDisputeNameProps) => {
  const firstPartyParts = (firstPartyFullName || '').split(' ')
  const secondPartyParts = (secondPartyFullName || '').split(' ')

  const firstPartyName = firstPartyParts.length > 1 ? firstPartyParts[firstPartyParts.length - 1] : firstPartyParts[0]
  const secondPartyName = secondPartyParts.length > 1 ? secondPartyParts[secondPartyParts.length - 1] : secondPartyParts[0]

  return `${firstPartyName} vs ${secondPartyName}`
}

const getParticipantInfo = (dispute: Dispute, participant: Profile) => {
  const isFirstPartieLegalRep = dispute?.first_parties_legal_reps?.some(
    (p) => p.id === participant?.id,
  )
  const isSecondPartieLegalRep = dispute?.second_parties_legal_reps?.some(
    (p) => p.id === participant?.id,
  )

  const isFirstPartie = dispute?.first_parties?.some(
    (p) => p.id === participant?.id,
  )
  const isSecondPartie = dispute?.second_parties?.some(
    (p) => p.id === participant?.id,
  )
  const isMediator = dispute?.mediator?.id === participant?.id
  const isOwner = dispute?.owner?.id === participant?.id
  const isLegalRep = isFirstPartieLegalRep || isSecondPartieLegalRep
  const isSpectator = (isFirstPartie || isSecondPartie) && !isLegalRep && !isOwner
  const isInfoSecondPartie = dispute?.second_party_info?.email === participant?.email

  return {
    isSpectator,
    isLegalRep,
    isOwner,
    isMediator,
    isSecondPartie,
    isFirstPartie,
    isFirstPartieLegalRep,
    isSecondPartieLegalRep,
    isInfoSecondPartie,
  }
}

const getParticipantRoleName = (dispute: Dispute, participant: Profile) => {
  const { isLegalRep, isMediator, isOwner, isSpectator, isInfoSecondPartie } = getParticipantInfo(
    dispute,
    participant,
  )
  if (isInfoSecondPartie) return 'Second Party'
  if (isLegalRep) return 'Legal Rep'
  if (isOwner) return 'Case Creator'
  if (isMediator) return 'Mediator'
  if (isSpectator) return 'Participant'
}

const priceToFloat = (price?: string) => {
  if (!price) return null

  const cleanedInput = price.replace(/[.,]/g, '')
  return parseFloat((parseInt(cleanedInput, 10) / 100).toFixed(2))
}

const homepageTypes = [
  { type: I18N.t('Homepage.disputeTypes.financial'), description: I18N.t('Homepage.disputeTypes.financialDescription') },
  { type: I18N.t('Homepage.disputeTypes.matrimonial'), description: I18N.t('Homepage.disputeTypes.matrimonialDescription') },
  { type: I18N.t('Homepage.disputeTypes.employment'), description: I18N.t('Homepage.disputeTypes.employmentDescription') },
  { type: I18N.t('Homepage.disputeTypes.flights'), description: I18N.t('Homepage.disputeTypes.flightsDescription') },
  { type: I18N.t('Homepage.disputeTypes.personal'), description: I18N.t('Homepage.disputeTypes.personalDescription') },
  { type: I18N.t('Homepage.disputeTypes.contract'), description: I18N.t('Homepage.disputeTypes.contractDescription') },
  // Below here is loren ipsum
  { type: 'Property', description: 'Dealing with real estate matters? Buying, selling, or renting property?' },
  { type: 'Criminal', description: 'Facing legal issues related to criminal charges? Get the right legal support.' },
  { type: 'Wills and Probate', description: 'Plan your estate and ensure your wishes are carried out after your passing.' },
  { type: 'Business', description: 'Legal assistance for your business, from contracts to disputes.' },
  { type: 'Immigration', description: 'Navigating the complexities of immigration laws and processes.' },
  { type: 'Intellectual Property', description: 'Protecting your creative and intellectual assets through patents, trademarks, and copyrights.' },
  { type: 'Family Law', description: 'Handling legal matters related to family relationships and domestic issues.' },
  { type: 'Personal Injury', description: 'Seeking compensation for injuries caused by accidents or negligence.' },
  { type: 'Bankruptcy', description: 'Dealing with financial difficulties? Explore bankruptcy and debt relief options.' },
  { type: 'Consumer Rights', description: 'Protecting your rights as a consumer in transactions and disputes.' },
  { type: 'Healthcare', description: 'Legal support for healthcare-related issues and medical malpractice cases.' },
  { type: 'Environmental', description: 'Addressing legal concerns related to environmental issues and regulations.' },
]

const formatUpdateForm = (dispute: Dispute, editForm) => {

  const category = TypeGuards.isString(editForm.category) ? editForm.category : editForm.category?.[0].value
  const status = TypeGuards.isString(editForm.status) ? editForm.status : editForm.status.value
  return {
    ...dispute,
    description: editForm.description,
    category: category,
    status: status,
    price: Number(editForm.price).toFixed(2),
    first_party_info: {
      full_name: editForm.first_party_name,
      address: editForm.first_party_address,
      phone: editForm.first_party_phone,
      email: editForm.first_party_email,
    },
    second_party_info: {
      full_name: editForm.second_party_name,
      address: editForm.second_party_address,
      phone: editForm.second_party_phone,
      email: editForm.second_party_email,
    },
  }
}

const availableStatus = [
  { text: 'Open', value: 'open', debugName: '' },
  { text: 'Closed', value: 'closed', debugName: '' },
  { text: 'Settled', value: 'settled', debugName: '' },
  { text: 'Pending', value: 'pending', debugName: '' },
]

export const DisputeUtils = {
  defaultStatusOptions,
  priceToFloat,
  formatDisputeCategories,
  createDisputeName,
  getParticipantInfo,
  getParticipantRoleName,
  homepageTypes,
  formatUpdateForm,
  availableStatus,
}
