import { variantProvider } from '@/app'
import { View, Text, CenterWrapper, Button } from '@/components'
import { Dispute } from '@/redux'
import { Navigation } from '@/utils'
import { useI18N } from '@codeleap/common'

export const PricingSection = () => {
  const { t } = useI18N()

  return (
    <View
      variants={['fullWidth', 'paddingTop:10']}
      responsiveVariants={{
        mid: ['paddingTop:5'],
      }}
    >
      <View
        variants={['fullWidth', 'paddingVertical:10']}
        responsiveVariants={{
          small: ['paddingVertical:5'],
        }}
        style={styles.beginMediationWrapper}
      >
        <CenterWrapper>
          <View variants={['fullWidth', 'center', 'column']}>
            <Text text={t('Homepage.price.title1')} variants={['h1', 'color:neutral8', 'marginBottom:1', 'textCenter']} />
            <View
              variants={['row', 'marginBottom:5', 'gap:1']}
              responsiveVariants={{
                mid: ['column', 'center', 'gap:0'],
              }}
            >
              <Text text={t('Homepage.price.title2')} variants={['h1', 'color:neutral8']} />
              <Text text={t('Homepage.price.title3')} variants={['h1', 'color:primary3', 'underline', 'textCenter']} />
            </View>

            <Button
              text={t('Homepage.price.btn')}
              onPress={() => {
                Dispute.clear()
                Navigation.navigate('Disputes.CreateDispute', {
                  params: { from: 'home', page: 'begin', clear: true },
                })
              }}
              variants={['fullWidth', 'large', 'marginBottom:5']}
              style={styles.button}
              debugName='Homepage getStarted'
            />
            <View variants={['column', 'gap:2']}>
              <Text
                text={t('Homepage.price.feeInfo1')}
                style={styles.feeInfo}
                variants={['p1', 'color:neutral8']}
                responsiveVariants={{
                  mid: ['textCenter'],
                }}
              />
              <Text
                text={t('Homepage.price.feeInfo2')}
                style={styles.feeInfo}
                variants={['p1', 'color:neutral8']}
                responsiveVariants={{
                  mid: ['textCenter'],
                }}
              />
            </View>
          </View>
        </CenterWrapper>
      </View>
    </View>
  )
}
const BUTTON_MAX_WIDTH = 320
const FEE_INFO_MAX_WIDTH = 589

const styles = variantProvider.createComponentStyle(theme => ({
  beginMediationWrapper: {
    background: 'linear-gradient(180deg, rgba(229, 245, 231, 0.00) 0%, #E5F5E7 100%)',
  },
  button: {
    maxWidth: BUTTON_MAX_WIDTH,
  },
  feeInfo: {
    maxWidth: FEE_INFO_MAX_WIDTH,
    ...theme.presets.textCenter,
    [theme.media.down('small')]: {
      ...theme.spacing.marginHorizontal(2),
    },
  },
}), true)
