import React from 'react'
import { ActionIcon, View, Image } from '@/components'
import { PropsOf } from '@codeleap/common'
import { Theme, variantProvider } from '@/app'
import { AppStatus, ModalName } from '@/redux'

export type AuthModalHeaderProps = {
  name: ModalName
  image: PropsOf<typeof Image>['source']
  centered?: boolean
  showClose?: boolean
  imageHeight?: number
}

const ModalHeaderComponent = ({ image, name, centered = false, showClose = true, imageHeight = 138 }: AuthModalHeaderProps) => {
  return (
    <View variants={['column', 'justifyCenter', 'bg:primary1']}
      style={styles.modalHeader}
    >
      <View variants={['column', 'justifyCenter', 'relative', 'alignCenter', !centered && 'justifyEnd']} style={styles.innerWrapper} >
        {showClose ? (
          <ActionIcon
            debugName={`${name}:closeButton`}
            name={'x'}
            variants={['minimal', 'primary3', 'alignSelfEnd', 'absolute']}
            style={styles.modalHeaderIcon}
            iconProps={{
              size: Theme.values.iconSize[3],
            }}
            onPress={() => AppStatus.setModal(name)}
          />
        ) : null}
        <Image
          source={image}
          objectFit='contain'
          css={[{ height: imageHeight }]}
        />
      </View>
    </View>
  )
}

export const AuthModalHeader = React.memo(ModalHeaderComponent, () => true)

const MODAL_HEADER_HEIGHT = 221

const styles = variantProvider.createComponentStyle((theme) => ({
  innerWrapper: {
    ...theme.presets.fullHeight,
  },
  modalHeader: {
    height: MODAL_HEADER_HEIGHT,

  },
  modalHeaderIcon: {
    top: 0,
    margin: theme.spacing.value(2),
  },
}), true)
