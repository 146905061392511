import { onUpdate } from '@codeleap/common'

export type UseUserTimezoneProps = {
  isLoggedIn: boolean
  onChange: (timezone: string) => void
}

export const useUserTimezone = ({ onChange, isLoggedIn }) => {
  onUpdate(() => {
    if (!isLoggedIn) return
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (!!timezone) onChange?.(timezone)
  }, [isLoggedIn])
}
