import { api } from '@/app'
import { DisputeThread } from '@/types'
import { PaginationResponse, QueryManager } from '@codeleap/common'
import { queryClient } from '../queryClient'

const BASE_URL = 'crm/dispute_threads/'

export const threadsManager = new QueryManager({
  itemType: {} as DisputeThread,
  name: 'Disputes Threads',
  queryClient: queryClient.client,

  listItems: async (limit, offset, filters) => {
    const response = await api.get<PaginationResponse<DisputeThread>>(
      BASE_URL,
      {
        params: {
          limit,
          offset,
          ...filters,
        },
      },
    )
    return response.data
  },
  retrieveItem: async (id) => {
    const response = await api.get<DisputeThread>(`${BASE_URL}${id}/`)

    return response.data
  },
  actions: {},
})

export * from './messages'
