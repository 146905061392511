import { variantProvider } from '@/app'
import { View, Text, CenterWrapper, HowWorks } from '@/components'
import { useI18N } from '@codeleap/common'

export const HowItWorksSection = () => {
  const { t } = useI18N()

  return (
    <View
      variants={['fullWidth', 'paddingVertical:15']}
      responsiveVariants={{
        small: ['paddingVertical:10'],
        smallish: ['paddingVertical:5'],
      }}
      style={styles.bannerGradient}
    >
      <CenterWrapper>
        <View variants={['fullWidth', 'column']}>
          <Text
            text={t('Homepage.howWorks.title')}
            variants={['hx', 'color:neutral1', 'marginBottom:5']}
            responsiveVariants={{
              small: ['h1', 'color:neutral1', 'marginBottom:5'],
            }}
          />

          <HowWorks />
        </View>
      </CenterWrapper>
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  bannerGradient: {
    background: 'linear-gradient(265deg, #49A674 10.83%, #6CAE75 89.36%)',
  },
}), true)
