import { createForm, yup } from '@codeleap/common'

export const inviteParticipant = createForm('inviteParticipant', {
  full_name: {
    type: 'text',
    label: 'Name',
    placeholder: 'First and last name',
    validate: yup.string().required(),
  },
  email: {
    type: 'text',
    label: 'Email',
    placeholder: 'example@email.com',
    validate: yup.string().email().required(),
  },
  role: {
    label: 'Select function',
    type: 'radio',
    options: [
      { label: 'Legal Representative', value: 'legal_rep' },
      { label: 'Participant', value: 'spectator' },
    ],
    defaultValue: 'spectator',
  },
  party: {
    label: 'Select party',
    type: 'radio',
    options: [
      { label: 'First', value: 'first_party' },
      { label: 'Second', value: 'second_party' },
    ],
    defaultValue: 'first_party',
  },
  skip_quiz: {
    type: 'checkbox',
    label: 'Skip Confidental Questionnaire',
    defaultValue: false,
  },
})
