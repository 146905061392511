import { ActionIcon, Icon, Text, View } from '@/components'
import { FileUtils, useSettlements } from '@/utils'

export function Settlement({ settlement, dispute, getStyles, isSignable }) {
  const { allIsSigned, hasSigned, signedDate, userCanSign, handleDownloadFile, NavigateToSignButton } =
    useSettlements({
      dispute,
      settlement,
    })

  const allowSignButton = isSignable && userCanSign

  const settlementName = FileUtils.formatFileName(settlement.file as string)?.replace('.pdf', '')
  const settlementSize = FileUtils.formatFileSize(settlement.file_size)

  return (
    <View css={getStyles('settlementWrapper')}>
      {hasSigned && <Text css={getStyles('settlementTextSigned')} text={`Signed on ${signedDate}`} />}
      <View css={getStyles('settlementInnerWrapper')}>
        <View css={getStyles('settlementOuterWrapper')}>
          {!allowSignButton && <Icon name='file-text' debugName='file-text icon' style={getStyles('settlementIcon')} />}
          <View css={getStyles('settlementTextWrapper')}>
            <Text css={getStyles('settlementTitle')} text={settlementName} variant='small' />
            <Text css={getStyles('settlementText')} text={`${settlementSize} • pdf`} variant='small' />
          </View>
          <ActionIcon
            name='download'
            variants={['minimal', 'neutral10']}
            styles={getStyles('settlementActionIcon')}
            onPress={() => handleDownloadFile(settlement)}
            debugName='download actionIcon'
          />
        </View>
        {allowSignButton && !allIsSigned && (
          <NavigateToSignButton
            variants={['outline']}
            disabled={hasSigned}
            styles={getStyles('settlementSign')}
            text={hasSigned ? 'Awaiting signatures' : 'Sign now'}
          />
        )}
      </View>
    </View>
  )
}
