import { assignTextStyle, includePresets } from '@codeleap/common'
import { variantProvider } from '../theme'

export type PageSectionComposition =
  | 'wrapper'
  | 'header'
  | 'contentWrapper'
  | 'title'
  | 'void'

const createPageSectionStyle =
  variantProvider.createVariantFactory<PageSectionComposition>()

const presets = includePresets((s) => createPageSectionStyle(() => ({ wrapper: s })))

export const PageSectionStyles = {
  ...presets,
  default: createPageSectionStyle((theme) => ({
    wrapper: {
      height: 'max-content',
      minHeight: '100%',
      ...theme.presets.flex,
      ...theme.presets.column,
      borderTopLeftRadius: theme.borderRadius.medium,
      borderTopRightRadius: theme.borderRadius.medium,
      borderLeft: `1px solid ${theme.colors.neutral3}`,
      backgroundColor: theme.colors.neutral2,
      [theme.media.down('midish')]: {
        borderLeft: 'none',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
    header: {
      ...theme.presets.justifySpaceBetween,
      ...theme.presets.alignCenter,
      ...theme.spacing.padding(2),
      backgroundColor: theme.colors.primary3,
      borderTopLeftRadius: theme.borderRadius.small,
      borderTopRightRadius: theme.borderRadius.small,
      [theme.media.down('midish')]: {
        ...theme.spacing.padding(0),
        ...theme.spacing.paddingHorizontal(2),
        minHeight: theme.values.itemHeight.default,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
    title: {
      ...assignTextStyle('h4')(theme).text,
      color: theme.colors.neutral1,
      [theme.media.down('midish')]: {
        ...assignTextStyle('h5')(theme).text,
        color: theme.colors.neutral1,
      },
    },
    contentWrapper: {
      ...theme.spacing.padding(2),
      ...theme.presets.fullHeight,
    },
    void: {
      ...theme.sized(4),
    },
  })),
}
