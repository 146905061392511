import React from 'react'
import {
  CardBase,
  CardBaseProps,
  DisputeItemOptions,
  DisputeStatusTag,
  Text,
  View,
} from '@/components'
import { Dispute } from '@/types'
import { DisputeCategoryTag } from './DisputeCategoryTag'
import {
  ComponentVariants,
  getNestedStylesByKey,
  useDefaultComponentStyle,
} from '@codeleap/common'
import { DisputeItemStyles } from '@/app/stylesheets'
import { useIsMobile } from '@/utils'
import { variantProvider } from '@/app'

export type DisputeItemProps = Omit<
  CardBaseProps,
  'variants' | 'styles' | 'style'
> &
  ComponentVariants<typeof DisputeItemStyles> & {
    dispute: Dispute
    styles?: Partial<typeof DisputeItemStyles>
    style?: StyleSheet
    isMediator?: boolean
  }

export function DisputeItem({
  dispute,
  variants,
  responsiveVariants,
  styles,
  onPress,
  isMediator = false,
  ...props
}: DisputeItemProps) {
  const { name, description, status, category, has_new_messages: newMessages } = dispute

  const isMobile = useIsMobile()

  const variantStyles = useDefaultComponentStyle<
    'u:DisputeItem',
    typeof DisputeItemStyles
  >('u:DisputeItem', {
    variants,
    styles,
    responsiveVariants,
  })

  const getStyles = (key) => ({
    ...variantStyles[key],
    ...getNestedStylesByKey(key, variantStyles),
  })

  const Title = () => (
    <View css={variantStyles.titleWrapper}>
      <Text css={variantStyles.index} text={dispute.id} />
      <Text css={variantStyles.title} text={name} />
    </View>
  )

  const Tags = ({ statusFirst = false }) => (
    <View variants={['gap:3']} responsiveVariants={{
      largeish: ['gap:2'],
      small: ['gap:1'],
    }}>
      {statusFirst ? <DisputeStatusTag status={status} styles={getStyles('statusTag')}/> : null}
      <DisputeCategoryTag category={category} styles={getStyles('categoryTag')}/>
      {!statusFirst ? <DisputeStatusTag status={status} styles={getStyles('statusTag')}/> : null}
    </View>
  )

  return (
    <CardBase
      debugName={`DisputeItem ${name}`}
      css={_styles[newMessages ? 'newMessages' : 'default']}
      styles={getStyles('card')}
      propagate={false}
      onPress={onPress}
      {...props}
    >
      <View css={getStyles('innerWrapper')}>
        <Title />
        {!isMobile ? <Tags /> : null}
        <Text css={variantStyles.description} text={description} />
        <DisputeItemOptions
          styles={getStyles('optionsBtn')}
          isMediator={isMediator}
          dispute={dispute}
        />
      </View>
      {isMobile ? <Tags statusFirst/> : null}
    </CardBase>
  )
}

const _styles = variantProvider.createComponentStyle(theme => ({
  default: {
    backgroundColor: theme.colors.neutral1,
  },
  newMessages: {
    backgroundColor: theme.colors.primary1,
  },
}), true)
