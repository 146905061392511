import { assignTextStyle } from './Text'
import { variantProvider } from '../theme'
import { TagComposition, TagPresets } from '@codeleap/web'

const createTagStyles =
  variantProvider.createVariantFactory<TagComposition>('wrapper')

const defaultStyles = TagPresets

export const TagStyles = {
  ...defaultStyles,
  default: createTagStyles((theme) => {
    const DEFAULT_HEIGHT = 32
    const backgroundColor = theme.colors.primary1
    const color = theme.colors.neutral8
    const iconSize = theme.values.iconSize[1]

    return {
      wrapper: {
        width: 'fit-content',
        height: DEFAULT_HEIGHT,
        padding: theme.spacing.value(1),
        borderRadius: theme.borderRadius.tiny,
        backgroundColor,
        ...theme.presets.alignCenter,
        ...theme.presets.justifyCenter,
        ...theme.presets.row,
      },
      'wrapper:disabled': {
        cursor: 'not-allowed',
      },
      leftWrapper: {
        ...theme.presets.alignCenter,
        ...theme.presets.justifyCenter,
        ...theme.presets.row,
        marginRight: theme.spacing.value(0.5),
      },
      rightWrapper: {
        ...theme.presets.alignCenter,
        ...theme.presets.justifyCenter,
        ...theme.presets.row,
        marginLeft: theme.spacing.value(0.5),
      },
      text: {
        color,
        ...assignTextStyle('p3')(theme).text,
        lineHeight: '16px',
        marginVertical: 'auto',
      },
      leftIcon: {
        color,
        height: iconSize,
        width: iconSize,
        marginRight: theme.spacing.value(0.5),
      },
      rightIcon: {
        color,
        height: iconSize,
        width: iconSize,
        marginLeft: theme.spacing.value(0.5),
      },
      rightBadgeWrapper: {
        ...theme.presets.relative,
        marginLeft: theme.spacing.value(0.5),
      },
      leftBadgeWrapper: {
        ...theme.presets.relative,
        marginRight: theme.spacing.value(0.5),
      },
    }
  }),
  neutral: createTagStyles((theme) => {
    const backgroundColor = theme.colors.neutral2
    const color = theme.colors.neutral8

    return {
      wrapper: { backgroundColor },
      text: { color },
      leftIcon: { color },
      rightIcon: { color },
    }
  }),
  destructive: createTagStyles((theme) => {
    const backgroundColor = theme.colors.destructive1
    return { wrapper: { backgroundColor }}
  }),
  'border:small': createTagStyles((theme) => ({
    wrapper: { borderRadius: theme.borderRadius.small },
  })),
  'border:medium': createTagStyles((theme) => ({
    wrapper: { borderRadius: theme.borderRadius.medium },
  })),
}
