import { logger, variantProvider } from '@/app'
import { Button, Modal, Pager, View } from '@/components'
import { AppStatus, Dispute, useAppSelector } from '@/redux'
import { mediatorOnboardingPages, onboardingPages } from './pages'
import { Page } from './Page'
import { useState } from 'react'
import { APIClient } from '@/services'

export const OnboardingModal = () => {
  const modals = useAppSelector((store) => store.AppStatus.modals)
  const { creatingDispute } = useAppSelector((store) => store.Dispute)
  const { profile, isMediator } = APIClient.Session.useSession()
  const { editProfile } = APIClient.Session.useEdit()
  const pages = isMediator ? mediatorOnboardingPages : onboardingPages

  const [page, setPage] = useState(0)

  const visible = modals.onboarding
  const toggle = () => AppStatus.setModal(['onboarding', false])

  const handleDotPress = (dot) => {
    setPage(dot)
  }

  const onOnboardingFinished = async () => {
    if (!profile?.onboarding_finished) {
      try {
        await editProfile({
          id: profile?.id,
          first_name: profile?.first_name,
          last_name: profile?.last_name,
          email: profile?.email,
          zip_code: profile?.zip_code,
          onboarding_finished: true,
        })
      } catch (e) {
        logger.error('Error while finishing onboarding', e, 'Onboarding Modal')
      }
    }
  }

  const handleCloseOnCreate = () => {
    Dispute.clear()
    setPage(0)
    AppStatus.transitionModals(['onboarding', 'paywall'])
    onOnboardingFinished()
  }

  const onClose = () => {
    if (creatingDispute) {
      handleCloseOnCreate()
      return
    }
    onOnboardingFinished()
    toggle()
    setTimeout(() => {
      setPage(0)
    }, 200)
  }

  const isLast = page === pages.length - 1

  const handleNext = () => {
    if (isLast) {
      if (creatingDispute) {
        handleCloseOnCreate()
        return
      }
      onClose()
      return
    }
    setPage((state) => state + 1)
  }

  return (
    <Modal
      visible={visible}
      style={styles.wrapper}
      styles={{
        box: styles.box,
        header: styles.header,
        body: styles.body,
        innerWrapper: styles.innerWrapper,
      }}
      responsiveVariants={{
        small: ['fullscreen'],
      }}
      onClose={onClose}
      closeButtonProps={{ style: styles.close }}
    >
      <Pager
        debugName={'pager:onboarding'}
        dots
        dotsProps={{ onPress: handleDotPress }}
        page={page}
        variants={['fullHeight']}
        onChange={setPage}
        beforeChange={(_, n) => setPage(n)}
        styles={{
          footerWrapper: styles.footerWrapper,
        }}
        adaptiveHeight
        footer={
          <View variants={['fullWidth', 'center', 'marginBottom:4']}>
            <Button
              debugName={'Onboarding Next Button'}
              text={isLast ? 'Dismiss' : 'Next'}
              variants={['fullWidth']}
              style={styles.buttonWrapper}
              onPress={handleNext}
              debounce={1000}
            />
          </View>
        }
      >
        {pages.map((page, index) => {
          return <Page key={index} {...page} />
        })}
      </Pager>
    </Modal>
  )
}

const MODAL_WIDTH = 800

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      ...theme.presets.fullWidth,
      maxWidth: MODAL_WIDTH,
      ...theme.spacing.marginHorizontal(8),
      [theme.media.down('small')]: {
        ...theme.spacing.marginHorizontal(0),
        paddingTop: 0,
      },
    },
    box: {
      ...theme.spacing.padding(0),
      maxHeight: '100%',
      overflow: 'auto',
    },
    body: {
      ...theme.spacing.paddingBottom(4),
      [theme.media.down('small')]: {
        overflow: 'scroll',
      },
    },
    innerWrapper: {
      padding: theme.spacing.value(2),
      ...theme.presets.alignCenter,
    },
    header: {
      ...theme.spacing.marginBottom(0),
    },
    close: {
      ...theme.spacing.marginTop(4),
      ...theme.spacing.marginRight(4),
      zIndex: 1,
    },
    buttonWrapper: {
      maxWidth: 320,
      height: 48,
    },
    footerWrapper: {
      [theme.media.down('small')]: {
        ...theme.spacing.paddingBottom(2),
      },
    },
  }),
  true,
)
