import { View, Text, Link } from '@/components'
import { RoutePath } from '@/utils'
import { StylesOf } from '@codeleap/common'
import { FooterComposition } from '../../../app/stylesheets/Footer'
import { Settings } from '@/app'

export type FooterNavigateProps = {
  variantStyles?: StylesOf<FooterComposition>
}

type NavigateLink = {
  text: string
  route?: RoutePath
  to?: string
}

const links: Record<string, NavigateLink[]> = {
  pages: [
    { text: 'Home', route: 'Home' },
    { text: 'Terms and Conditions', route: Settings.ContactINFO.TermsAndConditions },
    { text: 'Our mission', route: 'OurMission' },
    { text: 'Apply to be a MediationX Mediator', route: 'BeMediator' },
  ],
  others: [
    { text: 'About', route: 'About' },
    { text: 'Docs', to: 'http://docs.codeleap.co.uk/' },
  ],
}

function Column({ links, title, variantStyles }) {
  const renderLink = (link, index) => (
    <Link
      key={index + 'footer-link'}
      route={link?.route}
      to={link?.to}
      openNewTab={!!link?.to}
      text={link?.text}
      css={variantStyles.navigateLink}
    />
  )

  return (
    <View css={variantStyles.navigateColumn}>
      {!!title && <Text css={variantStyles.navigateTitle} text={title} />}
      {links.map(renderLink)}
    </View>
  )
}

export const FooterNavigate = (props: FooterNavigateProps) => {
  const { variantStyles } = props

  return (
    <View css={variantStyles.navigateWrapper}>
      <Column variantStyles={variantStyles} links={links?.pages} />
      {/* <Column
        variantStyles={variantStyles}
        title='Terms & Conditions'
        links={links?.others}
      /> */}
    </View>
  )
}
