import { View, Text, CenterWrapper, DisputeTypeSlider } from '@/components'
import { useI18N } from '@codeleap/common'

export const CategorySection = () => {

  const { t } = useI18N()

  return (
    <CenterWrapper variants={['noPaddingSmall']}>
      <View
        variants={['fullWidth', 'column', 'backgroundColor:neutral1', 'paddingVertical:10']}
        responsiveVariants={{
          mid: ['fullWidth', 'column', 'backgroundColor:neutral1', 'paddingTop:5'],
        }}
      >
        <View
          variants={['column']}
          responsiveVariants={{
            small: ['paddingHorizontal:2'],
          }}
        >
          <Text
            text={t('Homepage.disputeTypes.title')}
            variants={['h0', 'color:primary3', 'marginBottom:2']}
            responsiveVariants={{
              small: ['h1', 'color:primary3', 'marginBottom:2'],
            }}
          />
          <Text
            text={t('Homepage.disputeTypes.subtitle')}
            variants={['h4', 'color:neutral8', 'marginBottom:5']}
            responsiveVariants={{
              small: ['p1', 'color:neutral8', 'marginBottom:5'],
            }}
          />
        </View>

        <DisputeTypeSlider />

      </View>
    </CenterWrapper>
  )
}
