import { deepMerge } from '@codeleap/common'

export const IS_SSR = typeof window === 'undefined'

// Add here any route that need authentication
export const AUTHENTICATED_ROUTES = {
  Disputes: {
    List: '/disputes',
    CreateQuiz: '/disputes/create_quiz',
    Dispute: '/disputes',
    DisputeDetails: '/disputes/{{id}}/case_details',
    DisputeChat: '/disputes/{{id}}/chat',
    DisputeSettlements: '/disputes/{{id}}/settlements',
    DisputeParticipants: '/disputes/{{id}}/participants',
  },
  Profile: {
    Edit: '/profile/edit',
  },
  RequestSignatures: '/request_signatures',
  SignDocument: '/sign_document',
}

export const NON_AUTHENTICATED_ROUTES = {
  // add a new route following the pattern of the others
  Home: '/home',
  Auth: {
    Login: '/auth/login',
    Signup: '/auth/signup',
    ForgotPassword: '/auth/forgot',
  },
  OurMission: '/our_mission',
  TermsConditions: '/terms_and_conditions',
  CrudExample: '/crud',
  Playground: '/playground',
  Wait: '/wait',
  BeMediator: '/be_a_mediator',
  Disputes: {
    CreateDispute: '/disputes/create',
  },
}

export const ROUTES = deepMerge(NON_AUTHENTICATED_ROUTES, AUTHENTICATED_ROUTES)

