import { ActivityIndicator, MainLayout, MainLayoutProps, DisputeInfo, View } from '@/components'
import { variantProvider } from '@/app'
import { cloneElement, PropsWithChildren } from 'react'
import { Dispute } from '@/types'
import { APIClient } from '@/services'
import { useMediaQueryDown } from '@/utils'

type DisputeLayoutProps = MainLayoutProps &
  PropsWithChildren & {
    id: Dispute['id']
  }

export const DisputeLayout = ({ id, children, ...props }: DisputeLayoutProps) => {
  const isMidsh = useMediaQueryDown('midish')

  const { items: threads } = APIClient.Threads.threadsManager.useList({ filter: { dispute: id }})
  const { data: dispute } = APIClient.Disputes.disputesManager.useRetrieve({
    id,
    queryOptions: { refetchInterval: 10000 },
  })

  if (!dispute && !threads) return <ActivityIndicator debugName='ActivityIndicator DisputeDetails' variants={['margin:auto']} />
  return (
    <MainLayout {...props}>
      <View css={styles.wrapper}>
        {(!isMidsh || !children) && <DisputeInfo dispute={dispute} threads={threads}/>}
        {!!children && cloneElement(children as JSX.Element, { dispute, threads })}
      </View>
    </MainLayout>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      ...theme.presets.flex,
    },
  }),
  true,
)
