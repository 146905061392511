import { AppStatus } from '@/redux'
import { Dispute } from '@/types'
import { useCallback } from 'react'

export const usePaywall = (dispute: Dispute) => {

  const checkPayment = useCallback(
    (callback = () => {}) => {
      if (!dispute?.paid) {
        AppStatus.setModal('paywall')
        return
      }

      callback?.()
    },
    [dispute?.paid],
  )

  return { isPaid: dispute?.paid, checkPayment }
}
