import { createForm, yup } from '@codeleap/common'

export const requestSignatures = createForm('inviteParticipant', {
  first_party: {
    type: 'text',
    required: true,
    validate: yup.string().required('First party is required'),
  },
  second_party: {
    type: 'text',
    required: true,
    validate: yup.string().required('Second party is required'),
  },
})
