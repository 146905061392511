exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-auth-forgot-tsx": () => import("./../../../src/pages/auth/forgot.tsx" /* webpackChunkName: "component---src-pages-auth-forgot-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-mediator-signup-tsx": () => import("./../../../src/pages/auth/mediator_signup.tsx" /* webpackChunkName: "component---src-pages-auth-mediator-signup-tsx" */),
  "component---src-pages-auth-signup-tsx": () => import("./../../../src/pages/auth/signup.tsx" /* webpackChunkName: "component---src-pages-auth-signup-tsx" */),
  "component---src-pages-be-a-mediator-tsx": () => import("./../../../src/pages/be_a_mediator.tsx" /* webpackChunkName: "component---src-pages-be-a-mediator-tsx" */),
  "component---src-pages-disputes-[id]-case-details-tsx": () => import("./../../../src/pages/disputes/[id]/case_details.tsx" /* webpackChunkName: "component---src-pages-disputes-[id]-case-details-tsx" */),
  "component---src-pages-disputes-[id]-chat-tsx": () => import("./../../../src/pages/disputes/[id]/chat.tsx" /* webpackChunkName: "component---src-pages-disputes-[id]-chat-tsx" */),
  "component---src-pages-disputes-[id]-index-tsx": () => import("./../../../src/pages/disputes/[id]/index.tsx" /* webpackChunkName: "component---src-pages-disputes-[id]-index-tsx" */),
  "component---src-pages-disputes-[id]-participants-tsx": () => import("./../../../src/pages/disputes/[id]/participants.tsx" /* webpackChunkName: "component---src-pages-disputes-[id]-participants-tsx" */),
  "component---src-pages-disputes-[id]-settlements-tsx": () => import("./../../../src/pages/disputes/[id]/settlements.tsx" /* webpackChunkName: "component---src-pages-disputes-[id]-settlements-tsx" */),
  "component---src-pages-disputes-create-quiz-tsx": () => import("./../../../src/pages/disputes/create_quiz.tsx" /* webpackChunkName: "component---src-pages-disputes-create-quiz-tsx" */),
  "component---src-pages-disputes-create-tsx": () => import("./../../../src/pages/disputes/create.tsx" /* webpackChunkName: "component---src-pages-disputes-create-tsx" */),
  "component---src-pages-disputes-index-tsx": () => import("./../../../src/pages/disputes/index.tsx" /* webpackChunkName: "component---src-pages-disputes-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-mission-tsx": () => import("./../../../src/pages/our_mission.tsx" /* webpackChunkName: "component---src-pages-our-mission-tsx" */),
  "component---src-pages-profile-edit-tsx": () => import("./../../../src/pages/profile/edit.tsx" /* webpackChunkName: "component---src-pages-profile-edit-tsx" */),
  "component---src-pages-request-signatures-tsx": () => import("./../../../src/pages/request_signatures.tsx" /* webpackChunkName: "component---src-pages-request-signatures-tsx" */),
  "component---src-pages-sign-document-tsx": () => import("./../../../src/pages/sign_document.tsx" /* webpackChunkName: "component---src-pages-sign-document-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms_and_conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-wait-tsx": () => import("./../../../src/pages/wait.tsx" /* webpackChunkName: "component---src-pages-wait-tsx" */)
}

