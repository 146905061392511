import { api } from '@/app'
import { DisputeInvite, Dispute, Profile } from '@/types'

const REMOVE_URL = 'disputes/remove_participant/'

export const removeParticipant = async (body: {
  profile: Profile['id']
  dispute: Dispute['id']
}) => {
  const response = await api.patch<DisputeInvite>(REMOVE_URL, body)
  return response.data
}
