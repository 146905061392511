import { assignTextStyle, variantProvider } from '@/app'
import { Text, View } from '@/components'
import { ViewProps } from '@codeleap/web'
import React from 'react'

type ContentCardProps = Omit<ViewProps<'div'>, 'variants' | 'responsiveVariants' | 'content'> & {
  title: string
  content: React.ReactNode
  footer?: React.ReactNode
  contentWrapperStyles?: any
}

export function ContentCard({ title, content, footer, css, style, contentWrapperStyles = {}, ...props }: ContentCardProps) {
  return (
    <View css={[styles.wrapper, css, style]} {...props}>
      <View css={styles.titleWrapper}>
        <Text css={styles.title} text={title} />
      </View>
      <View css={[styles.contentWrapper, contentWrapperStyles]}>{content}</View>
      {footer}
    </View>
  )
}

const MAX_WIDTH = 800

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.medium,
      maxWidth: MAX_WIDTH,
      margin: 'auto',
      ...theme.presets.column,
      ...theme.spacing.gap(4),
      ...theme.spacing.paddingBottom(4),
      backgroundColor: theme.colors.neutral1,
    },
    titleWrapper: {
      borderRadius: '16px 16px 0 0',
      ...theme.spacing.paddingHorizontal(4),
      ...theme.spacing.paddingVertical(2),
      backgroundColor: theme.colors.primary3,
    },
    title: {
      ...assignTextStyle('h2')(theme).text,
      color: theme.colors.neutral1,
      [theme.media.down('small')]: {
        ...assignTextStyle('h3')(theme).text,
        color: theme.colors.neutral1,
      },
    },
    contentWrapper: {
      ...theme.presets.column,
      ...theme.spacing.gap(2),
      ...theme.spacing.paddingHorizontal(4),
    },
  }),
  true,
)
