import React from 'react'
import {
  AppStatusOverlay,
  DebugModal,
  PaywallModal,
  OnboardingModal,
  AuthenticatedScreen,
  Redirect,
  ParticipantInformationModal,
} from '@/components'
import { AppStatus } from '@/redux'
import { onUpdate } from '@codeleap/common'
import { APIClient } from '@/services'
import { graphql, PageProps } from 'gatsby'

export const Overlays = () => (
  <>
    <PaywallModal />
    <DebugModal />
    <AppStatusOverlay />
  </>
)

export const AuthenticatedOverlays = ({ isLoggedIn }) => isLoggedIn ? (
  <>
    <OnboardingModal />
    <ParticipantInformationModal />
  </>
) : <></>

export default (props: PageProps) => {
  const session = APIClient.Session.useSession()
  const { isLoggedIn } = session

  onUpdate(() => {
    AppStatus.setReady(isLoggedIn)
  }, [isLoggedIn])

  return (
    <AuthenticatedScreen>
      <Redirect to={isLoggedIn ? 'Disputes.List' : 'Home'} />
    </AuthenticatedScreen>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
