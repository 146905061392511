import { Settings } from '@/app'
import { View, Text, Link } from '@/components'
import { format } from 'date-fns'
import { StylesOf } from '@codeleap/common'
import { FooterComposition } from '../../../app/stylesheets/Footer'

export type FooterContactProps = {
  variantStyles?: StylesOf<FooterComposition>
}

export const FooterContact = (props: FooterContactProps) => {
  const { variantStyles } = props

  const year = format(new Date(), 'yyyy')

  return (
    <View css={variantStyles.contactWrapper}>
      <Link
        text={Settings.ContactINFO.SupportEMAIL}
        to={`mailto:${Settings.ContactINFO.SupportEMAIL}`}
        css={variantStyles.contactText}
      />
      {/* <Link
        text={Settings.ContactINFO.ContactPHONE}
        to={`tel:${Settings.ContactINFO.ContactPHONE}`}
        css={variantStyles.contactText}
      /> */}
      <Text
        text={`©${year} Hexameron Limited.`}
        css={variantStyles.contactText}
      />
      {/* <Text
        text={'Made with ♥️ in London, UK'}
        css={variantStyles.contactText}
      /> */}
    </View>
  )
}
