import { View, TextInput, Text } from '@/components'

export type DisputePartyFormProps = {
  form: any
}

export const DisputePartyForm = ({ form }) => {
  return (
    <View variants={['column', 'gap:2']}>
      <TextInput {...form.register('full_name')} variants={['noError']} />

      <TextInput {...form.register('email')} variants={['noError']}/>

      <TextInput {...form.register('phone')} variants={['noError']}/>

      <TextInput {...form.register('address')} variants={['noError']}/>

      <Text variants={['p4']} text='*indicates a required field'/>
    </View>
  )
}
