import { AppImages } from '@/app'
import { AuthModal } from './AuthModal'
import { AuthForms } from '@/components'

export const ForgotBeforeModal = () => {

  return (
    <AuthModal
      name='forgotBefore'
      image={AppImages.ResetPassword}
      centered
    >
      <AuthForms.ForgotPasswordBeforeForm />
    </AuthModal>
  )
}
