import { DisputeSettlement, Profile } from '@/types'

function allSigned(settlement: DisputeSettlement): boolean {
  const hasSignatures = !!settlement?.signatures?.length
  return hasSignatures && settlement?.signatures.every(signature => signature.signed)
}

function getUserSign(settlement: DisputeSettlement, userId: Profile['id']) {
  return settlement?.signatures.find(signature => signature.signed_by === userId)
}

export const SettlementUtils = {
  allSigned,
  getUserSign,
}
