const isFieldValid = (form, field: string, minLength?: number) => {
  const hasMinLength = !!minLength
  const noFieldError = !form.fieldErrors[field] && form.values[field]
  if (hasMinLength) {
    const meetsMinLenght = form.values[field]?.length >= minLength
    return noFieldError && meetsMinLenght
  }

  return noFieldError
}

export const FormUtils = {
  isFieldValid,
}
