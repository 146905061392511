import { AppStatus, useAppSelector } from '@/redux'
import { ActionIcon, Button, Modal, Text, View } from '@/components'
import { Settings, variantProvider } from '@/app'
import { ModalProps } from '@codeleap/web'
import { useIsMobile } from '@/utils'
import copy from 'copy-to-clipboard'

export function ContactModal(props: Omit<ModalProps, 'debugName'>) {
  const status = useAppSelector((store) => store.AppStatus.status)
  const isOpen = useAppSelector((store) => store.AppStatus.modals.contact)

  const isMobile = useIsMobile()

  const handleClose = () => {
    props?.toggle?.()
    AppStatus.setModal(['contact', false])
  }

  const copyToClipboard = async () => {
    try {
      copy(Settings.ContactINFO.SupportEMAIL)
      AppStatus.set('done')
      handleClose()
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  return (
    <Modal
      renderHeader={() => (
        <View variants={['justifySpaceBetween', 'fullWidth', 'marginBottom:1']}>
          <Text variants={[isMobile ? 'h2' : 'h1']} text={`Contact support`} />
          {isMobile ? null : (
            <ActionIcon
              name={'x'}
              debugName='Close contact modal button'
              variants={['outline', 'primary3', 'iconSize:3']}
              css={{ border: 'none' }}
              onPress={handleClose}
            />
          )}
        </View>
      )}
      variants={['centered']}
      visible={isOpen && !(status === 'loading' || status === 'done')}
      styles={styles}
      toggle={handleClose}
    >
      <Text
        variants={['color:neutral8']}
        text={
          `For any queries or assistance, you can reach us via e-mail (${Settings.ContactINFO.SupportEMAIL}).`
        }
      />
      <Text
        variants={['color:neutral8']}
        text={'We aim to respond to all messages within 24 hours.'}
      />
      <Button
        variants={['outline', 'marginTop:4']}
        text={'Copy email'}
        debugName={'Copy email button'}
        css={{ height: 48 }}
        onPress={copyToClipboard}
      />
      {isMobile ? (
        <Button
          variants={['marginTop:1']}
          text={'Dismiss'}
          debugName={'Copy email button'}
          css={{ height: 48 }}
          onPress={handleClose}
        />
      ) : null}
    </Modal>
  )
}

const MODAL_WIDTH = 480

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    box: {
      ...theme.spacing.padding(0),
      // @ts-ignore
      borderRadius: theme.borderRadius.medium,
      maxWidth: MODAL_WIDTH,
      ...theme.spacing.padding(4),
      ...theme.spacing.margin(2),
      [theme.media.down('small')]: {
        ...theme.spacing.padding(2),
      },
    },
    body: {
      flex: 1,
    },
  }),
  true,
)
