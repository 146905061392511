import { createForm, yup } from '@codeleap/common'
import { login } from './login'
import { fakeProfile } from '../testers'
import { I18N } from '@/app/i18n'

export const signup = () => {
  const loginForm = login()

  return createForm('signup', {
    email: {
      ...loginForm.config.email,
      defaultValue: fakeProfile.email,
    },
    password: {
      ...loginForm.config.password,
      defaultValue: fakeProfile.password,
      placeholder: I18N.t('form.placeholders.signupPassword'),
      validate: yup
        .string()
        .min(12, 'Password must be at least 12 characters long')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{12,}$/,
          'Password must contain at least one uppercase letter, one lowercase letter, one special character, and be at least 12 characters long',
        )
        .required('Please enter a password'),

    },
    zip_code: {
      type: 'text',
      placeholder: 'e.g. SW1A 1AA',
      label: I18N.t('form.labels.postcode'),
      validate: yup.string().required().max(10),
      transformer: (value) => value.toUpperCase(),
      required: true,
    },
    first_name: {
      type: 'text',
      label: I18N.t('form.labels.firstName'),
      placeholder: I18N.t('form.placeholders.firstName'),
      defaultValue: fakeProfile.first_name,
      validate: yup.string().min(1).max(30),
    },
    last_name: {
      type: 'text',
      label: I18N.t('form.labels.lastName'),
      placeholder: I18N.t('form.placeholders.lastName'),
      defaultValue: fakeProfile.last_name,
      validate: yup.string().min(1).max(30),
    },
    repeatPassword: {
      ...loginForm.config.password,
      label: I18N.t('form.labels.confirmPassword'),
      placeholder: I18N.t('form.placeholders.repeatPassword'),
      defaultValue: fakeProfile.repeatPassword,
      validate: (repeatPassword, { password }) => {
        const isValid = repeatPassword === password
        return {
          valid: isValid,
          message: isValid ? '' : I18N.t('form.validations.passwordsMatch'),
        }
      },
    },
    agree_terms_conditions: {
      type: 'checkbox',
      defaultValue: false,
      validate: yup.boolean().isTrue(),
    },

    agree_mediationx_terms: {
      type: 'checkbox',
      defaultValue: false,
      validate: yup.boolean().isTrue(),
    },
    // TODO TERMS I18N
    /*terms: {
      type: 'checkbox',
      label: 'Accept terms and conditions',
      defaultValue: true,
      validate: (value) => {
        const accpeted = value
        return {
          valid: accpeted,
          message: accpeted ? '' : 'You need to accept our Terms & Conditions to register',
        }
      },
    },*/
    avatar: {
      type: 'file',
    },
    tester: loginForm.config.tester,
  })
}

export const socialSignup = () => signup(true)
