import { AppForms, variantProvider } from '@/app'
import { Modal, RadioInput, TextInput, Button, View, Checkbox, Text } from '@/components'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { Dispute } from '@/types'
import { useForm } from '@codeleap/common'
import { ModalProps } from '@codeleap/web'

export type ParticipantInviteModalProps = ModalProps & {
  dispute: Dispute
}

export const ParticipantInviteModal = ({
  dispute,
  toggle,
  ...rest
}: ParticipantInviteModalProps) => {
  const form = useForm(AppForms.inviteParticipant, {})

  const handleSubmit = () => {
    AppStatus.set('loading')
    toggle()

    APIClient.Disputes.invite({ dispute: dispute?.id, ...form.values })
      .then(() => {
        AppStatus.set('done')
        form.reset()
      })
      .catch(() => {
        AppStatus.set('idle')
      })
  }

  return (
    <Modal
      title='Invite participant'
      toggle={toggle}
      variants={['participant-invite']}
      styles={{ box: styles.modalBox }}
      {...rest}
    >
      <View
        variants={['column', 'marginTop:2']}
      >
        <TextInput {...form.register('full_name')} />
        <TextInput {...form.register('email')} />
        <RadioInput
          variants={['options:vertical']}
          {...form.register('role')}
        />
        <RadioInput
          variants={['options:vertical']}
          {...form.register('party')}
        />

        <View variants={['column', 'gap:1', 'marginBottom:4']}>
          <Text variants={['p2', 'color:neutral7']} text='Confidential Questionnaire' />
          <Checkbox {...form.register('skip_quiz')} variants={['invertLabel']}/>
        </View>

        <Button
          debugName='ParticipantInviteModal:Send'
          text='Send invite'
          variants={['large']}
          disabled={!form.isValid}
          onPress={handleSubmit}
        />
      </View>
    </Modal>
  )
}

const MODAL_WIDTH = 480

const styles = variantProvider.createComponentStyle(theme => ({
  modalBox: {
    maxWidth: MODAL_WIDTH,
  },
}), true)
