import { DisputeQuiz } from '@/types'

type DisputeKey = keyof DisputeQuiz

const fieldsToShow =
  {
    perspective_description:
      'Tell us briefly about the dispute or grievance from your perspective.',
    ideal_solution: 'What would be your ideal solution?',
    urgency_description: 'Is there any urgency?',
    recent_position: 'What is the most recent position in the dispute ?',
    requirements_description:
      'Please tell us a little about you and your requirements, including the most significant parts of the dispute in your opinion.',
    context_description:
      'Please tell us about the context of the dispute or any deeper issues that might be fuelling it.',
    settlements_description:
      'What sort of settlement do you think the other side would accept? What’s significant to them?',
    roadblocks_description: 'Do you foresee any roadblocks?',
    authorised_to_settle:
      'Are you authorised to settle the dispute if settlement is possible?',
    take_lead_description:
      'From your side, would you like to take the lead in resolving the dispute, or somebody else? Please also let us know the names of anyone else who will be present or assisting you in resolving the dispute on MediationX',
    is_pending_lawsuit: 'Is there a pending lawsuit?',
    pending_lawsuit_court: 'Please specify the court',
    pending_lawsuit_case_number: 'Provide the case number',
    strengths_weaknesses_description:
      'Are  there any strengths and weaknesses in this dispute we should be aware of?',
    financial_value:
      'What is the financial value of the dispute in your opinion?',
    spent_so_far:
      'How much have you spent so far in resolving the dispute, if anything',
    suggestions:
      'If there is a particular request or suggestion on how the mediation should be conducted, please let us know',
    additional_info: 'Feel free to tell us any further information',

    file: 'Attachments',
  } || ''

const priceFields = ['financial_value', 'spent_so_far']

const getLabelByKey = (key?: DisputeKey | string) => fieldsToShow[key]

const formatFileName = (quiz: DisputeQuiz) => {
  if (!quiz) return ''
  const { owner, dispute } = quiz
  return `${owner?.first_name}-attachment-${dispute}`
}

export const DisputeQuizUtils = {
  fieldsToShow,
  priceFields,
  getLabelByKey,
  formatFileName,
}
