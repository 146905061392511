import { assignTextStyle, AvatarComposition } from '@codeleap/common'
import { ActionIconComposition, TagComposition } from '@codeleap/web'
import { variantProvider } from '../theme'

export type ParticipantItemComposition =
  | 'wrapper'
  | 'wrapper:selected'
  | 'innerWrapper'
  | 'rightWrapper'
  | `avatar${Capitalize<AvatarComposition>}`
  | 'avatarImage'
  | 'avatarInitials'
  | 'name'
  | `tag${Capitalize<TagComposition>}`
  | 'email'
  | `icon${Capitalize<ActionIconComposition>}`
  | `previewIcon${Capitalize<ActionIconComposition>}`

const createParticipantItemStyle =
  variantProvider.createVariantFactory<ParticipantItemComposition>()

export const ParticipantItemStyles = {
  default: createParticipantItemStyle((theme) => ({
    wrapper: {
      ...theme.presets.fullWidth,
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.paddingHorizontal(2),
      ...theme.spacing.paddingVertical(2),
      [theme.media.down('large')]: {
        ...theme.presets.column,
        ...theme.spacing.gap(0.5),
      },
    },
    'wrapper:selected': {
      backgroundColor: theme.colors.primary2,
    },
    innerWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      ...theme.spacing.gap(2),
    },
    avatarWrapper: {
      height: 32,
      width: 32,
    },
    avatarImage: {
      height: 32,
      width: 32,
    },
    name: {
      ...theme.presets.flex,
    },
    tagWrapper: {
      backgroundColor: theme.colors.neutral2,
    },
    tagText: {
      whiteSpace: 'nowrap',
    },
    email: {
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral7,
    },
    iconTouchableWrapper: {
      backgroundColor: 'transparent',
      ...theme.spacing.padding(0),
    },
    iconIcon: {
      color: theme.colors.primary3,
    },
    previewIconTouchableWrapper: {
      ...theme.presets.hidden,
      backgroundColor: 'transparent',
      ...theme.spacing.padding(0),
    },
    'previewIconTouchableWrapper:disabled': {
      backgroundColor: 'transparent',
    },
    previewIconIcon: {
      color: theme.colors.primary3,
    },
    rightWrapper: {
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      marginLeft: 'auto',
      ...theme.spacing.gap(2),
    },
  })),
  preview: createParticipantItemStyle((theme) => ({
    wrapper: {
      borderBottom: 0,
      ...theme.border.neutral3({
        directions: ['top'],
        width: theme.values.borderWidth.small,
      }),
    },
    tagRole: {
      ...theme.presets.hidden,
    },
    email: {
      ...theme.presets.hidden,
    },
    iconTouchableWrapper: {
      ...theme.presets.hidden,
    },
    previewIconTouchableWrapper: {
      display: 'flex',
    },
  })),
  'border-bottom': createParticipantItemStyle((theme) => ({
    wrapper: {
      ...theme.border.neutral3({
        directions: ['bottom'],
        width: theme.values.borderWidth.small,
      }),
    },
  })),
  'border-radius:bottom': createParticipantItemStyle((theme) => ({
    wrapper: {
      borderRadius: `0 0 ${theme.borderRadius.small}px ${theme.borderRadius.small}px`,
    },
  })),
  'border-radius:top': createParticipantItemStyle((theme) => ({
    wrapper: {
      borderRadius: `${theme.borderRadius.small}px ${theme.borderRadius.small}px 0 0`,
    },
  })),
  'border-radius:small': createParticipantItemStyle((theme) => ({
    wrapper: {
      borderRadius: theme.borderRadius.small,
    },
  })),
  signaturesRequested: createParticipantItemStyle((theme) => ({
    wrapper: {
      [theme.media.down('small')]: {
        ...theme.spacing.padding(0),
      },
    },
    email: {
      width: 250,
      ...theme.presets.textRight,

      [theme.media.down('small')]: {
        ...theme.presets.fullWidth,
        ...theme.presets.textLeft,
      },
    },
  })),
}
