import { ButtonProps, ViewProps } from '@codeleap/web'
import { View, Button, Text } from '@/components'
import { ReactElement, ReactNode } from 'react'
import { PropsOf } from '@codeleap/common'
import { useIsMobile } from '@/utils'
import { variantProvider } from '@/app'

export type FormPageProps = ViewProps<'div'> & {
  title: string
  description?: string
  onBack?: () => void
  onNext: () => void
  onSkip?: () => void
  children?: ReactNode
  scroll?: ViewProps<'div'>['scroll']
  disabled?: ButtonProps['disabled']
  nextButtonProps?: PropsOf<typeof Button>
  backButtonProps?: PropsOf<typeof Button>
  skipButtonProps?: PropsOf<typeof Button>
  jump?: boolean
  hide?: boolean
  canGoBack?: boolean
  footer?: ReactElement
  index: number
}

export const FormPage = ({
  title,
  description,
  children,
  disabled,
  scroll,
  nextButtonProps,
  backButtonProps,
  skipButtonProps,
  footer,
  onBack,
  onNext,
  onSkip,
  jump = false,
  canGoBack = true,
}: FormPageProps) => {
  const isMobile = useIsMobile()

  if (jump) return null
  return (
    <View variants={['fullWidth', 'justifyCenter', 'alignCenter', 'padding:2', 'contentPageHeight']}>
      <View variants={['card']} css={styles.innerWrapper}>
        <Text
          text={title}
          variants={['h1', 'marginBottom:1']}
          responsiveVariants={{
            small: ['h2'],
          }}
        />
        {!!description ? <Text text={description} variants={['p1', 'marginBottom:4']} /> : null}

        {children && (
          <View
            scroll={scroll}
            variants={['column', 'gap:2', !isMobile && 'marginBottom:2']}
            style={scroll && { maxHeight: !isMobile && '30vh' }}
          >
            {children}
          </View>
        )}

        <View
          variants={['fullWidth', 'gap:2', 'marginTop:5']}
          responsiveVariants={{
            small: ['marginTop:2'],
          }}
        >
          {onBack && canGoBack && (
            <Button
              debugName='Back'
              text='Back'
              variants={['flex', 'outline', 'large']}
              onPress={onBack}
              {...backButtonProps}
            />
          )}

          <Button
            debugName='Continue'
            text='Continue'
            variants={['flex', 'large']}
            onPress={onNext}
            disabled={disabled}
            {...nextButtonProps}
          />
        </View>

        {onSkip && (
          <Button
            debugName='Skip'
            text='Skip question'
            variants={['minimal', 'marginTop:2', 'large', 'fullWidth']}
            onPress={onSkip}
            {...skipButtonProps}
          />
        )}

        {footer}
      </View>
    </View>
  )
}

const MAX_WIDTH = 600

const styles = variantProvider.createComponentStyle(
  () => ({
    innerWrapper: {
      maxWidth: MAX_WIDTH,
      width: '100%',
    },
  }),
  true,
)
