import React from 'react'
import { PropsOf } from '@codeleap/common'
import { Image, View, Text } from '@/components'
import { Theme, variantProvider } from '@/app'

type PageProps = {
  title: string
  descriptions: string[]
  image: PropsOf<typeof Image>['source']
}

export const Page = (props: PageProps) => {
  const {
    title,
    descriptions,
    image,
  } = props

  return (
    <View
      variants={['center', 'column', 'textCenter', 'gap:4', 'paddingBottom:4', 'fullHeight']}
      style={styles.wrapper}
    >
      <View style={styles.imageWrapper}>
        <Image source={image} objectFit={'contain'} style={styles.image} />
      </View>
      <View
        variants={['gap:1', 'column', 'center', 'fullWidth', 'flex']}
        style={styles.textWrapper}
      >
        <Text variants={['h2']} text={title} />
        {descriptions.length > 0 ? (
          <View style={styles.descriptionWrapper}>
            {descriptions.map((item, index) => (
              <Text key={`Description: ${index}`} text={item} variants={['p1']} />
            ))}
          </View>
        ) : null}
      </View>
    </View>
  )
}

const IMAGE_HEIGHT = 450
const CONTENT_WIDTH = 576

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    ...theme.presets.fullHeight,
  },
  imageWrapper: {
    background: theme.colors.primary1,
    height: '83%',
    maxHeight: IMAGE_HEIGHT,
    ...theme.presets.fullWidth,
    ...theme.presets.center,
    ...theme.presets.alignEnd,
    [theme.media.down('small')]: {
      height: 320,
    },
  },
  image: {
    maxWidth: 650,
    ...theme.presets.fullWidth,
    maxHeight: IMAGE_HEIGHT,
    [theme.media.down('small')]: {
      maxHeight: 300,
    },
  },
  textWrapper: {
    maxWidth: CONTENT_WIDTH,
    [theme.media.down('small')]: {
      ...theme.spacing.paddingHorizontal(2),
    },
  },
  descriptionWrapper: {
    ...theme.presets.column,
  },
}), true)

