export const terms = `
<p class="p1" id="terms"><strong>TERMS AND CONDITIONS</strong></p>
<p class="p2">Version 1.3</p>
<p class="p2"><strong>Last updated 6 August 2024</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>AGREEMENT TO OUR LEGAL TERMS</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">We are Hexameron Limited, doing business as MediationX ("<strong>Company</strong>," &ldquo;<strong>we</strong>,&rdquo; &ldquo;<strong>us</strong>,&rdquo; or &ldquo;<strong>our</strong>&rdquo;), a company registered in England at 32 Sextons Meadows, Bury St. Edmunds, England, IP33 2SB.<span class="s1"><span class="Apple-converted-space">&nbsp;</span></span></p>
<p class="p3">&nbsp;</p>
<p class="p2">We operate the website www.mediationx.com (the &ldquo;<strong>Site</strong>&rdquo;), as well as any other related products and services that refer or link to these legal terms (the &ldquo;<strong>Legal Terms</strong>&rdquo;). The Site is, inter alia, a platform that connects mediators with parties looking to resolve disputes, concerns, complaints or grievances, and provides them with a digital environment in which to discuss, share and resolve their issues and differences. MediationX does not itself conduct mediations, but instead facilitates, keeps records of and monitors them, and also communicates with users via, inter alia, e-mail (collectively, the &ldquo;<strong>Services</strong>&rdquo;).</p>
<p class="p3">&nbsp;</p>
<p class="p2">You can contact us by e-mail at support@mediationx.com</p>
<p class="p3">&nbsp;</p>
<p class="p2">These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity (&ldquo;<strong>you</strong>&rdquo;), and Hexameron Limited, and concerning your access to and use of the Services.<span class="s2"><span class="Apple-converted-space">&nbsp;</span></span></p>
<p class="p3">&nbsp;</p>
<p class="p2">You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms from time to time. We will alert you about any changes by updating the &ldquo;Last updated&rdquo; date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Legal Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted. <span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">The services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Services.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">We recommend that you print a copy of these Legal Terms for your records.</p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>1. OUR SERVICES</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2">The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>2. INTELLECTUAL PROPERTY RIGHTS</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>Our intellectual property</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2">We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the &ldquo;Content&rdquo;), as well as the trademarks, service marks, and logos contained therein (the &ldquo;Marks&rdquo;).</p>
<p class="p3">&nbsp;</p>
<p class="p2">Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in the United Kingdom and around the world.</p>
<p class="p3">&nbsp;</p>
<p class="p2">The Content and Marks are provided in or through the Services &ldquo;AS IS&rdquo; for you to help resolve disputes only. You must be a party to or connected with such disputes.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>Your use of our Services</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2">Subject to your compliance with these Legal Terms, including the "PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive, non-transferable, revocable license to access:</p>
<p class="p3">&nbsp;</p>
<ul class="ul1">
<li class="li4">access the Services;<span class="Apple-converted-space">&nbsp;</span></li>
<li class="li4">download or print a copy of any portion of the Content to which you have proerly gained access (save that you must not do so, or do any other act, which would or could breach privilege or confidentiality of any mediation),</li>
</ul>
<p class="p5"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p4">solely for your personal or commercial/business purpose in relation to a dispute you are a party to or otherwise connected with. You are not granted any license whatsoever in relation to any commercial or external business purpose or any purpose which may compete with the Company.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any purpose whatsoever, without our express prior written permission.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: legal@mediationx.com. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.</p>
<p class="p3">&nbsp;</p>
<p class="p2">We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.</p>
<p class="p6">&nbsp;</p>
<p class="p2">Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate immediately.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>Your submissions and contributions</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2">Please review this section and the &ldquo;PROHIBITED ACTIVITIES&rdquo; section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any content through the Services.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>Submissions:&nbsp;</strong>By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services ("Submissions"), you agree to assign to us all intellectual property rights in such Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>Contributions:&nbsp;</strong>The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality during which you may create, submit, post, send, display, transmit, publish, distribute, or broadcast content and materials to us or through the Services, including but not limited to text, writings, video, audio, photographs, music, graphics, comments, reviews, rating suggestions, personal information, or other material (&ldquo;Contributions&rdquo;). Any Submission that is publicly posted shall also be treated as a Contribution.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">You understand that Contributions may be viewable by other users of the Services and the Company itself.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>When you post Contributions, you grant us a license (including use of your name, trademarks, and logos):</strong> By posting any Contributions, you grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to: use, copy, reproduce, distribute, sell, resell, publish, broadcast, retitle, store, publicly perform, publicly display, reformat, translate, excerpt (in whole or in part), and exploit your Contributions (including, without limitation, your image, name, and voice) for any purpose, commercial, advertising, or otherwise, to prepare derivative works of, or incorporate into other works, your Contributions, and to sublicense the licenses granted in this section. Our use and distribution may occur in any media formats and through any media channels.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">This license includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide.</p>
<p class="p3">&nbsp;</p>
<p class="p2">Save that any Contribution within a mediation will be treated as confidential, subject to the terms of the MediationX Mediation Agreement. <span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>You are responsible for what you post or upload:&nbsp;</strong>By sending us Submissions and/or posting Contributions through any part of the Services or making Contributions accessible through the Services, you:</p>
<p class="p3">&nbsp;</p>
<ul class="ul1">
<li class="li2">confirm that you have read and agree with our &ldquo;PROHIBITED ACTIVITIES&rdquo; and will not post, send, publish, upload, or transmit through the Services any Submission nor post any Contribution that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading;</li>
<li class="li2">to the extent permissible by applicable law, waive any and all moral rights to any such Submission and/or Contribution;</li>
<li class="li2">warrant that any such Submissions and/or Contributions are original to you or that you have the necessary rights and licenses to submit such Submissions and/or Contributions and that you have full authority to grant us the above-mentioned rights in relation to your Submissions and/or Contributions; and</li>
<li class="li2">warrant and represent that your Submissions and/or Contributions do not constitute confidential information that you are not able to share.</li>
</ul>
<p class="p7"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p8">You are solely responsible for your Submissions and/or Contributions and you expressly agree to reimburse us for any and all losses that we may suffer because of your breach of (a) this section, (b) any third party&rsquo;s intellectual property rights, or (c) applicable law.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>We may remove or edit your Content:&nbsp;</strong>Although we have no obligation to monitor any Contributions, we shall have the right to remove or edit any Contributions at any time without notice if in our reasonable opinion we consider such Contributions harmful or in breach of these Legal Terms. If we remove or edit any such Contributions, we may also suspend or disable your account and report you to the authorities.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>Copyright infringement</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2">We respect the intellectual property rights of others. If you believe that any material available on or through the Services infringes upon any copyright you own or control, please immediately refer to the &ldquo;COPYRIGHT&rdquo; section below.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>3. USER REPRESENTATIONS</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">By using the Services, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal capacity and you agree to comply with these Legal Terms; (4) you are not under the age of 18;<span class="s1">&nbsp;</span>(5) you are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use the Services; (6) you will not access the Services through automated or non-human means, whether through a bot, script, or otherwise; (7) you will not use the Services for any illegal or unauthorized purpose; (8) you have not previously been barred by the Company; (9) if you are asked by the Company to provide proof of any registration information you agree to do so within 7 working days; and (10) your use of the Services will not violate any applicable law or regulation.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">If you provide any information that is untrue, inaccurate, not current, or incomplete, or fail to provide any information or proof we require, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2"><strong>4. USER REGISTRATION</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">You may be required to register to use some or all of the Services. You agree to keep your password confidential and will be responsible for any and all use of your account and password by you or any third-party. You must set a password that is very strong and not easily guessable.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">You agree to keep any one time passwords that you receive confidential.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">We reserve the right to remove, reclaim, or change an an account if we determine, in our sole discretion, that such use is inappropriate, obscene, or otherwise objectionable.</p>
<p class="p3">&nbsp;</p>
<p class="p2">You must only create and use one account, and your account is neither transferable nor assignable.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2"><strong>5. MEDIATIONS<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p9">&nbsp;</p>
<ul class="ul1">
<li class="li2">The Services enable you, on payment being satisfied, to be allocated an Independent Registered Mediator.<span class="Apple-converted-space">&nbsp;</span></li>
</ul>
<p class="p9">&nbsp;</p>
<ul class="ul1">
<li class="li2">All Independent Registered Mediators are either
<ul class="ul1">
<li class="li2">persons who are not in any way employees or workers or agents (of any kind) or partners of the Company. No acts or omissions or literature of the Company are to be deemed to indicate such a relationship.<span class="Apple-converted-space">&nbsp;</span></li>
<li class="li2">Or directors of the Company who, when acting as an Independent Registered Mediator, are doing so in their capacity as a legal professional.<span class="Apple-converted-space">&nbsp;</span></li>
</ul>
</li>
</ul>
<p class="p9">&nbsp;</p>
<ul class="ul1">
<li class="li2">On inputting data about a dispute on the Company&rsquo;s website, you are making an offer to receive mediation services and use our Services as per the Legal Terms.</li>
</ul>
<p class="p9">&nbsp;</p>
<ul class="ul1">
<li class="li2">On receipt of your offer, the Company may accept it (for the avoidance of doubt, there is no obligation on the Company to accept it and the Company will not accept it if doing so would be contrary to law, for example due to the subject matter of the dispute) by providing you a quotation/invoice, on terms that our acceptance is subject to you making the necessary payment as per the price set out in the quotation/invoice. If the requisite payment is made, we will make one or more Independent Registered Mediators aware of this fact. They may choose to accept, bearing in mind, inter alia, their availability, skills, expertise and knowledge.</li>
<li class="li2">If no Independent Registered Mediators accept within 3 working days, we will issue you a full refund.<span class="Apple-converted-space">&nbsp;</span></li>
</ul>
<p class="p9">&nbsp;</p>
<ul class="ul1">
<li class="li2">If an Independent Registered Mediator does accept but for any reason has to be replaced (with such replacement happening at the sole discretion of the Company), the Company will make one or more Independent Registered Mediators aware of this fact. They may choose to accept, bearing in mind, inter alia, their availability, skills, expertise and knowledge. However, if no replacement Independent Registered Mediators accept within 3 working days, we will issue you a full refund.<span class="Apple-converted-space">&nbsp;</span></li>
</ul>
<p class="p9">&nbsp;</p>
<ul class="ul1">
<li class="li2">All our mediations take place subject to the MediationX Mediation Agreement, which is deemed incorporated herein. The prices mentioned in our invoices are a fixed fee, subject to a Fair Use of Mediators&rsquo; Time policy.</li>
</ul>
<p class="p9">&nbsp;</p>
<ul class="ul1">
<li class="li2"><span class="s3">Fair Use of Mediators&rsquo; Time Policy</span>
<ul class="ul1">
<li class="li2">Where fees paid are up to a total of &pound;50, 2 hours</li>
<li class="li2">Where fees paid are up to a total of &pound;100, 2.5 hours</li>
<li class="li2">Where fees paid are up to a total of &pound;500, 4 hours</li>
<li class="li2">Where fees paid are up to a total of &pound;1000, 6 hours</li>
<li class="li2">Where fees paid are up to a total of &pound;3000, 10 hours</li>
<li class="li2">Where fees paid are up to a total of &pound;5000, 18 hours<span class="Apple-converted-space">&nbsp;</span></li>
</ul>
</li>
</ul>
<p class="p9">&nbsp;</p>
<ul class="ul1">
<li class="li2">If the fair use policy is exceeded, the Independent Registered Mediator may request additional fees in order to continue.<span class="Apple-converted-space">&nbsp;</span></li>
</ul>
<p class="p9">&nbsp;</p>
<ul class="ul1">
<li class="li2">We do not guarantee that mediations will result in settlements. Furthermore, all mediations are subject to availability, and we cannot guarantee that Independent Registered Mediators will be available to mediate a dispute. We reserve the right to discontinue mediations at any time for any reason.</li>
</ul>
<p class="p9">&nbsp;</p>
<ul class="ul1">
<li class="li2">All prices for mediations and our Services are subject to change.</li>
</ul>
<p class="p3">&nbsp;</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>6. PAYMENT<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">You may have to pay the Company for some or all of the Services or for a service provided to you or to be provided to you by us or our Independent Registered Mediators. If this is the case, you will first be provided with a quotation/invoice for each instance a payment is required. The quotation/invoice will be comprehensive and include fees payable to the Company and/or Independent Registered Mediators.</p>
<p class="p3">&nbsp;</p>
<p class="p2">Payment will be due on your acceptance of the quotation/invoice.</p>
<p class="p3">&nbsp;</p>
<p class="p2">On acceptance, you will owe fees to the Independent Registered Mediator and/or to us. We shall, as agent or otherwise for any Independent Registered Mediator, pay to such Independent Registered Mediator fees as contractually may be due to them. We reserve the right to charge Independent Registered Mediators a commission, charge or referral fee.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">You will be deemed to have accepted the quotation by signifying your assent to us or by making payment to us pursuant to receiving the quotation.</p>
<p class="p3">&nbsp;</p>
<p class="p2">We accept the following forms of payment:</p>
<p class="p3">&nbsp;</p>
<p class="p10">-<span class="Apple-converted-space">&nbsp;&nbsp;</span>Bank Transfer</p>
<p class="p10">-<span class="Apple-converted-space">&nbsp;&nbsp;</span>Credit/Debit Cards</p>
<p class="p3">&nbsp;</p>
<p class="p2">You agree to provide current, complete, and accurate purchase and account information for all payments made via or in connection with the Services. You further agree to promptly update account and payment information, including email address, payment method, and payment card expiration date, so that we can complete your transactions and contact you as needed. VAT will be added to the price as deemed required by us. We may change prices at any time. All payments shall be in GBP (Pound Sterling). <span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">You agree to pay all charges at the prices then in effect for your purchases, and you authorise us to charge your chosen payment method for any such amounts. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel numbers of mediations purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same payment method, and/or orders that use the same billing or shipping address.</p>
<p class="p3">&nbsp;</p>
<p class="p2">We may from time to time have promotional offers.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">Payment will also be satisfied if paid by the other party, at that time or in advance.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>7. REFUNDS POLICY<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">All sales are final and no refund will be issued, save at the Company&rsquo;s sole discretion.</p>
<p class="p3">&nbsp;</p>
<p class="p2">Save that, if an allocated Independent Registered Mediator is unsuccessful in inviting all responding parties to join the mediation, a full refund of the mediation fee will be issued to the commencing party after deduction of a &pound;15 administrative fee.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp;</span></p>
<p class="p2"><strong>8. ACCOUNT DELETION<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">You can delete your account at any time by logging into your account.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">If you are unsatisfied with our Services, or wish to make any complaints, please e-mail us at support@mediationx.com</p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>9. SOFTWARE</strong></p>
<p class="p6">&nbsp;</p>
<p class="p2">We may include software for use in connection with our Services. If such software is accompanied by an end user license agreement (&ldquo;EULA&rdquo;), the terms of the EULA will also govern your use of the software. If such software is not accompanied by a EULA, then we grant to you a non-exclusive, revocable, personal, and non-transferable license to use such software solely in connection with our services and in accordance with these Legal Terms. Any software and any related documentation is provided &ldquo;AS IS&rdquo; without warranty of any kind, either express or implied, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. You accept any and all risk arising out of use or performance of any software. You may not reproduce or redistribute any software except in accordance with the EULA or these Legal Terms.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>10. PROHIBITED ACTIVITIES</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavors.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">As a user of the Services, you agree not to:</p>
<p class="p3">&nbsp;</p>
<ul class="ul1">
<li class="li2">Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without express written permission from us.</li>
<li class="li2">Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</li>
<li class="li2">Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein.</li>
<li class="li2">Engage in unauthorized framing of or linking to the Services.</li>
<li class="li2">Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords or other sensitive or confidential information.</li>
<li class="li2">Make improper use of our support services or submit false reports of abuse or misconduct.</li>
<li class="li2">Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
<li class="li2">Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.</li>
<li class="li2">Attempt to impersonate another user or person or use the username of another user.</li>
<li class="li2">Use any information obtained from the Services in order to harass, abuse, or harm another person.</li>
<li class="li2">Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavor or commercial enterprise.</li>
<li class="li2">Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services.</li>
<li class="li2">Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.</li>
<li class="li2">Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.</li>
<li class="li2">Delete the copyright or other proprietary rights notice from any Content.</li>
<li class="li2">Copy or adapt the Services&rsquo; software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
<li class="li2">Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party&rsquo;s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.</li>
<li class="li2">Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (&ldquo;gifs&rdquo;), 1&times;1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as &ldquo;spyware&rdquo; or &ldquo;passive collection mechanisms&rdquo; or &ldquo;pcms&rdquo;).</li>
<li class="li2">Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or using or launching any unauthorized script or other software.</li>
<li class="li2">Access the Services from a device that is not up to date in software <span class="Apple-converted-space">&nbsp;</span></li>
<li class="li2">Access the Services from a device that does not contain up to date and running anti-virus software and measures</li>
<li class="li2">Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
<li class="li2">Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
</ul>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>11. USER GENERATED CONTRIBUTIONS</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp;&nbsp;</span></p>
<p class="p2">The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Services, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions"). Contributions may be viewable by other users of the Services. As such, any Contributions you transmit may be treated as non-confidential and non-proprietary, save that Contributions within a mediation will be treated as confidential subject to the MediationX Mediation Agreement. When you create or make available any Contributions, you thereby represent and warrant that:</p>
<p class="p3">&nbsp;</p>
<ul class="ul1">
<li class="li2">The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.</li>
<li class="li2">You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Services, and other users of the Services to use your Contributions in any manner contemplated by the Services and these Legal Terms.</li>
<li class="li2">You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Services and these Legal Terms.</li>
<li class="li2">Your Contributions are not false, inaccurate, or misleading.</li>
<li class="li2">Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.</li>
<li class="li2">Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).</li>
<li class="li2">Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
<li class="li2">Your Contributions do not advocate the violent overthrow of any government or incite, encourage, or threaten physical harm against another.</li>
<li class="li2">Your Contributions do not violate any applicable law, regulation, or rule.</li>
<li class="li2">Your Contributions do not violate the privacy or publicity rights of any third party.</li>
<li class="li2">Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.</li>
<li class="li2">Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.</li>
<li class="li2">Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal Terms, or any applicable law or regulation.</li>
</ul>
<p class="p3"><span class="Apple-converted-space">&nbsp;&nbsp;</span></p>
<p class="p2">Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other things, termination or suspension of your rights to use the Services.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
<p class="p2"><strong>12. CONTRIBUTION LICENSE</strong></p>
<p class="p11"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">Save that everything said and done in a mediation will be confidential in a mediation, subject to the MediationX Mediation Agreement, by posting your Contributions to any part of the Services, you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions (including, without limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of, or incorporate into other works, such Contributions, and grant and authorize sublicenses of the foregoing. The use and distribution may occur in any media formats and through any media channels.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">This license will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in your Contributions, and you warrant that moral rights have not otherwise been asserted in your Contributions.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the Services. You are solely responsible for your Contributions to the Services and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions; (2) to re-categorize any Contributions to place them in more appropriate locations on the Services; and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We have no obligation to monitor your Contributions.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
<p class="p2"><strong>13. GUIDELINES FOR REVIEWS</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">We may provide you areas on the Services to leave reviews or ratings. When posting a review, you must comply with the following criteria: (1) you should have firsthand experience with the person/entity being reviewed; (2) your reviews should not contain offensive profanity, or abusive, racist, offensive, or hateful language; (3) your reviews should not contain discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation, or disability; (4) your reviews should not contain references to illegal activity; (5) you should not be affiliated with competitors if posting negative reviews; (6) you should not make any conclusions as to the legality of conduct; (7) you may not post any false or misleading statements; and (8) you may not organize a campaign encouraging others to post reviews, whether positive or negative.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp;&nbsp;</span></p>
<p class="p2">We may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation to screen reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily represent our opinions or the views of any of our affiliates or partners. We do not assume liability for any review or for any claims, liabilities, or losses resulting from any review. By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully paid, assignable, and sublicensable right and license to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content relating to reviews.</p>
<p class="p3">&nbsp;</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp;</span></p>
<p class="p2"><strong>14. THIRD-PARTY WEBSITES AND CONTENT</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">The Services may contain (or you may be sent by us or via the Site,) links to other websites ("Third-Party Websites") as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties ("Third-Party Content"). Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, security or completeness by us, and we are not responsible for any Third- Party Websites accessed through the Services or through any e-mail sent by us or any Third-Party Content posted on, available through, viewed on or installed from the Services, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, security or other policies of or contained in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or viewing or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">If you decide to leave the Services and/or access the Third-Party Websites or use or view or install any Third-Party Content, you do so at your own risk.</p>
<p class="p3">&nbsp;</p>
<p class="p2">You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from any e-mail from us or the Services or relating to any applications you use or install from the Services.</p>
<p class="p3">&nbsp;</p>
<p class="p2">Any purchases or transactions you make through Third-Party Websites may be through other websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable third party.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us blameless from any harm caused by your purchase of such products or services.</p>
<p class="p3">&nbsp;</p>
<p class="p2">Additionally, you shall hold us blameless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
<p class="p2"><strong>15. ADVERTISERS</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">We may allow advertisers to display their advertisements and other information in certain areas of the Services, such as sidebar advertisements or banner advertisements. We simply provide the space to place such advertisements, and we have no other relationship with advertisers<span class="s2">.</span></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp;</span></p>
<p class="p2"><strong>16. SERVICES MANAGEMENT</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.</p>
<p class="p11"><strong><span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p2"><strong>17. PRIVACY POLICY</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp;&nbsp;</span></p>
<p class="p2">We care about data privacy and security. By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please be advised the Services are hosted in the UK. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the UK, then through your continued use of the Services, you are transferring your data to the UK, and you expressly consent to have your data transferred to and processed in the UK.</p>
<p class="p3">&nbsp;</p>
<p class="p2">Further, you consent to your data being transferred to and/or processed in other jurisdictions where Independent Registered Mediators may be accessing the Services from. <span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">Further, you consent to your data being transferred to and/or processed in other jurisdictions where third-party AI technologies may be accessing the Services from. <span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">Further, you consent to your data being transferred to and/or processed in other jurisdictions where the Services are developed and/or maintained by the Company and/or its contractors. <span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">Further, we do not knowingly accept, request, or solicit information from children or knowingly market to children.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">We may collect personal information about you including, but not limited to, IP addresses, phone numbers, addresses, post codes, device identifiers, wireless identifiers, geographic identifiers etc. By using the Services you agree to have no objection to us collecting this data either from you or from third parties including wireless and broadband carriers.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
<p class="p2"><strong>18. Copyright</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;&nbsp;</span><strong><span class="Apple-converted-space">&nbsp;</span></strong> <span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>Notifications</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">We respect the intellectual property rights of others. If you believe that any material available on or through the Services infringes upon any copyright you own or control, please immediately notify us at <span class="s4">legal@mediationx.com</span> (a &ldquo;Notification&rdquo;). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification. Please be advised that you may be held liable for damages if you make material misrepresentations in a Notification.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">All Notifications should include the following information: (1) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; (2) identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works on the Services are covered by the Notification, a representative list of such works on the Services; (3) identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material; (4) information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number, and, if available, an email address at which the complaining party may be contacted; (5) a statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and (6) a statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed upon.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2"><strong>Counter Notification</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">If you believe your own copyrighted material has been removed from the Services as a result of a mistake or misidentification, you may submit a written counter notification to us via legal@mediationx.com (a &ldquo;Counter Notification&rdquo;). To be an effective Counter Notification, your Counter Notification must include substantially the following: (1) identification of the material that has been removed or disabled and the location at which the material appeared before it was removed or disabled; (2) a statement that you will accept service of process from the party that filed the Notification or the party's agent; (3) your name, address, and telephone number; (4) a statement under penalty of perjury that you have a good faith belief that the material in question was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled; and (5) your physical or electronic signature.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">If you send us a valid, written Counter Notification meeting the requirements described above, we will restore your removed or disabled material, unless we first receive notice from the party filing the Notification informing us that such party has filed a court action to restrain you from engaging in infringing activity related to the material in question. Please note that if you materially misrepresent that the disabled or removed content was removed by mistake or misidentification, you may be liable for damages.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>19. TERM AND TERMINATION</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">These Legal Terms shall remain in full force and effect in perpetuity.</p>
<p class="p3">&nbsp;</p>
<p class="p2">WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>20. MODIFICATIONS AND INTERRUPTIONS</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services.<span class="s1">&nbsp;</span>We also reserve the right to modify or discontinue all or part of the Services without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to supply any corrections, updates, or releases in connection therewith.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>21. GOVERNING LAW</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">These Legal Terms and your use of the Services are governed by and construed in accordance with the laws of England and Wales applicable to agreements made and to be entirely performed within England and Wales, without regard to its conflict of law principles.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>22. DISPUTE RESOLUTION</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp;</span></p>
<p class="p2"><strong>Informal Negotiations</strong></p>
<p class="p4">To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms (each a "Claim" and collectively, the &ldquo;Claims&rdquo;) brought by either you or us (individually, a &ldquo;Party&rdquo; and collectively, the &ldquo;Parties&rdquo;), the Parties agree to first attempt to negotiate any Claim (except those Claims expressly provided below) informally for at least 45 days before initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other Party.</p>
<p class="p6">&nbsp;</p>
<p class="p4"><strong>Binding Arbitration</strong></p>
<p class="p6">&nbsp;</p>
<p class="p2">If the Parties are unable to resolve a Claim through informal negotiations, the Claim (except those Claims expressly excluded below) will be finally and exclusively resolved through binding arbitration.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">The arbitration shall be commenced and conducted under the such rules as the Chartered Institute of Arbitrators deem fit and proper in the circumstances. The number of arbitrators shall be 1 and shall be chosen by the Chartered Institute of Arbitrators. Each party to a Claim shall be responsible for an equal share of arbitration fees.</p>
<p class="p3">&nbsp;</p>
<p class="p2">The arbitration may be conducted in person, through the submission of documents, by phone, or online. The arbitrator will make a decision in writing, but need not provide a statement of reasons unless requested by either Party. The arbitrator must follow applicable law, and any award may be challenged if the arbitrator fails to do so.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">The arbitration will take place in London, England. Except as otherwise provided herein, the Parties may litigate in court to compel arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.</p>
<p class="p6"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">If for any reason, a Claim proceeds in court rather than arbitration, the Claim shall be commenced in the courts located in London, England, and you hereby consent to, and waive all defenses of lack of personal jurisdiction, and forum non conveniens with respect to venue and jurisdiction in such courts. Application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) is excluded from these Legal Terms.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2"><strong>Restrictions</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">The Parties agree that any arbitration shall be limited to the Claim between the Parties. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Claim to be arbitrated on a class-action (or group-action) basis or to utilize class-action (or group-action) procedures; and (c) there is no right or authority for any Claim to be brought in a purported representative capacity on behalf of the general public or any other persons.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2"><strong>Exceptions to Informal Negotiations and Arbitration</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2">The Parties agree that the following Claims are subject to the above provisions concerning informal negotiations and binding arbitration only at the discretion of the Company: (a) any Claims by the Company seeking to enforce or protect, or confirming the validity of, any of the intellectual property rights of the Company; (b) any Claim by the Company related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim by the Company for injunctive relief.</p>
<p class="p3">&nbsp;</p>
<p class="p2">If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Claim falling within that portion of this provision found to be illegal or unenforceable and such Claim shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and you agree to submit to the jurisdiction of that court.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span></p>
<p class="p2"><strong>23. CORRECTIONS</strong><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>24. DISCLAIMER</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES&rsquo; CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, ANY THIRD PARTY CONTENT,,<span class="Apple-converted-space">&nbsp;&nbsp;</span>OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING.</p>
<p class="p3">&nbsp;</p>
<p class="p2">WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES (SAVE AS MAY BE SET OUT IN THE MEDIATIONX MEDIATION AGREEMENT).</p>
<p class="p3">&nbsp;</p>
<p class="p2"><span class="Apple-converted-space">&nbsp;</span>AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>25. LIMITATIONS OF LIABILITY</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">The Company does not limit its liability contrary to law. Subject to that:</p>
<p class="p3">&nbsp;</p>
<p class="p2">IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, BUSINESS LOSSES OR INTERRUPTIONS, LOST REVENUE, LOST OPPORTUNITIES, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR EVEN IF SUCH LOSSES OR DAMAGES COULD REASONABLY BE EXPECTED TO FLOW FROM A TORTIOUS/NEGLIGENT ACT OR BREACH OF CONTRACT.</p>
<p class="p3">&nbsp;</p>
<p class="p2">NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED, TO THE EXTENT PERMITTED BY LAW, TO THE LESSER OF:</p>
<p class="p2"><span class="Apple-converted-space">&nbsp;</span>THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE 6 MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR</p>
<p class="p2"><span class="Apple-converted-space">&nbsp;</span>THE VALUE OF THE DISPUTE WHICH WAS THE SUBJECT MATTER OF THE MEDIATION (IF ANY, AS DISCLOSED AT THE TIME THE DISPUTE WAS FIRST BROUGHT TO THE ATTENTION OF THE COMPANY) OR</p>
<p class="p2"><span class="Apple-converted-space">&nbsp;</span>&pound;1000 IN TOTAL.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">Furthermore, all Independent Registered Mediators are legal professionals who are required to have professional indemnity insurance, and the Company will in any event bear no liability for matters subject to such insurance until an aggrieved party has pursued a claim against the Independent Registered Mediator(s) (and their insurers).<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;&nbsp;</span></p>
<p class="p2"><strong>26. INDEMNIFICATION</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys&rsquo; fees and expenses, made by any third party due to or arising out of: (1) your Contributions; (2) use of the Services; (3) breach of these Legal Terms; (4) any breach of your representations and warranties set forth in these Legal Terms; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;&nbsp;</span></p>
<p class="p2"><strong>27. USER DATA</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;&nbsp;</span></p>
<p class="p2"><strong>28. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">Visiting and using the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive, directly or indirectly from the Company, electronic communications (including WhatsApp messages, SMS messages, e-mails), and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>29. MISCELLANEOUS</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment, or agency relationship created between you and us as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>29. MEDIATIONX MEDIATION AGREEMENT</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2">The MediationX Mediation Agreement is incorporated into these Legal Terms</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2"><strong>30. CONTACT US</strong></p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at support@mediationx.com:<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p9">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p1" id="agreement"><strong>MEDIATIONX MEDIATION AGREEMENT</strong></p>
<p class="p2"><strong>Version 1.1</strong></p>
<p class="p2"><strong>Last updated 29 July 2024</strong></p>
<p class="p12">&nbsp;</p>
<p class="p2">We are Hexameron Limited, doing business as MediationX ("<strong>Company</strong>," &ldquo;<strong>we</strong>,&rdquo; &ldquo;<strong>us</strong>,&rdquo; or &ldquo;<strong>our</strong>&rdquo;), a company registered in England at 32 Sextons Meadows, Bury St. Edmunds, England, IP33 2SB.<span class="s1"><span class="Apple-converted-space">&nbsp;</span></span></p>
<p class="p3">&nbsp;</p>
<p class="p2">We operate the website www.mediationx.com (the &ldquo;<strong>Site</strong>&rdquo;), as well as any other related products and services that refer or link to these legal terms (the &ldquo;<strong>Legal Terms</strong>&rdquo;).The Site is, inter alia, a platform that connects independent mediators with parties looking to resolve disputes, concerns, complaints or grievances, and provides them with a digital environment in which to discuss, share and resolve their issues and differences. MediationX does not itself conduct mediations, but instead facilitates, keeps records of and monitors them (collectively, the &ldquo;<strong>Services</strong>&rdquo;).</p>
<p class="p3">&nbsp;</p>
<p class="p2">You can contact us by e-mail at support@mediationx.com</p>
<p class="p3">&nbsp;</p>
<p class="p2">WHEREAS</p>
<p class="p3">&nbsp;</p>
<p class="p2">Persons may seek or receive or potentially receive mediation services, or otherwise participate in a mediation on or through the Site, whether personally or on behalf of any person or entity.</p>
<p class="p3">&nbsp;</p>
<p class="p2">On the initiation of any such mediation, with initiation occurring on allocation of a case number by us, the aforementioned persons are designated as PARTICIPANTS in that particular mediation.</p>
<p class="p3">&nbsp;</p>
<p class="p2">On such occurrence, these Legal Terms constitute a legally binding agreement made between the aforementioned participants (&ldquo;the Participants&rdquo;) and the Company.</p>
<p class="p3">&nbsp;</p>
<p class="p2">&nbsp; &nbsp; &nbsp; &nbsp; For the avoidance of doubt, each Participant is also bound to each other Participants as per the terms and conditions contained herein.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">FURTHERMORE</p>
<p class="p3">&nbsp;</p>
<p class="p2">If and when the Company appoints in writing (or through software means) any person to act as an Independent Registered Mediator in relation to such mediation, and that person accepts such appointment, that person (&ldquo;the Mediator&rdquo;)<span class="Apple-converted-space">&nbsp;&nbsp;</span>is also bound by these Legal Terms.</p>
<p class="p12">&nbsp;</p>
<p class="p2">All Participants and the Mediator agree that by accessing the Services, they have read, understood, and agreed to be bound by all of these Legal Terms.</p>
<p class="p3">&nbsp;</p>
<p class="p2">IF ANY PARTICIPANT OR MEDIATOR DOES NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN THEY ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND MUST DISCONTINUE USE IMMEDIATELY.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms from time to time. We will alert you about any changes by updating the &ldquo;Last updated&rdquo; date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is the responsibility of each Participant and Mediator to periodically review these Legal Terms to stay informed of updates. Each Participant and Mediator will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted. <span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">The services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Services.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">We recommend that you print a copy of these Legal Terms for your records.</p>
<p class="p12">&nbsp;</p>
<ul class="ul1">
<li class="li13"><strong>Appointment of Mediator</strong>
<ul class="ul1">
<li class="li2">We confirm that the mediator in each case shall be a person registered with us as a Mediator, and be bound by and on such terms as we have seen fit.</li>
<li class="li2">The Company may appoint more than one Mediator in each case.</li>
<li class="li2">By accepting an appointment, a Mediator declares that they are independent of each of the Participants and the subject matter of the dispute and further declares that they are qualified and available to serve as mediator in the matter they are appointed in respect of.<strong>&nbsp;<span class="Apple-converted-space">&nbsp;</span></strong></li>
</ul>
</li>
<li class="li14"><strong>The Mediation</strong></li>
</ul>
<p class="p15">2.1<span class="Apple-converted-space">&nbsp;&nbsp;</span>The Parties agree to attempt in good faith to settle their dispute while mediating using the MediationX platform. <span class="Apple-converted-space">&nbsp;</span></p>
<p class="p15">2.2<span class="Apple-converted-space">&nbsp;&nbsp;</span>Every Mediator agrees to conduct, and the Participants agree to participate in the Mediation, in accordance with these Legal Terms and pursuant to the MEDIATIONX<strong>&nbsp;</strong>Mediation Rules.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p16"><strong>3. Mediation Framework.<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p17">3.1<span class="Apple-converted-space">&nbsp;&nbsp;</span>The Mediation will take place remotely via the MEDIATIONX virtual platform</p>
<p class="p17">3.2<span class="Apple-converted-space">&nbsp;&nbsp;</span>The Participants agree that for the purposes of the Mediation the Mediator may also utilise third party technology such as telephony, Zoom or WhatsApp.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p17">3.3 The Participants also agree that the Mediator may be assisted by AI technologies provided by third parties, as facilitated by the Company.</p>
<p class="p16"><strong>4. Authority and status<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p16">4.1 Each Participant warrants having authority to bind themselves and all other persons taking part on that Participant&rsquo;s behalf at the Mediation, or any part thereof, to observe the terms of these Legal Terms, including Schedule A, and also having full authority to bind that Participant to the terms of any settlement.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p16">4.2 No Mediator shall be liable to the Parties for any act or omission arising from or in relation to the Mediation, unless the act or omission is proved to have been fraudulent or involved wilful misconduct.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p16">4.3 The Participants understand that the Mediator does not give legal advice and agree that they will not make any claim whatsoever whether in tort or in contract against the Mediator or the Company arising from or in relation to the Mediation.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p16"><strong>5. Confidentiality and without prejudice status<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p16">5.1 Each Participant (which term includes without limitation workers, employees, agents, consultants, experts and legal representatives of a Participant ) involved in the Mediation:<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p17">5.1.1<span class="Apple-converted-space">&nbsp;&nbsp;</span>will keep confidential all information arising out of or in connection with the Mediation, including the terms of any settlement, unless otherwise agreed by the Participants in writing, but not including the fact that the Mediation is to take place or has taken place or where disclosure is required by law, or to implement or to enforce terms of settlement or to notify their insurers, insurance brokers and/or accountants and attest to that fact in the form of Schedule &ldquo;A&rdquo; hereto (such attestation is deemed to have been made by acceptance of these Legal Terms and use of www.mediationx.com);<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p17">5.1.2<span class="Apple-converted-space">&nbsp;&nbsp;</span>acknowledges that all such information passing between the Participants and the Mediator however communicated, is agreed to be without prejudice to any Participant&rsquo;s legal position and may not be produced as evidence or disclosed to any judge, arbitrator or other decision-maker in any legal or other formal process, except where otherwise disclosable in law;<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p17">5.1.3<span class="Apple-converted-space">&nbsp;&nbsp;</span>will not make any attempt to or make any audio or video recording or screen capture of any part of the Mediation;<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p17">5.1.4<span class="Apple-converted-space">&nbsp;&nbsp;</span>will ensure that only persons notified in writing to the Independent Registered Mediator, prior to the commencement of any part of the Mediation, may be present in the room where a Participant&rsquo;s computer is located during any audio and/or video Mediation session, and at all other times when the MediationX platform is being used, Participants will ensure that nobody can see their screen and will take all necessary steps in that regard;<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p17">5.1.5<span class="Apple-converted-space">&nbsp;&nbsp;</span>where it privately discloses to the Mediator any information in confidence before, during or after the Mediation, the Mediator will not disclose that information to any other Participant or person without the consent of the Participant disclosing it. The Mediator may also make disclosure to a Court of relevant jurisdiction where compelled to do so by judicial act of that Court, but will give the affected Participant written notice of any such action by the Court;<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p17">5.1.6<span class="Apple-converted-space">&nbsp;&nbsp;</span>will not make an application to call the Mediator or the Company as a witness, nor require them to produce in evidence any records or notes, or other media of any kind relating to the Mediation, in any litigation, arbitration or other formal process arising out of or in connection with their dispute and the Mediation; nor will the Mediator nor the Company agree to act as a witness, expert, arbitrator or consultant in any such process; and<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p16">5.1.7 where it does make such an application (as listed above), that Participant will fully indemnify the Mediator and the Company in respect of any costs any of them incur in resisting and/or responding to such an application, including reimbursement at the Mediator&rsquo;s standard hourly rate for the Mediator&rsquo;s time spent in resisting and/or responding to such an application.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p16">6. <strong>Settlement formalities.<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p17">6.1<span class="Apple-converted-space">&nbsp;&nbsp;</span>All offers made in the course of or for the purpose of the Mediation are without prejudice and confidential and shall be inadmissable in any litigation or arbitration.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p17">6.2<span class="Apple-converted-space">&nbsp;&nbsp;</span>No terms of settlement reached at the Mediation will be legally binding until set out in writing in a Settlement Agreement and signed (electronically or otherwise) by or on behalf of each of the Participants.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p17">6.3 In disputes valued at less than &pound;1500, the Mediator may on request of the parties provide a suggested draft settlement agreement for signing. The Company takes no responsibility or liability for such documents or its contents.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p17">6.4 Participants must only sign (electronically or otherwise) a settlement agreement if they fully understand it, and may wish to seek independent legal advice from a third party such as an independent legal advisor prior to signing.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p16"><strong>7. Fees and costs of the Mediation.<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p17">7.1<span class="Apple-converted-space">&nbsp;&nbsp;</span>The fees of the mediation shall be as paid for by the Participants in advance of commencement of the mediation, except if paragraph 7.2 below applies.</p>
<p class="p17">7.2 If there exists a written agreement between the Company and a Participant that all mediation fees in respect of disputes involving that Participant shall be paid solely by that Participant, any other Participant involved in that dispute will not be required to pay any fees.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p16"><strong>8. Legal Status and effect of the Mediation<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p16">8.1. This Agreement is governed by the laws of England and Wales<strong>&nbsp;</strong>and exclusive jurisdiction to decide any matters arising out of or in connection with this Agreement and the Mediation shall lie with a sole arbitrator. Prior to arbitration being commenced parties must attempt to resolve their differences by negotiation, followed by mediation. <span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p18"><strong>SCHEDULE A DECLARATION OF ATTENDEES/PARTICIPANTS<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p16">I agree with the Company and each of the attendees/Participants at the mediation, including the Mediator, that in consideration of my being present/participating at this mediation, I will not disclose to any person not expressly authorised by all parties to these Legal Terms to receive such information, anything which I have heard or read or seen in the course of this mediation unless obliged by law to do so.</p>
<p class="p12">&nbsp;</p>
<p class="p2"><strong>MEDIATIONX MEDIATION RULES</strong></p>
<p class="p3">&nbsp;</p>
<ul class="ul1">
<li class="li2">Any mediation conducted via MediationX shall be conducted as per these rules.</li>
<li class="li2">These rules may not be modified by any Participant. However Participants may request modification on a case by case basis by e-mailing support@mediationx.com. No approval of any such request as mentioned will be valid unless it is in writing.<span class="Apple-converted-space">&nbsp;</span></li>
<li class="li2">&lsquo;Mediation&rsquo; includes the entire process from initiation to conclusion of the mediation.<span class="Apple-converted-space">&nbsp;</span></li>
<li class="li2">Mediation is initiated when the dispute comes to the notice of the Company and a case number is allocated.</li>
<li class="li2">A Mediation is conducted by a suitable legal professional being appointed by the Company (&ldquo;a Mediator&rdquo;).</li>
<li class="li2">Mediation is concluded when a Mediator, or the Company, certifies it having concluded for reasons including settlement reached, abandoned, etc, or alternatively if all Participants withdraws from the mediation in writing, or alternatively if the Mediator or the Company wish to terminate the mediation for any reason. If the Mediation is terminated by the Mediator or the Company without provision of any reason,, a refund may be sought by contacting support@mediationx.com</li>
<li class="li2">Mediators may only be appointed by the Company and can only mediate a dispute on accepting their appointment.<span class="Apple-converted-space">&nbsp;</span></li>
<li class="li2">Such appointments are at the discretion of the Company.</li>
<li class="li2">Requests for changing mediator may however be made at any time with consent of all Participants by e-mailing support@mediationx.com. On such request being made, the Company will make a final decision in writing on whether to accept or reject the request. If the request is accepted, the Company may allocate a Mediator.</li>
<li class="li2">In exceptional cases, requests for the appointment of a particular person as mediator may be made with the consent of all Participants by e-mailing support@mediationx.com On such request being made, the Company will make a final decision in writing on whether to accept or reject the request. If the request is accepted, the Company will invite the said person to mediate the dispute and/or register as an Independent Registered Mediator.</li>
<li class="li2">All Participants and Mediators shall keep all mediation related information or content confidential including the existence of the mediation and any settlement agreement, save for as necessary as per law or for enforcement.</li>
<li class="li2">The Mediator has sole conduct of a mediation, subject to these rules and the various subsisting agreements and policies. All mediations must be conducted via the company&rsquo;s website, save with express written permission acquired by the Mediator from the Company by e-mailing support@mediationx.com.<span class="Apple-converted-space">&nbsp;</span></li>
</ul>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p19">&nbsp;</p>
<p class="p1" id="privacy"><strong>PRIVACY POLICY</strong></p>
<p class="p3">&nbsp;</p>
<p class="p1"><strong>PRIVACY NOTICE</strong></p>
<p class="p2">Version 1.1</p>
<p class="p2"><strong>Last updated 20 July 2024</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2">This privacy notice for Hexameron Limited (doing business as MediationX) ("<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"), describes how and why we might collect, store, use, and/or share ("<strong>process</strong>") your information when you use our services ("<strong>Services</strong>"), such as when you:</p>
<ul class="ul1">
<li class="li20">Visit our website at www.mediationx.com, or any website of ours that links to this privacy notice</li>
<li class="li21">Engage with us in other related ways ― including any e-mails, sales, marketing, or events</li>
</ul>
<p class="p2"><strong>Questions or concerns?</strong> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">If you have any questions or concerns, please contact us at support@mediationx.com.</p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>SUMMARY OF KEY POINTS</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>This summary provides key points from our privacy notice, but you can find out more details about any of these topics below.<span class="Apple-converted-space">&nbsp;</span></em></strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>Do we process any sensitive personal information?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2">We do not aim to process any sensitive personal information. If you mention any sensitive personal information in the information you provide us, or on any part of our Services, we may inadvertently process such information, and you give us consent to do so.</p>
<p class="p3">&nbsp;</p>
<p class="p2">We may otherwise process any information as permitted by applicable law.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>Do we receive any information from third parties?</strong> We may receive information from public databases, marketing partners, social media platforms, and other outside sources. Learn more about information collected from other sources.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>In what situations and with which types of parties do we share personal information?</strong> We may share information in specific situations and with specific categories of third parties. Learn more about when and with whom we share your personal information.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>How do we keep your information safe?</strong> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>How do I exercise my rights?</strong> The easiest way to exercise your rights is by sending us a data subject access request or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>1. WHAT INFORMATION DO WE COLLECT?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>Personal information you disclose to us</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>In Short:&nbsp;</em></strong><em>We collect personal information that you provide to us.</em></p>
<p class="p3">&nbsp;</p>
<p class="p2">We collect personal information that you voluntarily provide to us when you register on or use the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
<p class="p3">&nbsp;</p>
<p class="p2">Names</p>
<p class="p2">Addresses</p>
<p class="p2">Post Codes</p>
<p class="p2">IP Addresses</p>
<p class="p2">Phone Numbers</p>
<p class="p2">E-mail Addresses</p>
<p class="p2">Device Identifiers including Device ID, model, manufacturer, operating system, version information, system configuration information, device numbers, browser type and version.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">ISP/Mobile carrier data</p>
<p class="p2">Proxy server information</p>
<p class="p2">Electronic or Digital Signatures</p>
<p class="p2">Geolocation information</p>
<p class="p2">Other information disclosed by you regarding a dispute</p>
<p class="p2">The names, addresses, postcodes, phone numbers, e-mail addresses of people You wish to involve in a mediation</p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p2">The above information is primarily needed for business operations purposes, to facilitate mediations, to maintain the security and operation of our business, for troubleshooting, to enhance electronic or digital signatures and for our internal analytics and reporting purposes.</p>
<p class="p22">&nbsp;</p>
<p class="p2"><strong>Sensitive Information.</strong></p>
<p class="p2">We do not aim to process sensitive information. However, with your consent, you may choose to share such information within our Services.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p22">&nbsp;</p>
<p class="p2"><strong>Payment Data.</strong> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored or handled by Monzo You may find their privacy notice link(s) on the website or by contacting them.</p>
<p class="p3">&nbsp;</p>
<p class="p2">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>Information automatically collected</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>In Short:&nbsp;</em></strong><em>Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device characteristics &mdash; is collected automatically when you visit our Services.</em></p>
<p class="p3">&nbsp;</p>
<p class="p2">We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
<p class="p3">&nbsp;</p>
<p class="p2">Like many businesses, we may also collect information through cookies and similar technologies. If we do so, you can find out more about this in our Cookie Notice.</p>
<p class="p3">&nbsp;</p>
<p class="p2">The information we collect includes:</p>
<ul class="ul1">
<li class="li20"><em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</li>
<li class="li2"><em>Device Data.</em> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
<li class="li2"><em>Location Data.</em> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
</ul>
<p class="p23">&nbsp;</p>
<p class="p2"><strong>Information collected from other sources</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>In Short:&nbsp;</em></strong><em>We may collect limited data from public databases, marketing partners, social media platforms, and other outside sources.</em></p>
<p class="p3">&nbsp;</p>
<p class="p2">In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, social media platforms, and from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behavior data), Internet Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event promotion. If you interact with us on a social media platform using your social media account (e.g., Facebook or Twitter), we receive personal information about you such as your name, email address, and gender. Any personal information that we collect from your social media account depends on your social media account's privacy settings.</p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>In Short:&nbsp;</em></strong><em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong></p>
<ul class="ul1">
<li class="li20"><strong>To facilitate account creation and authentication and otherwise manage user accounts.&nbsp;</strong>We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
<li class="li2"><strong>To deliver and facilitate delivery of services to the user.&nbsp;</strong>We may process your information to provide you with the requested service.</li>
<li class="li2"><strong>To respond to user inquiries/offer support to users.&nbsp;</strong>We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
<li class="li2"><strong>To send administrative information to you.&nbsp;</strong>We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>
<li class="li2"><strong>To fulfill and manage your payments.</strong> We may process your information to fulfill and manage your orders, payments and refunds made through the Services.</li>
<li class="li2"><strong>To enable user-to-user communications.&nbsp;</strong>We may process your information to allow for communication with another user and/or to facilitate mediations.</li>
<li class="li2"><strong>To request feedback.&nbsp;</strong>We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
<li class="li2"><strong>To send you marketing and promotional communications.&nbsp;</strong>We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below).</li>
<li class="li2"><strong>To deliver targeted advertising to you.</strong> We may process your information to develop and display personalised content and advertising tailored to your interests, location, and more.</li>
<li class="li2"><strong>To protect our Services.</strong> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
<li class="li2"><strong>To identify usage trends.</strong> We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
<li class="li2"><strong>To determine the effectiveness of our marketing and promotional campaigns.</strong> We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.</li>
<li class="li2"><strong>To save or protect an individual's vital interest.</strong> We may process your information when necessary to save or protect an individual&rsquo;s vital interest, such as to prevent harm.</li>
</ul>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>In Short:&nbsp;</em></strong><em>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</em></p>
<p class="p3">&nbsp;</p>
<p class="p2"><span class="s3"><strong><em>If you are located in the EU or UK, this section applies to you.</em></strong></span></p>
<p class="p3">&nbsp;</p>
<p class="p2">The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</p>
<ul class="ul1">
<li class="li20"><strong>Consent.&nbsp;</strong>We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent.</li>
<li class="li2"><strong>Performance of a Contract.</strong> We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</li>
<li class="li2"><strong>Legitimate Interests.</strong> We may process information provided by you when we believe it is reasonably necessary to achieve our legitimate interests, including legitimate business interests, and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process personal and other information provided by you for some of the purposes described in order to:</li>
<li class="li2">Facilitate and organise mediations and participation in mediations</li>
<li class="li2">Send users information about special offers and discounts on our products and services</li>
<li class="li2">Develop and display personalized and relevant advertising content for our users</li>
<li class="li2">Analyze how our Services are used so we can improve them to engage and retain users</li>
<li class="li2">Support our marketing activities</li>
<li class="li2">Diagnose problems and/or prevent fraudulent activities</li>
<li class="li2">Understand how our users use our products and services so we can improve user experience</li>
<li class="li2"><strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.</li>
<li class="li21"><strong>Vital Interests.</strong> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</li>
</ul>
<p class="p2">In legal terms, we are generally the &ldquo;data controller&rdquo; under European data protection laws of the personal information described in this privacy notice, since we determine the means and/or purposes of the data processing we perform. This privacy notice does not apply to the personal information we process as a &ldquo;data processor&rdquo; on behalf of our customers. In those situations, the customer that we provide services to and with whom we have entered into a data processing agreement is the &ldquo;data controller&rdquo; responsible for your personal information, and we merely process your information on their behalf in accordance with your instructions. If you want to know more about our customers' privacy practices, you should read their privacy policies and direct any questions you have to them.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>In Short:</em></strong><em>&nbsp;We may share information in specific situations described in this section and/or with the following categories of third parties.</em></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>Vendors, Consultants, and Other Third-Party Service Providers.</strong> We may share your data with third-party vendors, service providers, contractors, or agents (&ldquo;<strong>third parties</strong>&rdquo;) who perform services for us or on our behalf, or for you on your behalf, and require access to such information to do that work. We have contracts in place with third parties, which are designed to help safeguard your personal information. They will also not share your personal information with any organisation apart from us. They also commit to protect the data they hold on to retain it for a reasonable period only. The categories of third parties we may share personal information with are as follows:</p>
<ul class="ul1">
<li class="li20">Mediators</li>
<li class="li2">Ad Networks</li>
<li class="li2">Affiliate Marketing Programs</li>
<li class="li2">Cloud Computing Services</li>
<li class="li2">Communication &amp; Collaboration Tools</li>
<li class="li2">Data Analytics Services</li>
<li class="li2">Data Storage Service Providers</li>
<li class="li2">Finance &amp; Accounting Tools</li>
<li class="li2">Government Entities</li>
<li class="li2">Order Fulfillment Service Providers</li>
<li class="li2">Payment Processors</li>
<li class="li2">Performance Monitoring Tools</li>
<li class="li2">Product Engineering &amp; Design Tools</li>
<li class="li2">Retargeting Platforms</li>
<li class="li2">Sales &amp; Marketing Tools</li>
<li class="li2">Social Networks</li>
<li class="li2">Testing Tools</li>
<li class="li2">User Account Registration &amp; Authentication Services</li>
<li class="li2">Website Hosting Service Providers</li>
<li class="li2">Website design and software development providers</li>
<li class="li2">Business development agents</li>
<li class="li2">Franchisees</li>
</ul>
<p class="p2">We also may need to share your personal information in the following situations:</p>
<ul class="ul1">
<li class="li20"><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
<li class="li2"><strong>When we use Google Analytics or Hotjar.&nbsp;</strong>We may share your information with Google Analytics or Hotjar to track and analyze the use of the Services. To opt out of being tracked, please opt-out with these third parties directly or e-mail support@mediationx.com.</li>
<li class="li2"><strong>When we use Google Maps Platform APIs.</strong> We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). We may use certain Google Maps Platform APIs to retrieve certain information when you make location-specific requests.. A full list of what we use information for can be found in this section and in the previous section titled &ldquo;HOW DO WE PROCESS YOUR INFORMATION?&rdquo;<span class="s1">&nbsp;</span>The Google Maps Platform APIs that we may use may store and access cookies and other information on your devices. If you are a user currently in the European Economic Area (EU countries, Iceland, Liechtenstein and Norway) or the United Kingdom, and if we use such cookies, please take a look at our Cookie Notice.</li>
<li class="li2"><strong>Affiliates.&nbsp;</strong>We may share your information with our affiliates, in which case we will require those affiliates to honour this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
<li class="li2"><strong>Business Partners.</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
<li class="li2"><strong>When we facilitate or organise mediations</strong></li>
<li class="li2"><strong>When you have expressly or impliedly requested us to share personal information</strong></li>
<li class="li2"><strong>Other Users.</strong> When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by certain users and there is a risk that may be publicly made available outside the Services in perpetuity, although contractually such users are bound by our Terms and Conditions and may be bound to respect the MediationX Mediation Agreement and related agreements, rules or policies.</li>
</ul>
<p class="p22">&nbsp;</p>
<p class="p2"><strong>5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>In Short:</em></strong><em>&nbsp;We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.</em></p>
<p class="p3">&nbsp;</p>
<p class="p2">The Services may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications. The inclusion of a link towards a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>In Short:</em></strong><em>&nbsp;We may use cookies and other tracking technologies to collect and store your information.</em></p>
<p class="p3">&nbsp;</p>
<p class="p2">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. If we do use cookies,<span class="Apple-converted-space">&nbsp;&nbsp;</span>specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>8. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>In Short:&nbsp;</em></strong><em>We may transfer, store, and process your information in countries other than your own.</em></p>
<p class="p3">&nbsp;</p>
<p class="p2">Our servers are located in the UK. Wherever you are accessing our Services from, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" above), in<span class="s2">&nbsp;the UK</span>, and other countries.</p>
<p class="p3">&nbsp;</p>
<p class="p2">If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.</p>
<p class="p3">&nbsp;</p>
<p class="p2">European Commission's Standard Contractual Clauses:</p>
<p class="p3">&nbsp;</p>
<p class="p2">We have implemented measures to protect your personal information, including by using the European Commission's Standard Contractual Clauses for transfers of personal information between our group companies and between us and our third-party providers. These clauses require all recipients to protect all personal information that they process originating from the EEA or UK in accordance with European data protection laws and regulations. We have implemented similar appropriate safeguards with our third-party service providers and partners and further details can be provided upon request.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">Binding Corporate Rules:</p>
<p class="p3">&nbsp;</p>
<p class="p2">These include a set of Binding Corporate Rules ("BCRs") established and implemented by us. Our BCRs have been recognized by EEA and UK data protection authorities as providing an adequate level of protection to the personal information we process internationally. You can request a copy of our BCRs by e-mailing support@mediationx.com<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>9. HOW LONG DO WE KEEP YOUR INFORMATION?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>In Short:&nbsp;</em></strong><em>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</em></p>
<p class="p3">&nbsp;</p>
<p class="p2">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this policy/notice will require us to keep your personal information for longer than 12 months past the termination of a user&rsquo;s account, save that information related to a mediation may be retained until all mediation Participants seek deletion of the data by writing to support@mediationx.com.</p>
<p class="p3">&nbsp;</p>
<p class="p2">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>10. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>In Short:&nbsp;</em></strong><em>We aim to protect your personal information through a system of organizational and technical security measures.</em></p>
<p class="p3">&nbsp;</p>
<p class="p2">We, directly or through our vendors, contractors or other third parties, have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>11. DO WE COLLECT INFORMATION FROM MINORS?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>In Short:</em></strong><em>&nbsp;We do not knowingly collect data from or market to children under 18 years of age.</em></p>
<p class="p3">&nbsp;</p>
<p class="p2">We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at support@mediationx.com</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>12. WHAT ARE YOUR PRIVACY RIGHTS?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>In Short:</em></strong><em>&nbsp;In some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</em></p>
<p class="p3">&nbsp;</p>
<p class="p2">In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section &ldquo;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?<span class="s2">&rdquo;</span> below.</p>
<p class="p3">&nbsp;</p>
<p class="p2">We will consider and act upon any request in accordance with applicable data protection laws.</p>
<p class="p3"><span class="Apple-converted-space">&nbsp;</span></p>
<p class="p2">If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2">If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><span class="s3"><strong>Withdrawing your consent:</strong></span> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below or updating your preferences.</p>
<p class="p3">&nbsp;</p>
<p class="p2">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><span class="s3"><strong>Opting out of marketing and promotional communications:&nbsp;</strong></span>You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, replying &ldquo;STOP&rdquo; or &ldquo;UNSUBSCRIBE&rdquo; to the SMS messages that we send,<span class="s1">&nbsp;</span>or by contacting us using the details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from the marketing lists &mdash; however, we may still communicate with you, for example to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>Account Information</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2">If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
<ul class="ul1">
<li class="li20">Log in to your account settings and update your user account.</li>
<li class="li2">Contact us using the contact information provided.</li>
</ul>
<p class="p22">&nbsp;</p>
<p class="p2">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, maintain mediation records, enforce our legal terms and/or comply with applicable legal requirements.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><span class="s3"><strong>Cookies and similar technologies:</strong></span> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject<span class="s2">&nbsp;</span>cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. You may also opt out of interest-based advertising by advertisers on our Services. For further information, and if we choose to use cookies, please see our Cookie Notice.</p>
<p class="p3">&nbsp;</p>
<p class="p2">If you have questions or comments about your privacy rights, you may email us at support@mediationx.com.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>13. CONTROLS FOR DO-NOT-TRACK FEATURES</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>16. DO WE MAKE UPDATES TO THIS NOTICE?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong><em>In Short:&nbsp;</em></strong><em>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></p>
<p class="p3">&nbsp;</p>
<p class="p2">We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>17. HOW CAN YOU CONTACT US ABOUT THIS POLICY/NOTICE?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2">If you have questions or comments about this Policy/notice, you may contact our Data Protection Officer (DPO) by email at <span class="s4">support@mediationx.com</span>.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p2"><strong>18. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</strong></p>
<p class="p3">&nbsp;</p>
<p class="p2">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please contact us via support@mediationx.com</p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
<p class="p3">&nbsp;</p>
`
