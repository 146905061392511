import { assignTextStyle, AvatarComposition } from '@codeleap/common'
import { BadgeComposition } from '@codeleap/web'
import { variantProvider } from '../theme'
import { CardBaseComposition } from './CardBase'

export type ThreadComposition =
 | `card${Capitalize<CardBaseComposition>}`
 | 'wrapper'
 | 'innerWrapper'
 | 'innerWrapper:empty'
 | `avatar${Capitalize<AvatarComposition>}`
 | `badge${Capitalize<BadgeComposition>}`
 | 'content'
 | 'header'
 | 'title'
 | 'hour'
 | 'body'
 | 'name'
 | 'message'
 | 'empty'

const createThreadStyle = variantProvider.createVariantFactory<ThreadComposition>()

export const ThreadStyles = {
  default: createThreadStyle((theme) => ({
    wrapper: {
      backgroundColor: theme.colors.neutral2,
      ...theme.border.neutral3({ 'directions': ['top'], 'width': theme.values.borderWidth.small }),
    },
    cardWrapper: {
      borderRadius: 0,
      maxHeight: 92,
      minHeight: 92,
    },
    cardInnerWrapper: {
      ...theme.presets.row,
      ...theme.spacing.gap(2),
      ...theme.presets.fullHeight,
    },
    avatarWrapper: {
      minWidth: 32,
      ...theme.sized(4),
    },
    avatarImage: {
      ...theme.sized(4),
    },
    badgeWrapper: {
      ...theme.sized(2),
      backgroundColor: theme.colors.neutral2,
      ...theme.presets.center,
    },
    badgeInnerWrapper: {
      ...theme.sized(1.25),
      backgroundColor: theme.colors.destructive2,
    },
    content: {
      ...theme.presets.flex,
      maxWidth: 'calc(100% - 48px)',
      ...theme.presets.column,
      ...theme.spacing.gap(1),
    },
    header: {
      maxHeight: 20,
      ...theme.spacing.gap(1),
    },
    title: {
      ...assignTextStyle('h5')(theme).text,
      lineHeight: '20px',
      ...theme.presets.fullWidth,
      ...theme.presets.justifySpaceBetween,
      color: theme.colors.neutral8,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    hour: {
      ...assignTextStyle('p4')(theme).text,
      lineHeight: '16px',
      color: theme.colors.neutral7,
      whiteSpace: 'nowrap',
    },
    body: { maxHeight: 32 },
    message: {
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral7,
      ...theme.presets.fullWidth,
      lineHeight: '16px',
      overflow: 'hidden',
      display: '-webkit-box',
      webkitBoxOrient: 'vertical',
      webkitLineClamp: 2,
      overflowY: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
    },
    name: {
      ...assignTextStyle('p3')(theme).text,
      lineHeight: '16px',
      fontWeight: '700',
    },
    tagWrapper: {
      backgroundColor: theme.colors.neutral10,
    },
    tagText: {
      ...assignTextStyle('p3')(theme).text,
    },
    empty: {
      alignSelf: 'center',
    },
  })),
}
