import { TextInputComposition } from '@codeleap/common'
import { variantProvider } from '../theme'
import { PageSectionComposition } from './PageSection'
import { assignTextStyle } from './Text'

type BannerComposition = 'wrapper' | 'icon' | 'text'

export type ChatComposition =
 | 'wrapper'
 | 'innerWrapper'
 | `toolbar${Capitalize<TextInputComposition>}`
 | `pageSection${Capitalize<PageSectionComposition>}`
 | `banner${Capitalize<BannerComposition>}`

const createChatStyle = variantProvider.createVariantFactory<ChatComposition>()

export const ChatStyles = {
  default: createChatStyle((theme) => ({
    pageSectionWrapper: {
      background: 'linear-gradient(180deg, #F6F6F6 0%,  #FFFFFF 100%)',
    },
    pageSectionContentWrapper: {
      ...theme.spacing.paddingTop(0.5),
    },
    wrapper: {
      ...theme.presets.flex,
      ...theme.presets.relative,
      ...theme.presets.column,
      ...theme.presets.justifyEnd,
      height: 'calc(100vh - 205px)',
      [theme.media.down('midish')]: {
        height: 'calc(100vh - 149px)',
      },
      [theme.media.down('smallish')]: {
        height: 'calc(100vh - 109px)',
      },

    },
    innerWrapper: {
      height: 'calc(100% - 62px)',
      ...theme.spacing.gap(0.5),
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      ...theme.presets.absolute,
      ...theme.presets.top,
      overflowX: 'hidden',
      '&::-webkit-scrollbar': { width: 0 },
    },
    toolbarWrapper: {
      ...theme.presets.absolute,
      ...theme.presets.bottom,
    },
    'toolbarInnerWrapper:hasMultipleLines': {
      minHeight: 'unset',
    },

    bannerWrapper: {
      ...theme.spacing.padding(2),
      ...theme.spacing.marginTop(2),
      ...theme.spacing.gap(2),
      backgroundColor: theme.colors.neutral3,
      borderRadius: theme.borderRadius.small,
    },
    bannerIcon: {
      ...theme.sized(2.5),
    },
    bannerText: {
      ...assignTextStyle('p3')(theme).text,
      ...theme.presets.flex,
    },
  })),
}
