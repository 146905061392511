import {
  BadgeComposition,
  ButtonComposition,
  SelectComposition,
} from '@codeleap/web'
import { variantProvider } from '../theme'
import { assignTextStyle } from './Text'
import { OptionsDropDownComposition } from './OptionsDropDown'

export type DisputeSelectStatusComposition =
  | `dropdown${Capitalize<OptionsDropDownComposition>}`
  | `select${Capitalize<SelectComposition>}`
  | 'funnel'
  | 'chevron'
  | 'text'
  | 'wrapper'
  | `option${Capitalize<ButtonComposition>}`
  | `badge${Capitalize<BadgeComposition>}`

const createDisputeSelectStatusStyle =
  variantProvider.createVariantFactory<DisputeSelectStatusComposition>()

const DEFAULT_SIZE = 175

export const DisputeSelectStatusStyles = {
  default: createDisputeSelectStatusStyle((theme) => ({
    wrapper: {
      width: DEFAULT_SIZE,
      ...theme.presets.center,
      backgroundColor: theme.colors.neutral2,
      ...theme.spacing.padding(1),
      ...theme.spacing.gap(0.5),
      height: theme.values.itemHeight.small,
      borderRadius: theme.borderRadius.tiny,
      [theme.media.down('small')]: {
        width: 'fit-content',
      },
    },
    funnel: {
      ...theme.sized(2),
      color: theme.colors.neutral7,
    },
    text: {
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral7,
      ...theme.spacing.marginRight('auto'),
      [theme.media.down('small')]: {
        display: 'none',
      },
    },
    chevron: {
      ...theme.sized(2),
      color: theme.colors.neutral7,
      [theme.media.down('small')]: {
        display: 'none',
      },
    },
    dropdownTooltipWrapper: {
      width: DEFAULT_SIZE,
      ...theme.spacing.margin(2),
      ...theme.spacing.marginTop(1),
    },
    optionWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.noWrap,
      borderRadius: theme.spacing.value(0),
      backgroundColor: theme.colors.neutral1,
      ...theme.presets.justifyStart,
      height: theme.values.itemHeight.default,
      ...theme.border.neutral3({
        directions: ['bottom'],
        width: theme.values.borderWidth.small,
      }),

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },

      '&:first-of-type': {
        borderTopRightRadius: theme.borderRadius.small,
        borderTopLeftRadius: theme.borderRadius.small,
      },

      '&:last-of-type': {
        borderBottomRightRadius: theme.borderRadius.small,
        borderBottomLeftRadius: theme.borderRadius.small,
      },
    },
    'optionWrapper:selected': {
      backgroundColor: theme.colors.primary2,
    },

    optionText: {
      ...theme.presets.textLeft,
    },
    'optionText:selected': {
      color: theme.colors.primary4,
    },

    optionIcon: {
      color: theme.colors.primary4,
    },

    badgeWrapper: {
      position: 'static',
    },
  })),
  details: createDisputeSelectStatusStyle((theme) => ({
    wrapper: {
      width: 'fit-content',
    },
    text: {
      [theme.media.down('small')]: {
        ...theme.presets.flex,
      },
    },
    chevron: {
      [theme.media.down('small')]: {
        ...theme.presets.flex,
      },
    },
  })),
}
