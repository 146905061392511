import { ButtonComposition, TagComposition } from '@codeleap/web'
import { variantProvider, assignTextStyle } from '..'

export type DisputeInfoComposition =
  | 'wrapper'
  | `button${Capitalize<ButtonComposition>}`
  | 'chatWrapper'
  | 'disputeNumber'
  | 'disputeTitle'
  | 'description'
  | 'chatTitle'
  | 'tagsWrapper'
  | `statusTag${Capitalize<TagComposition>}`
  | `categoryTag${Capitalize<TagComposition>}`
  | `priceTag${Capitalize<TagComposition>}`

const createDisputeInfoStyle = variantProvider.createVariantFactory<DisputeInfoComposition>()

export const DisputeInfoStyles = {
  default: createDisputeInfoStyle((theme) => ({
    wrapper: {
      width: 400,
      ...theme.spacing.padding(2),
      ...theme.presets.column,
      ...theme.spacing.gap(2),
      [theme.media.down('largeish')]: {
        maxWidth: 300,
      },
      [theme.media.down('midish')]: {
        maxWidth: 'unset',
        ...theme.presets.fullWidth,
      },
    },

    // dispute number and title styles
    disputeTitle: {
      ...assignTextStyle('h3')(theme).text,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    disputeNumber: {
      ...assignTextStyle('h3')(theme).text,
      color: theme.colors.neutral7,
    },

    description: {
      ...assignTextStyle('p3')(theme).text,
      overflowWrap: 'break-word',
      maxHeight: 70,
      overflow: 'hidden',
    },

    // tags styles
    tagsWrapper: {
      ...theme.spacing.gap(1),
      ...theme.presets.row,
      ...theme.presets.wrap,
    },

    // category tag styles
    categoryTagText: {
      whiteSpace: 'nowrap',
    },

    // price tag styles
    priceTagWrapper: {
      backgroundColor: theme.colors.neutral2,
    },

    // button styles
    buttonWrapper: {
      ...theme.spacing.paddingHorizontal(2),
      ...theme.spacing.paddingVertical(1),
      backgroundColor: theme.colors.neutral2,
      borderRadius: theme.borderRadius.small,
      height: theme.values.itemHeight.default,
    },
    buttonText: {
      color: theme.colors.neutral8,
    },
    buttonIcon: {
      color: theme.colors.neutral7,
      ...theme.sized(2.5),
    },
    'buttonWrapper:selected': {
      backgroundColor: theme.colors.primary2,
    },
    'buttonText:selected': {
      color: theme.colors.primary4,
    },
    'buttonIcon:selected': {
      color: theme.colors.primary4,
    },

    // chat threads styles
    chatTitle: {
      ...assignTextStyle('h4')(theme).text,
      ...theme.spacing.padding(2),
      backgroundColor: theme.colors.primary3,
      color: theme.colors.neutral1,
      borderTopLeftRadius: theme.borderRadius.medium,
      borderTopRightRadius: theme.borderRadius.medium,
    },
    chatWrapper: {
      ...theme.presets.flex,
      ...theme.presets.column,
    },
  })),
}
