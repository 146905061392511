import { AppStatus } from '@/redux'
import { getFirebaseAuth } from '../../firebaseApp'
import { QueryKeys } from './queryKeys'
import { useSession } from './useSession'
import { ERRORS, isEmailAvailable, Profile } from './views'
import { OSAlert } from '@/components'

export function useSignup() {
  const { createProfile, warnEmailInUse } = useSession()

  const sendEmailVerification = async (user) => {
    logger.debug('Sending email verification', user, 'Auth')
    await user.sendEmailVerification()
  }

  const signup = async (data:Partial<Profile>, source:SocialProviderTypes|'email' = 'email') => {
    const firebaseAuth = await getFirebaseAuth()

    AppStatus.set('loading')
    try {
      const emailAvailable = await isEmailAvailable(data.email)
      logger.debug('Email available', emailAvailable, 'Auth')
      let currentUser = firebaseAuth().currentUser
      logger.debug('Firebase currentUser', currentUser, 'Sign Up')

      currentUser?.reload?.()

      logger.debug('Firebase currentUser (after reload)', currentUser, 'Sign Up')

      if (!emailAvailable) {
        throw ERRORS.emailInUse
      }

      try {
        if (source === 'email') {
          logger.debug('Creating user with email and password', data, 'Sign Up')
          const newUser = await firebaseAuth().createUserWithEmailAndPassword(data.email, data.password)
          currentUser = newUser.user
        }

        currentUser?.reload?.()

        if (!currentUser?.emailVerified) {
          await sendEmailVerification(currentUser)

        }

        currentUser?.reload?.()

      } catch (e) {
        logger.error('Error on signup', e, 'Auth')

        if (e.code === 'auth/email-already-in-use') {
          throw ERRORS.emailInUse
        }
      }
      logger.debug('Creating profile', data, 'Sign Up')

      await createProfile.mutateAsync(data)
      await QueryKeys.me.refresh()

      AppStatus.set('done')

      return {
        user: currentUser,
      }
    } catch (e) {
      AppStatus.set('idle')
      logger.error('Error on signup', e, 'Auth')
      if (e.cause === 'auth/email-already-in-use') {
        warnEmailInUse()
      } else {
        OSAlert.error({
          title: 'Signup error',
          body: 'Something happened! not possible to complete sign up',
        })
      }
    }

  }

  return { signup, sendEmailVerification }
}
