import { assignTextStyle, ButtonComposition, TooltipComposition } from '@codeleap/common'
import { variantProvider } from '../theme'

export type OptionsDropDownComposition = 'outerWrapper'
 | 'wrapper'
 | 'tooltip'
 | `tooltip${Capitalize<TooltipComposition>}`
 | 'tooltipInnerWrapper'
 | 'icon'
 | 'text'
 | 'option'
 | `option${Capitalize<ButtonComposition>}`

const createOptionsDropDownStyle = variantProvider.createVariantFactory<OptionsDropDownComposition>()

export const OptionsDropDownStyles = {
  default: createOptionsDropDownStyle((theme) => ({
    outerWrapper: {
      position: 'relative',
    },
    wrapper: {
      backgroundColor: 'transparent',
    },
    'wrapper:focused': {
      backgroundColor: theme.colors.neutral2,
    },
    icon: {
      color: theme.colors.neutral10,
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    text: {
      ...assignTextStyle('p3')(theme).text,
      color: theme.colors.neutral7,
    },

    // tooltip
    tooltipInnerWrapper: {
      cursor: 'pointer',
      ...theme.presets.alignEnd,
    },
    tooltipWrapper: {
      ...theme.spacing.padding(0),
      ...theme.presets.column,
      backgroundColor: theme.colors.neutral1,
      ...theme.effects.light,
      zIndex: 500,
    },
    tooltipArrow: {
      display: 'none',
      zIndex: 500,
      width: 0,
      height: 0,
    },

    optionWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.noWrap,
      borderRadius: theme.spacing.value(0),
      backgroundColor: theme.colors.neutral1,
      ...theme.presets.justifyStart,
      height: theme.values.itemHeight.default,
      ...theme.border.neutral3({ directions: ['bottom'], width: theme.values.borderWidth.small }),

      '&:hover': {
        backgroundColor: theme.colors.neutral2,
      },

      '&:first-of-type': {
        borderTopRightRadius: theme.borderRadius.small,
        borderTopLeftRadius: theme.borderRadius.small,
      },

      '&:last-of-type': {
        borderBottomRightRadius: theme.borderRadius.small,
        borderBottomLeftRadius: theme.borderRadius.small,
      },
    },
  })),
  settlement: createOptionsDropDownStyle((theme) => ({
    icon: {
      color: theme.colors.neutral10,
    },
    optionWrapper: {
      '&:hover': {
        backgroundColor: theme.colors.primary2,
      },
    },
  })),
}
