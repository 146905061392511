import { AppImages } from '@/app'
import { ImageModal } from '@/components'
import { AppStatus, useAppSelector } from '@/redux'
import { useIsMobile } from '@/utils'
import { useI18N } from '@codeleap/common'

export const PaywallModal = () => {
  const modals = useAppSelector((store) => store.AppStatus.modals)
  const { t } = useI18N()

  const isMobile = useIsMobile()

  const visible = modals.paywall
  const toggle = () => AppStatus.setModal(['paywall', false])

  return (
    <ImageModal
      debugName='PaywallModal'
      visible={visible}
      toggle={toggle}
      image={AppImages.Paywall}
      variants={['payWall']}
      responsiveVariants={{ small: ['fullscreen'] as any }}
      showCloseButton={isMobile}
      title={t('PaywallModal.title')}
      description={t('PaywallModal.description')}
      buttonProps={{
        debugName: 'PaywallModal:Dismiss',
        text: 'Continue',
        onPress: toggle,
      }}
    />
  )
}
