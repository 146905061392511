import { AppIcon } from '@/app'
import { NavigationLinkStyles } from '@/app/stylesheets'
import { Text, View, useNavigation } from '@/components'
import { Navigation, RoutePath } from '@/utils'
import { ComponentVariants, IconPlaceholder, TypeGuards, useDefaultComponentStyle } from '@codeleap/common'
import { Icon } from '@codeleap/web'

export type NavigationLinkProps = ComponentVariants<typeof NavigationLinkStyles> & {
    styles?: typeof NavigationLinkStyles
    style?: StyleSheet
    title: string
    icon?: AppIcon
    to?: RoutePath
    minimal?: boolean
    onPress?: () => void
  }

export function NavigationLink({ icon, title, to, minimal, onPress, ...props }: NavigationLinkProps) {
  const { styles, variants, responsiveVariants } = props
  const { url } = useNavigation()

  const variantStyles = useDefaultComponentStyle<'u:NavigationLink', typeof NavigationLinkStyles>('u:NavigationLink', {
    responsiveVariants,
    variants,
    styles,
  })

  const getStyles = (key: string) => {
    const status = url?.includes(Navigation.getRoutePath(to)) ? 'selected' : 'unselected'

    return {
      ...variantStyles[key],
      ...(!minimal && variantStyles[`${key}:${status}`]),
    }
  }

  const handlePress = () => {
    if (onPress) onPress()
    else Navigation.navigate(to)
  }

  return (
    <View onClick={handlePress} css={getStyles('wrapper')}>
      {!TypeGuards.isNil(icon) ? (
        <Icon name={icon as IconPlaceholder} debugName={`SideBarMenu - ${title}`} style={getStyles('icon')} />
      ) : null}
      <Text variants={['p1', 'noWrap']} text={title} css={getStyles('text')} />
    </View>
  )
}
