import { variantProvider } from '@/app'
import { View, Text, Icon } from '@/components'
import { IconProps } from '@codeleap/web'

export type CompanyStrengthsProps = {
  title: string
  description: string
  icon: IconProps['name']
}

export const CompanyStrengthsItem = (props: CompanyStrengthsProps) => {
  const { title, description, icon } = props

  return (
    <View variants={['column', 'alignCenter']} style={styles.itemWrapper}>
      <View>
        <Icon
          name={icon}
          variants={['largeish', 'marginBottom:2']}
          style={styles.icon}
          debugName={`${title} icon`}
        />
      </View>

      <Text text={title} variants={['h2', 'color:primary4', 'marginBottom:1']} />
      <Text text={description} variants={['p1', 'textCenter']} style={styles.description} />
    </View>
  )
}

const HEIGHT = 290

const styles = variantProvider.createComponentStyle(theme => ({
  itemWrapper: {
    maxHeight: HEIGHT,
    minHeight: HEIGHT,

    [theme.media.down('small')]: {
      maxHeight: 244,
      minHeight: 244,
    },
  },
  icon: {
    color: theme.colors.primary4,
  },
  description: {
    lineHeight: '20px',
  },
}), true)
