import React from 'react'

type FirebaseInstance = any

let initialized = false

type FirebaseFunction<T> = (fb: FirebaseInstance) => T

/*
  Firebase does not work in a server environment as it requires access to the browser's fetch API
  This is a workaround to allow us to use Firebase in the browser without failing Gatbsy's build process
*/

const config = {
  apiKey: 'AIzaSyA11fIeX9lspQc3FYIAPePTxxZshtMq6po',
  authDomain: 'x-mediationx.firebaseapp.com',
  projectId: 'x-mediationx',
  storageBucket: 'x-mediationx.appspot.com',
  messagingSenderId: '285555932861',
  appId: '1:285555932861:web:50f7fad8a36e8b4867c1d4',
  measurementId: 'G-7FNQFLGDH8',
}

let cachedInstance: FirebaseInstance = null

export async function getFirebase() {
  const fbApp = await import('firebase/compat/app')
  await import('firebase/compat/auth')
  await import('firebase/compat/analytics')

  const _firebase = fbApp.default

  if (!initialized) {
    _firebase.initializeApp(config)
    cachedInstance = fbApp
    initialized = true
  }

  return _firebase
}

export const useFirebase = () => {
  const [firebase, setFirebase] = React.useState<FirebaseInstance | null>(cachedInstance)

  React.useEffect(() => {
    if (!firebase) {
      getFirebase().then(setFirebase)
    }
  }, [])

  return firebase
}

export async function getFirebaseAuth() {
  const firebaseAuth = (await getFirebase()).auth
  return firebaseAuth
}
