import { variantProvider } from '@/app'
import { View } from '@/components'

type PDFViewerProps = {
  url: string | File | null
}

export function PDFViewer({ url }: PDFViewerProps) {
  if (!url) return null
  return (
    <View css={[styles.wrapper]}>
      <iframe
        src={url as string}
        width='100%'
        height='100%'
        style={{ border: 'none' }}
      />
    </View>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  wrapper: {
    width: 720,
    ...theme.presets.fullHeight,
  },
}), true)
