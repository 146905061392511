import { ActionIconComposition, ButtonComposition, DropzoneComposition, ModalComposition, ProgressCircleComposition } from '@codeleap/web'
import { variantProvider } from '..'
import { assignTextStyle } from './Text'

type HeaderComposition = 'wrapper' | 'title' | `actionIcon${Capitalize<ActionIconComposition>}`
type UploadingComposition = 'wrapper' | 'text' | 'name' | `progress${Capitalize<ProgressCircleComposition>}`

export type UploadSettlementComposition =
  | 'wrapper'
  | `dropzone${Capitalize<DropzoneComposition>}`
  | `modal${Capitalize<ModalComposition>}`
  | `header${Capitalize<HeaderComposition>}`
  | `footerButton${Capitalize<ButtonComposition>}`
  | `uploading${Capitalize<UploadingComposition>}`

const createUploadSettlementStyle =
  variantProvider.createVariantFactory<UploadSettlementComposition>()

const MODAL_WIDTH = 480

export const UploadSettlementStyles = {
  default: createUploadSettlementStyle((theme) => ({
    wrapper: {},

    //Modal
    modalBox: {
      width: MODAL_WIDTH,
      ...theme.spacing.padding(4),
      [theme.media.down('small')]: {
        width: '100%',
        ...theme.spacing.padding(2),
      },
    },

    //Header
    headerWrapper: {
      ...theme.spacing.gap(2),
      ...theme.presets.justifySpaceBetween,
    },
    headerTitle: {
      ...assignTextStyle('h3')(theme).text,
      ...theme.presets.alignSelfCenter,
    },

    //Dropzone
    dropzoneWrapper: {
      ...theme.spacing.gap(4.5),
      ...theme.presets.fullWidth,
    },
    dropzoneIconWrapper: {
      backgroundColor: theme.colors.primary2,
    },
    dropzoneIcon: {
      color: theme.colors.primary3,
      stroke: theme.colors.primary3,
    },
    dropzoneFileRightIconIcon: {
      color: theme.colors.primary3,
      stroke: theme.colors.primary3,
    },
    dropzonePlaceholder: {
      ...assignTextStyle('p3')(theme).text,
      lineHeight: '16px',
    },
    dropzoneDropzone: {
      ...theme.presets.fullWidth,
    },
    dropzoneFilesWrapper: {
      ...theme.presets.fullWidth,
    },
    dropzoneFileWrapper: {
      backgroundColor: theme.colors.neutral2,
    },
    dropzoneFileNameWrapper: {
      width: 'calc(100% - 56px)',
    },

    //Footer
    footerButtonWrapper: {
      height: theme.values.itemHeight.default,
    },

    //Uploading
    uploadingWrapper: {
      ...theme.presets.flex,
      ...theme.presets.column,
      ...theme.spacing.gap(2),
      ...theme.presets.center,
    },
    uploadingProgressWrapper: {
      // ...theme.sized(12.5),
      size: '100px',
      // maxHeight: theme.sized(12.5).height,
      // ...theme.presets.center,
    },
    uploadingProgressText: {
      color: theme.colors.primary3,
      overflow: 'hidden',
      maxWidth: '100%',
    },
    uploadingText: {
      ...assignTextStyle('h5')(theme).text,
    },
    uploadingName: {
      ...assignTextStyle('p3')(theme).text,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: 'calc(100% - 64px)',
      [theme.media.down('small')]: {
        maxWidth: 'calc(100% - 32px)',
      },
    },
  })),
}
