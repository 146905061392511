import { variantProvider } from '../theme'
import { assignTextStyle } from './Text'

type Status = ':selected' | ':disabled' | ':unselected' | ''

export type NavigationLinkComposition =
  | `wrapper${Status}`
  | `icon${Status}`
  | `text${Status}`

const createNavigationLinkStyle = variantProvider.createVariantFactory<NavigationLinkComposition>()

export const NavigationLinkStyles = {
  default: createNavigationLinkStyle((theme) => ({
    wrapper: {
      ...theme.presets.justifyStart,
      ...theme.spacing.padding(2),
      height: theme.values.itemHeight.default,
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      borderRadius: theme.borderRadius.small,
      maxWidth: 400,
      ...theme.presets.fullWidth,
      [theme.media.down('mid')]: {
        ...theme.presets.justifyCenter,
      },
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: theme.colors.neutral3,
      },
    },
    icon: {
      ...theme.spacing.marginRight(1),
      ...theme.sized(2.5),
      color: theme.colors.primary3,
    },
    text: {
      ...assignTextStyle('h5')(theme).text,
      color: theme.colors.primary3,
      textDecoration: 'none',
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
    'wrapper:selected': {
      backgroundColor: theme.colors.primary2,
    },
    'icon:selected': {
      color: theme.colors.primary4,
    },
    'text:selected': {
      color: theme.colors.primary4,
    },
    'wrapper:unselected': {
      backgroundColor: theme.colors.neutral2,
    },
    'icon:unselected': {
      color: theme.colors.neutral8,
    },
    'text:unselected': {
      color: theme.colors.neutral8,
    },
  })),
}
