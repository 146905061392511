import { variantProvider } from '@/app'
import { View, Touchable, ActionIcon, Text } from '@/components'
import { useI18N } from '@codeleap/common'
import { useState } from 'react'

export const HowWorks = () => {
  const { t } = useI18N()

  const howWorksSteps = [
    {
      id: 0,
      title: t('Homepage.howWorks.gettingStarted.title'),
      icon: 'scale',
      content: (
        <View variants={['column', 'gap:2']}>
          <Text text={t('Homepage.howWorks.gettingStarted.txt1')} variants={['p1']} />
          <Text text={t('Homepage.howWorks.gettingStarted.txt2')} variants={['p1']} />
          <Text text={t('Homepage.howWorks.gettingStarted.txt3')} variants={['p1']} />
          <Text text={t('Homepage.howWorks.gettingStarted.txt4')} variants={['p1']} />
        </View>
      ),
    },
    {
      id: 1,
      title: t('Homepage.howWorks.resolution.title'),
      icon: 'timer',
      content: (
        <View variants={['column', 'gap:2']}>
          <Text text={t('Homepage.howWorks.resolution.txt1')} variants={['p1']} />
          <Text text={t('Homepage.howWorks.resolution.txt2')} variants={['p1']} />
          <Text text={t('Homepage.howWorks.resolution.txt3')} variants={['p1']} />
        </View>
      ),
    },

    {
      id: 2,
      title: t('Homepage.howWorks.solution.title'),
      icon: 'coins',
      content: (
        <View variants={['column', 'gap:2']}>
          <Text text={t('Homepage.howWorks.solution.txt1')} variants={['p1']} />
          <Text text={t('Homepage.howWorks.solution.txt2')} variants={['p1']} />
        </View>
      ),
    },
  ]

  const [selectedStep, setSelectedStep] = useState(0)

  const content = howWorksSteps.find(
    (item) => item.id === selectedStep,
  ).content

  const handleStepPress = (itemId: number) => {
    setSelectedStep(itemId)
  }

  return (
    <View
      variants={[
        'padding:8',
        'justifySpaceBetween',
        'alignCenter',
        'gap:4',
        'backgroundColor:neutral1',
      ]}
      responsiveVariants={{
        largeish: ['column'],
        mid: ['padding:4'],
        small: ['padding:2'],
      }}
      style={styles.howWorkWrapper}
    >
      <View
        variants={['column', 'gap:3', 'relative']}
        responsiveVariants={{
          largeish: ['alignSelfStart'],
        }}
      >
        {howWorksSteps.map((item) => {
          const isSelected = item.id === selectedStep

          return (
            <View key={item.id}>
              <Touchable
                variants={['row', 'gap:2']}
                onPress={() => handleStepPress(item.id)}
                debugName='How it work touchable item'
              >
                {isSelected ? (
                  <ActionIcon
                    name={item.icon}
                    variants={['homepage', 'floating']}
                    style={styles.actionIcon}
                    debugName='ActionIcon item'
                  />
                ) : (
                  <View variants={['center']} style={styles.outerCircle}>
                    <View
                      variants={['backgroundColor:primary2']}
                      style={styles.innerCircle}
                    />
                  </View>
                )}

                <Text text={item.title} variants={['h3', isSelected ? 'color:primary4' : 'color:neutral8', 'medium']} />
              </Touchable>
            </View>
          )
        })}
        <View style={styles.stepsBar} />
      </View>

      <View
        variants={[
          'flex',
          'backgroundColor:neutral2',
          'border-radius:medium',
          'padding:4',
        ]}
        style={styles.contentWrapper}
      >
        {content}
      </View>
    </View>
  )
}

const CONTENT_MAX_WIDTH = 600

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    howWorkWrapper: {
      borderRadius: 24,
    },
    actionIcon: {
      zIndex: 1,
    },
    outerCircle: {
      width: 44,
      height: 44,
      minWidth: 44,
      backgroundColor: 'transparent',
      zIndex: 1,
    },
    innerCircle: {
      width: 12,
      height: 12,
      borderRadius: theme.borderRadius.rounded,
    },
    stepsBar: {
      position: 'absolute',
      top: 22,
      left: 21,
      bottom: 22,
      width: 2,
      height: 'calc(100% - 44px)',
      backgroundColor: theme.colors.primary2,
    },
    contentWrapper: {
      maxWidth: CONTENT_MAX_WIDTH,

      [theme.media.down('largeish')]: {
        maxWidth: '100%',
      },
    },
  }),
  true,
)
