import { OSAlert } from '@/components'
import { AppStatus } from '@/redux'
import { getFirebase } from '../../firebaseApp'
import { QueryKeys } from './queryKeys'
import { isEmailAvailable } from './views'
import { Profile } from '@/types'

export const codeLoginErrors = {
  ['auth/wrong-password']: 'Email or password is incorrect',
  ['auth/invalid-login-credentials']: 'Email or password is incorrect',
  ['auth/too-many-requests']: 'Access to this account has been temporarily disabled due to many failed login attempts.',
}

export function useLogin() {
  const login = async (data:Partial<Profile>) => {
    AppStatus.set('loading')

    try {
      const { email, password } = data

      const emailAvailable = await isEmailAvailable(email)

      if (emailAvailable) {
        AppStatus.set('idle')
        OSAlert.error({
          title: 'Login error',
          body: 'Could not find an account matching the specified email address',
        })
        return false
      }

      const firebase = await getFirebase()
      const userCredentials = await firebase.auth().signInWithEmailAndPassword(email.trim(), password.trim())

      console.log('userCredeentials', userCredentials)
      await QueryKeys.me.refresh()

      AppStatus.set('done')
      return true

    } catch (e) {
      AppStatus.set('idle')

      const errorMessage = codeLoginErrors?.[e?.code]

      if (!!errorMessage) {
        OSAlert.error({
          title: 'Login error',
          body: errorMessage,
        })
      }

      console.error('Error on login', e, 'Auth')
      return false
    }
  }

  return login
}
