import { assignTextStyle } from './Text'
import { ProgressBarComposition, ProgressBarPresets } from '@codeleap/web'
import { variantProvider } from '../theme'

const createProgressBarStyle =
  variantProvider.createVariantFactory<ProgressBarComposition>()

export const AppProgressBarStyles = {
  ...ProgressBarPresets,
  default: createProgressBarStyle((theme) => ({
    wrapper: {
      ...theme.presets.flex,
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      ...theme.spacing.gap(1),
    },
    progress: {
      ...theme.presets.flex,
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: theme.colors.neutral3,
      borderRadius: theme.borderRadius.rounded,
      transform: 'translateZ(0)',
      height: theme.spacing.base,
    },
    indicator: {
      backgroundColor: theme.colors.primary3,
      ...theme.presets.flex,
      ...theme.presets.fullHeight,
      transition: 'transform 600ms cubic-bezier(0.65, 0, 0.35, 1)',
    },
    text: {
      ...assignTextStyle('h5'),
      color: theme.colors.neutral7,
    },
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
      color: theme.colors.neutral7,
    },
  })),
}
