import { variantProvider } from '@/app'
import { Tag } from '@/components'
import { Dispute } from '@/types'
import { capitalize } from '@codeleap/common'
import { TagProps } from '@codeleap/web'

type DisputeStatusTagProps = Omit<TagProps, 'text' | 'debugName'> & {
  status: Dispute['status']
}

export function DisputeStatusTag({ status, styles, ...props }: DisputeStatusTagProps) {
  return (
    <Tag
      text={`${capitalize(status)}`}
      debugName={`DisputeStatusTag-${status}`}
      variants={['neutral'] as any}
      styles={{
        leftBadgeWrapper: _styles[status],
        ...styles,
      }}
      leftBadge={true}
      {...props}
    />
  )
}

const _styles = variantProvider.createComponentStyle(
  () => ({
    open: {
      backgroundColor: '#42C586',
    },
    closed: {
      backgroundColor: '#F33F3F',
    },
    pending: {
      backgroundColor: '#FA8700',
    },
    settled: {
      backgroundColor: '#0095D6',
    },
  }),
  true,
)
