import { AppForms, variantProvider } from '@/app'
import { AnyFunction, TypeGuards, useForm, useI18N } from '@codeleap/common'
import { AuthFormComponents, Button, Text, TextInput, View } from '@/components'
import { APIClient } from '@/services'
import { AppStatus } from '@/redux'
import { useOTP } from '@/utils'

type ForgotPasswordBeforeFormProps = {
  onRequest?: AnyFunction
}

export const ForgotPasswordBeforeForm = ({ onRequest }: ForgotPasswordBeforeFormProps) => {
  const form = useForm(AppForms.forgotPassword, {})
  const { t } = useI18N()

  const { requestPasswordReset } = APIClient.Session.useSession()

  const onRequestReset = async () => {
    await requestPasswordReset(form.values.email)
    if (TypeGuards.isFunction(onRequest)) onRequest()

    AppStatus.setModal(['forgotAfter', true])
  }

  const { sendCode } = useOTP({
    email: form.values.email,
    onSuccess: onRequestReset,
  })

  const handleSendCode = () => {
    AppStatus.setModal(['forgotBefore', false])
    sendCode()
  }

  return (
    <View variants={['flex', 'column']} >
      <Text
        variants={['h1', 'extraBold', 'marginBottom:1']}
        text={t('forgot.title')}
      />

      <Text
        variants={['p1', 'marginBottom:4']}
        text={t('forgot.description')}
        style={styles.description}
      />

      <TextInput
        {...form.register('email')}
        leftIcon={{ name: 'mail' }}
      />

      <View variants={['column', 'marginTop:2', 'gap:2']}>
        <Button
          text={t('actions.submit')}
          debugName='Request password reset'
          disabled={!form.isValid}
          onPress={handleSendCode}
          variants={['large', 'fullWidth', !form.isValid && 'outline']}
        />

        <AuthFormComponents.RedirectLink
          route={'Auth.Login'}
          textAction={t('actions.login')}
        />
      </View>
    </View>
  )
}

const styles = variantProvider.createComponentStyle((theme) => ({
  description: {
    letterSpacing: '-0.16px',
  },
}), true)

