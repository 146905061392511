import { AppForms, variantProvider } from '@/app'
import { APIClient } from '@/services'
import { useForm, useI18N } from '@codeleap/common'
import {
  Button,
  Text,
  TextInput,
  View,
  AuthFormComponents,
} from '@/components'
import { Navigation, useOTP } from '@/utils'
import { useKeydown } from '@codeleap/web'
import { AppStatus, useAppSelector } from '@/redux'

export const LoginForm = () => {
  const form = useForm(AppForms.login, {})
  const { t } = useI18N()
  const { toggleRememberInformation, profileQuery } =
    APIClient.Session.useSession()

  const { creatingDispute } = useAppSelector((store) => store.Dispute)

  const handleSubmit = async () => {
    try {
      const successLogin = await login({
        ...form.values,
        email: form?.values?.email?.toLowerCase(),
      })
      if (successLogin) {
        await profileQuery.refetch()
        onSuccess()
      }
    } catch (e) {
      logger.error(e)
    }
  }

  const { sendCode } = useOTP({
    email: form.values.email,
    onSuccess: handleSubmit,
  })

  const onSuccess = () => {
    if (creatingDispute) {
      Navigation.navigate('Disputes.CreateDispute', {
        params: { page: 'disputeValue' },
      })
      return
    }
    Navigation.navigate('Disputes.List')
  }

  /*const handleSocial = APIClient.Session.useSocialLogin((userSocial) => {
    if (userSocial) {
      onSuccess()
    }
  }, 'login')*/

  const login = APIClient.Session.useLogin()

  useKeydown(
    'Enter',
    () => {
      const isInvalidSubmit = !form.isValid
      if (isInvalidSubmit) return
      sendCode?.()
    },
    [form?.values],
  )

  return (
    <View variants={['column', 'fullWidth']}>
      <Text
        variants={['h1', 'extraBold', 'marginBottom:1']}
        responsiveVariants={{ small: ['h2'] }}
        text={t('Login.title')}
      />
      <Text variants={['p1', 'marginBottom:4']} text={t('Login.subtitle')} />

      <View variants={['flex', 'column', 'gap:4', 'fullWidth']}>
        <View variants={['column', 'fullWidth']}>
          <TextInput {...form.register('email')} />
          <TextInput {...form.register('password')} visibilityToggle />
          {/*<Checkbox
            {...form.register('remember')}
            onValueChange={(val) => {
              toggleRememberInformation(val)
              form.setFieldValue('remember', val)
            }}
            variants={['left']}
          />*/}
        </View>

        {/*<AuthFormComponents.SocialProviders onPress={handleSocial} />*/}

        <View variants={['column', 'gap:2']}>
          <Button
            onPress={sendCode}
            disabled={!form.isValid}
            debugName={`submit Login`}
            text={t('actions.continue')}
            variants={[
              'marginHorizontal:auto',
              'fullWidth',
              'large',
              !form.isValid && 'outline',
            ]}
            style={styles.submitBtn}
          />

          <AuthFormComponents.RedirectLink
            route={'Auth.Signup'}
            text={`${t('Login.txt1')}`}
            textAction={`${t('actions.signup')}`}
          />

          <Button
            onPress={() => AppStatus.setModal('forgotBefore')}
            debugName={'navigate to forgot password Button'}
            variants={[
              'marginHorizontal:auto',
              'fullWidth',
              'minimal',
              'large',
              'row',
              'gap:1',
              'alignCenter',
              'justifyCenter',
              'paddingHorizontal:2',
            ]}
            style={styles.submitBtn}
          >
            <Text
              text={t('Login.forgot')}
              variants={['medium', 'alignSelfCenter', 'row', 'color:primary3']}
            />
          </Button>
        </View>
      </View>
    </View>
  )
}

const MAX_WIDTH = 320

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    submitBtn: {
      maxWidth: MAX_WIDTH,
    },
  }),
  true,
)
