import React from 'react'
import { ActionIcon, Button, Tooltip, View } from '@/components'
import { useMemo } from 'react'
import {
  ComponentVariants,
  getNestedStylesByKey,
  useDefaultComponentStyle,
} from '@codeleap/common'
import { TooltipProps } from '@codeleap/web'
import { DisputeItemOptionsStyles } from '@/app/stylesheets'
import { Dispute } from '@/types'
import { APIClient } from '@/services'

export type DisputeItemOptionsProps = ComponentVariants<
  typeof DisputeItemOptionsStyles
> & {
  styles?: Partial<typeof DisputeItemOptionsStyles>
  style?: StyleSheet
  index?: number | string
  tooltipProps?: TooltipProps
  isMediator?: boolean
  dispute: Dispute
}

export function DisputeItemOptions(props: DisputeItemOptionsProps) {
  const { variants, responsiveVariants, styles, style, tooltipProps, isMediator = false, dispute, ...rest } =
    props

  const variantStyles = useDefaultComponentStyle<
    'u:DisputeItemOptions',
    typeof DisputeItemOptionsStyles
  >('u:DisputeItemOptions', {
    variants,
    styles,
    responsiveVariants,
  })

  const actionIconStyles = getNestedStylesByKey('actionIcon', variantStyles)
  const tooltipStyles = getNestedStylesByKey('tooltip', variantStyles)
  const optionButtonStyles = getNestedStylesByKey(
    'optionButton',
    variantStyles,
  )

  const { sendInvite } = APIClient.Disputes.useInvite({
    dispute,
    inviteRole: 'spectator',
    participant: dispute.second_party_info,
    party: 'second_party',
  })

  const renderOptions = useMemo(() => {
    return (
      <>
        <Button
          variants={['minimal']}
          styles={optionButtonStyles}
          text={'Mail to second party'}
          debugName={`SendEmailButton-${dispute.id}`}
          onPress={sendInvite}
        />
      </>
    )
  }, [])

  if (!isMediator) return null

  return (
    <View
      {...rest}
      css={[variantStyles.wrapper, style]}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        e.nativeEvent.stopImmediatePropagation()
      }}
    >
      <Tooltip
        {...tooltipProps}
        content={renderOptions}
        side='bottom'
        styles={tooltipStyles}
        closeOnClickOutside={true}
        openOnPress={true}
        openOnHover={false}
        debugName={`DisputeTooltip-${dispute.id}`}
      >
        <ActionIcon
          icon='more-horizontal'
          debugName={`DisputeItemActionIcon-${dispute.id}`}
          styles={actionIconStyles}
        />
      </Tooltip>
    </View>
  )
}
