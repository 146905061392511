import { api } from '@/app'
import { AppOptions, AppStatus } from '@/redux'

const BASE_URL = 'disputes/categories/'

export const fetchDisputeCategories = async () => {
  const response = await api.get(BASE_URL, {
    params: {
      limit: 100,
    },
  })
  AppOptions.setDisputeCategories(response.data.results)
  AppStatus.setFetchedDisputeCategories(true)
  return response.data
}
