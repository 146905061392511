import { variantProvider, Theme, React } from '@/app'
import {
  View,
  CenterWrapper,
  Drawer,
  Logo as _Logo,
  Link,
  ActionIcon,
  Navigator,
  AuthModals,
  NavigationLink,
  NavigationLinkProps,
  Text,
} from '@/components'
import { onUpdate, useMemo, useState } from '@codeleap/common'
import { APIClient } from '@/services'
import { useMediaQuery } from '@codeleap/web'
import { Navigation } from '@/utils'
import { useLocation } from '@reach/router'
import { AppStatus } from '@/redux'

const BREAKPOINT = 'mid'

const NavContent = ({ isMobile, isHome }) => {
  const { isLoggedIn, isMediator } = APIClient.Session.useSession()

  const items: Record<string, NavigationLinkProps> = {
    myDisputes: {
      title: 'My Disputes',
      icon: 'briefcase',
      to: 'Disputes.List',
    },
    profile: {
      title: 'Profile',
      icon: 'user',
      to: 'Profile.Edit',
    },
    contact: {
      title: 'Contact',
      onPress: () => AppStatus.setModal(['contact', true]),
      minimal: true,
      icon: isMobile ? 'phone' : null,
      styles: isMobile ? styles.contact as any : null,
    },
    newDispute: {
      title: 'New Dispute',
      icon: 'plus',
      to: 'Disputes.CreateDispute',
      minimal: true,
    },
    login: {
      title: 'Login',
      to: 'Auth.Login',
      minimal: true,
      styles: styles.login as any,
    },
    home: {
      title: 'Home',
      to: 'Home',
      minimal: true,
    },
    myDisputesMinimal: {
      title: 'My Disputes',
      to: 'Disputes.List',
      minimal: true,
    },
  }

  const navItems: NavigationLinkProps[] = useMemo(() => {
    if (!isMobile) {
      return [
        isLoggedIn ? (isHome ? items.myDisputesMinimal : items.home) : null,
        items.contact,
        !isLoggedIn ? items.login : null,
      ]
    }
    return [
      !isMediator && isLoggedIn ? items.newDispute : null,
      ...(isLoggedIn ? [items.profile, items.myDisputes] : [items.login]),
      items.contact,
    ]
  }, [isMobile, isLoggedIn, isMediator]).filter(Boolean)

  return (
    <View
      variants={['center', 'gap:2']}
      responsiveVariants={{
        [isHome ? 'small' : BREAKPOINT]: ['column', 'paddingVertical:2'],
      }}
    >
      {navItems.map((option, index) => (
        <NavigationLink {...option} key={index} />
      ))}
    </View>
  )
}

const Logo = () => (
  <Link to='/home'>
    <_Logo debugName='header logo' variants={['header']} styles={{ image: styles.image }} />
  </Link>
)

const DrawerMenu = ({ isMobile, isHome }) => {
  const [drawerOpen, setDrawer] = useState(false)

  const toggleDrawer = (to = !drawerOpen) => {
    if (to) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
    setDrawer(to)
  }

  onUpdate(() => {
    if (!isMobile && drawerOpen) toggleDrawer(false)
  }, [drawerOpen, isMobile])

  return (
    <>
      <Drawer
        open={drawerOpen}
        size='100vw'
        position='top'
        variants={['header']}
        toggle={toggleDrawer}
        title={<Logo />}
        showCloseButton
        debugName='header drawer'
      >
        <NavContent isMobile={isMobile} isHome={isHome} />
      </Drawer>

      <ActionIcon
        debugName='header menu action icon'
        icon='menu'
        variants={['marginLeft:auto', 'minimal', 'neutral10']}
        onPress={toggleDrawer}
      />
    </>
  )
}

export const _Header = () => {
  const { pathname } = useLocation()
  const isHome = Navigation.inHome(pathname)

  const mediaQuery = Theme.media.down(isHome ? 'small' : BREAKPOINT)
  const isMobile = useMediaQuery(mediaQuery, {
    getInitialValueInEffect: false,
  })

  return (
    <CenterWrapper
      styles={{
        wrapper: styles.wrapper,
        innerWrapper: styles.innerWrapper,
      }}
    >
      <View>
        <Logo />
        <Text
          variants={['p3', 'marginLeft:3']}
          responsiveVariants={{
            small: ['p4', 'marginLeft:2'],
          }}
          text={'Beta version'}
        />
      </View>
      {isMobile ? (
        <DrawerMenu isMobile={isMobile} isHome={isHome} />
      ) : (
        <View variants={['alignCenter']}>
          <NavContent isMobile={isMobile} isHome={isHome} />
        </View>
      )}
    </CenterWrapper>
  )
}

export function Header(props) {
  return (
    <>
      <Navigator.Container>
        <_Header {...props} />
      </Navigator.Container>
      <AuthModals />
    </>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      ...theme.spacing.paddingVertical(2),
      position: 'sticky',
      zIndex: 2,
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.colors.neutral1,
      backdropFilter: 'blur(4px)',
      borderBottom: `1px solid ${theme.colors.neutral3}`,
      [Theme.media.down('smallish')]: {
        ...theme.spacing.paddingVertical(1),
      },
    },
    innerWrapper: {
      ...theme.presets.flex,
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      ...theme.presets.justifySpaceBetween,
      height: theme.values.itemHeight.default,
      [Theme.media.down('smallish')]: {
        height: theme.values.itemHeight.default / 2,
      },
    },
    login: {
      wrapper: {
        backgroundColor: theme.colors.primary3,
        ...theme.spacing.paddingHorizontal(4),
      },
      text: {
        color: theme.colors.neutral10,
      },
    },
    contact: {
      text: {
        color: theme.colors.neutral8,
      },
    },
    image: {
      objectFit: 'contain',
      height: 22,
      [Theme.media.down('smallish')]: {
        height: 18,
      },
    },
  }),
  true,
)
