import { variantProvider } from '@/app'
import {
  View,
  Text,
  TextInput,
  ActionIcon,
  Button,
  CenterWrapper,
} from '@/components'
import { Dispute, useAppSelector } from '@/redux'
import { Navigation, useInterval, useMediaQueryDown } from '@/utils'
import { useI18N, useState } from '@codeleap/common'
import { AnimatePresence, motion } from 'framer-motion'

const BREAKPOINT = 'mid'

const Headline = ({ text, visible }) => {
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          key={text}
          transition={{ duration: 1 }}
          animate={{ opacity: 1, right: 0 }}
          initial={{ opacity: 0, right: 700 }}
          exit={{ opacity: 0, right: -700, dur: 2 }}
          style={{
            width: '100%',
            position: 'absolute',
            paddingLeft: '5%',
            paddingRight: '5%',
          }}
        >
          <Text
            text={text}
            variants={['hx', 'color:neutral1', 'textCenter']}
            responsiveVariants={{
              midish: ['h0', 'color:neutral1', 'textCenter'],
            }}
          />
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const Headlines = () => {
  const { t } = useI18N()
  const [index, setIndex] = useState(0)
  const isDownMid = useMediaQueryDown(BREAKPOINT)

  const headlines = [
    t('Homepage.banner.title2'),
    t('Homepage.banner.title3'),
    t('Homepage.banner.title4'),
    t('Homepage.banner.title6'),
    t('Homepage.banner.title8'),
    t('Homepage.banner.title10'),
    t('Homepage.banner.title11'),
    t('Homepage.banner.title12'),
    t('Homepage.banner.title14'),
  ]

  useInterval(() => {
    setIndex((state) => (state === headlines.length - 1 ? 0 : state + 1))
  }, 5000)

  return (
    <View
      variants={['column', 'fullWidth', 'marginBottom:3', 'justifyCenter']}
      style={{ minHeight: isDownMid ? 140 : 176 }}
    >
      {headlines.map((headline, headlineIndex) => (
        <Headline text={headline} visible={index === headlineIndex} />
      ))}
    </View>
  )
}

export const BannerSection = () => {
  const homeDescription = useAppSelector(
    (store) => store.Dispute.homeDescription,
  )

  const isDownMid = useMediaQueryDown(BREAKPOINT)
  const { t } = useI18N()

  const handleContinue = () => {
    Navigation.navigate('Disputes.CreateDispute', {
      params: { from: 'home', page: 'begin', clear: true },
    })
  }

  return (
    <CenterWrapper
      variants={['paddingVertical:10', 'center']}
      style={styles.bannerGradient}
    >
      <View variants={['fullWidth', 'column', 'center']} style={styles.banner}>
        <View variants={['fullWidth', 'column', 'center', 'marginBottom:5']}>
          <Headlines />
          <Text
            text={t('Homepage.banner.subtitle')}
            variants={['h4', 'color:neutral1', 'textCenter']}
            responsiveVariants={{
              midish: ['h5', 'color:neutral1', 'textCenter'],
            }}
          />
        </View>

        <View
          variants={[
            'fullWidth',
            'row',
            'gap:2',
            'padding:2',
            'backgroundColor:neutral1',
          ]}
          responsiveVariants={{
            small: [
              'padding:1',
              'border-radius:medium',
              'gap:1',
              'marginTop:auto',
            ],
          }}
          style={styles.inputWrapper}
        >
          <TextInput
            value={homeDescription}
            onChangeText={Dispute.setHomeDescription}
            placeholder={
              isDownMid
                ? 'Tell us more about your case'
                : 'Tell us more about your case or select a category below'
            }
            variants={['noError']}
            styles={{ input: styles.inputText }}
            debugName='Homepage input'
          />

          {isDownMid ? (
            <ActionIcon
              debugName='Homepage continue icon'
              onPress={handleContinue}
              name='arrow-right'
              variants={['large', 'neutral10', 'iconSize:3']}
              style={styles.continueIconWrapper}
            />
          ) : (
            <Button
              text={'Continue'}
              onPress={handleContinue}
              debugName={'Homepage continue button'}
            />
          )}
        </View>
      </View>
    </CenterWrapper>
  )
}

const HEADING_MAX_WIDTH = 800
const BANNER_FIXED_HEIGHT = 465

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    banner: {
      maxWidth: HEADING_MAX_WIDTH,
    },
    inputWrapper: {
      borderRadius: 24,
    },
    continueIconWrapper: {
      minWidth: 56,
    },
    bannerGradient: {
      background: 'linear-gradient(265deg, #49A674 10.83%, #6CAE75 89.36%)',
      [theme.media.down('small')]: {
        height: BANNER_FIXED_HEIGHT,
      },
    },
    fadeOut: {
      opacity: 0,
      transition: 'opacity 0.5s ease-in-out',
    },
    fadeIn: {
      opacity: 1,
      transition: 'opacity 0.5s ease-in-out',
    },
    inputText: {
      fontWeight: 400,
    },
  }),
  true,
)
