import { Tag } from '@/components'
import { Dispute } from '@/types'
import { TagProps } from '@codeleap/web'

type DisputeCategoryTagProps = Omit<TagProps, 'text' | 'debugName'> & {
  category: Dispute['category']
}

export function DisputeCategoryTag({
  category,
  ...props
}: DisputeCategoryTagProps) {
  if (!category) return null

  const { name } = category
  return (
    <Tag
      debugName={`DisputeCategoryTag-${name}`}
      text={`${name}`}
      variants={['neutral'] as any}
      {...props}
    />
  )
}
