import { ChatMessageStyles } from '@/app/stylesheets'
import { ChatMessageComposition } from '@/app/stylesheets/ChatMessage'
import { View, Avatar, Text, Icon } from '@/components'
import { APIClient } from '@/services'
import { Dispute, DisputeMessage } from '@/types'
import { DateUtils, useGetStyles } from '@/utils'
import { ComponentVariants, StylesOf, TypeGuards, useCallback, useDefaultComponentStyle } from '@codeleap/common'
import { Settlement } from './SettlementMessage'
import { ContentMessage } from './ContentMessage'

type MessageProps = ComponentVariants<typeof ChatMessageStyles> & {
  styles?: StylesOf<ChatMessageComposition>
  style?: StyleSheet
  message: DisputeMessage
  nextMessage: DisputeMessage
  prevMessage: DisputeMessage
  dispute: Dispute
}

export function ChatMessage({ message, nextMessage, prevMessage, dispute, ...props }: MessageProps) {
  const { variants, styles, responsiveVariants } = props
  const { owner, settlement } = message

  const { profile } = APIClient.Session.useSession()

  const isOwner = owner?.id === profile?.id
  const nxtMsgOwner = nextMessage?.owner?.id === owner?.id
  const prevMessageOwner = prevMessage?.owner?.id === owner?.id
  const deletedMessage = TypeGuards.isNil(message.owner)
  const tailSide = isOwner ? 'right' : 'left'

  const variantStyles = useDefaultComponentStyle<'u:ChatMessage', typeof ChatMessageStyles>('u:ChatMessage', {
    variants,
    styles,
    responsiveVariants,
  })

  const { getStyles: _getStyles } = useGetStyles(variantStyles)

  const getStyles = (key: string, extraKeys = null) => _getStyles(key, [
    isOwner && ':owner',
    nxtMsgOwner && !isOwner && ':nextMessageOwner',
    prevMessageOwner && ':prevMessageOwner',
    deletedMessage && ':deletedMessage',
    extraKeys && `:${extraKeys}`,
  ])

  const Content = useCallback(() => {
    if (deletedMessage) return <ContentMessage deleted getStyles={getStyles} />
    if (!TypeGuards.isNil(settlement)) return <Settlement dispute={dispute} getStyles={getStyles} settlement={settlement} isSignable={message?.signable} />
    return <ContentMessage message={message} getStyles={getStyles} showName={!(prevMessageOwner || isOwner)} />
  }, [message])

  if (!!settlement && TypeGuards.isNil(settlement.file)) return null

  return (
    <View css={variantStyles.wrapper}>
      {!DateUtils.isSameDay(prevMessage?.created_datetime, message?.created_datetime) && (
        <Text css={variantStyles.time} text={DateUtils.formatChatDate(message.created_datetime)} />
      )}
      <View css={[variantStyles.innerWrapper]}>
        <View css={variantStyles.separator}>
          {!(nxtMsgOwner || isOwner) && !TypeGuards.isNil(message.owner) && (
            <Avatar styles={getStyles('avatar')} name={owner?.first_name} debugName='avatar' />
          )}
        </View>
        <View css={getStyles('contentWrapper')}>
          <Icon name={`tail-${tailSide}` as any} debugName='tail message' style={getStyles('tail', tailSide)} />
          <Content />
        </View>
      </View>
    </View>
  )
}

export * from './SettlementMessage'
export * from './ContentMessage'
