import { ViewComposition, ViewPresets } from '@codeleap/web'
import { variantProvider } from '../theme'

const createViewStyle = variantProvider.createVariantFactory<ViewComposition>()

export const AppViewStyles = {
  ...ViewPresets,
  default: createViewStyle((t) => ({
    wrapper: {
      display: 'flex',
    },
  })),
  separator: createViewStyle((theme) => ({
    wrapper: {
      width: '100%',
      height: 2,
      backgroundColor: theme.colors.neutral5,

      [theme.media.down('mid')]: {
        height: 4,
      },
    },
  })),
  card: createViewStyle((t) => ({
    wrapper: {
      display: 'column',
      backgroundColor: t.colors.background,
      borderRadius: t.borderRadius.medium,
      border: `1px solid ${t.colors.neutral3}`,
      padding: t.spacing.value(4),
      [t.media.down('small')]: {
        ...t.spacing.padding(2),
      },
    },
  })),
  'border-none': createViewStyle(() => ({
    wrapper: {
      border: 'none',
    },
  })),
  'contentPageHeight': createViewStyle((theme) => ({
    wrapper: {
      minHeight: `calc(100vh - ${theme.values.itemHeight.default + theme.spacing.value(4)}px)`,
      [theme.media.down('small')]: {
        minHeight: `calc(100vh - 81px)`,
      },
      [theme.media.down('smallish')]: {
        minHeight: `calc(100vh - 41px)`,
      },
    },
  })),
  'disabled': createViewStyle((theme) => ({
    wrapper: {
      'pointerEvents': 'none',
      opacity: 0.3,
    },
  })),
}
