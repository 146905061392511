import React from 'react'
import { AppForms, variantProvider } from '@/app'
import { Alert, Button, DisputeDetailsPreview, DisputeForms, PageSection, View } from '@/components'
import { AppStatus } from '@/redux'
import { APIClient } from '@/services'
import { Dispute } from '@/types'
import { DisputeUtils, Navigation, useDisputeCategories, useMediaQueryDown, useRoleInDispute } from '@/utils'
import { deepEqual, onUpdate, useBooleanToggle, useForm } from '@codeleap/common'

export type DisputeDetailsPageSectionProps = {
  dispute?: Dispute
}

export const DisputeDetailsPageSection = (props: DisputeDetailsPageSectionProps) => {
  const { dispute } = props

  const { refresh } = APIClient.Disputes.disputesManager.useRetrieve({ id: dispute?.id })

  const [isDetailsPreview, toggleDetailPreview] = useBooleanToggle(true)
  const [alertVisible, toggleAlert] = useBooleanToggle(false)
  const { disputeCategory } = useDisputeCategories(dispute?.category?.type)
  const { isMediator } = useRoleInDispute(dispute)
  const isMobile = useMediaQueryDown('smallish')
  const initialState = {
    description: dispute?.description || '',
    category: disputeCategory ? DisputeUtils.formatDisputeCategories([disputeCategory]) : null,
    status: DisputeUtils.defaultStatusOptions.find(item => item.value === dispute?.status),
    price: dispute?.price,
    first_party_name: dispute?.first_party_info?.full_name,
    first_party_email: dispute?.first_party_info?.email,
    first_party_phone: dispute?.first_party_info?.phone ? dispute?.first_party_info?.phone : '',
    first_party_address: dispute?.first_party_info?.address ? dispute?.first_party_info?.address : '',
    second_party_name: dispute?.second_party_info?.full_name,
    second_party_email: dispute?.second_party_info?.email,
    second_party_phone: dispute?.second_party_info?.phone ? dispute?.second_party_info?.phone : '',
    second_party_address: dispute?.second_party_info?.address ? dispute?.second_party_info?.address : '',
  }

  const editDisputeForm = useForm(AppForms.editDispute, {
    initialState,
  })

  const onEditDispute = async () => {
    const areFieldsChanged = !deepEqual(initialState, editDisputeForm.values)
    if (areFieldsChanged) {
      const data = DisputeUtils.formatUpdateForm(dispute, editDisputeForm.values)
      AppStatus.set('loading')
      try {
        await APIClient.Disputes.disputesManager.options.updateItem(data)
        refresh()
        toggleDetailPreview(true)
      } catch (e) {
        logger.error('Error updating dispute', e, 'Dispute Details')
      }
      AppStatus.set('done')
    } else {
      toggleDetailPreview(true)
    }
  }

  const onDeleteDispute = async () => {
    AppStatus.set('loading')
    try {
      await APIClient.Disputes.disputesManager.options.deleteItem(dispute)
      Navigation.goToDashboard()
    } catch (e) {
      logger.error('Error deleting dispute', e, 'Dispute Details')
    }
    AppStatus.set('done')
  }

  onUpdate(() => {
    editDisputeForm.setFieldValue('category', disputeCategory ? DisputeUtils.formatDisputeCategories([disputeCategory]) : null)
  }, [dispute])

  const header = isDetailsPreview ? (
    <Button
      text={'Edit'}
      icon={'edit'}
      onPress={() => toggleDetailPreview(false)}
      variants={['minimal', 'small', isMobile ? 'pageSectionMobile' : '']}
      debugName='Edit dispute button'
    />
  ) : (
    <View variants={['gap:1']}>
      {!isMobile ? <Button
        text={'Cancel'}
        variants={['destructive:minimal', 'small']}
        onPress={() => toggleDetailPreview(true)}
        debugName='Edit Cancel button'
      /> : null}
      <Button
        text={'Save'}
        variants={['minimal', 'small', isMobile ? 'pageSectionMobile' : '']}
        onPress={onEditDispute}
        disabled={(deepEqual(initialState, editDisputeForm.values) || !editDisputeForm.isValid)}
        debugName='Edit Save button'
      />
    </View>
  )

  return (
    <PageSection
      title='Case details'
      onBack={() => isDetailsPreview ? Navigation.goToDispute({ id: dispute.id }) : toggleDetailPreview(true)}
      headerRightComponent={isMediator ? header : null}
      style={styles.wrapper}
    >
      {isDetailsPreview ? (
        <>
          <DisputeDetailsPreview dispute={dispute} onDelete={() => toggleAlert(true)} />
          <Alert
            title='Dispute Delete'
            body='Are you sure you want to delete this case? This action cannot be undone'
            visible={alertVisible}
            toggle={() => toggleAlert(false)}
            type={'warn'}
            debugName={'Delete dispute alert'}
            onAction={onDeleteDispute}
          />
        </>
      ) : (
        <DisputeForms.DisputeDetails dispute={dispute} form={editDisputeForm} />
      )}
    </PageSection>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {
      background: theme.colors.neutral2,
      ...theme.spacing.paddingBottom(3),
    },
  }),
  true,
)
