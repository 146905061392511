import { variantProvider } from '@/app'
import { DisputeTypeStyles } from '@/app/stylesheets'
import { DisputeTypeComposition } from '@/app/stylesheets/DisputeType'
import { Text, CardBase, CardBaseProps, View, Icon } from '@/components'
import { ComponentVariants, getNestedStylesByKey, StylesOf, useDefaultComponentStyle, useI18N } from '@codeleap/common'
import { IconProps } from '@codeleap/web'

export type DisputeTypeProps = ComponentVariants<typeof DisputeTypeStyles>
  & Omit<CardBaseProps, 'variants' | 'styles'>
  & {
    styles?: StylesOf<DisputeTypeComposition>
    title: string
    text: string
    icon?: IconProps['name']
  }

export const DisputeType = ({
  variants = [],
  //styles = {},
  responsiveVariants = {},
  title,
  text,
  icon = 'arrow-right',
  ...otherProps
}: DisputeTypeProps) => {
  const variantStyles = useDefaultComponentStyle<'u:DisputeType', typeof DisputeTypeStyles>('u:DisputeType', {
    variants,
    rootElement: 'wrapper',
    styles,
    responsiveVariants,
  })

  const { t } = useI18N()

  const cardStyles = getNestedStylesByKey('card', variantStyles)

  return (
    <CardBase
      styles={cardStyles}
      className={'disputeTypeCard'}
      {...otherProps}
    >
      <Text text={title} css={[variantStyles.title]} className={'disputeTypeCardText'}/>
      <Text text={text} css={[variantStyles.text]} className={'disputeTypeCardText'}/>

      <View css={[variantStyles.fakeButton]} className={'disputeTypeCardFakeButton'} >
        <Text text={t('Homepage.disputeTypes.button')} css={[variantStyles.fakeButtonText]} />
        <Icon name={icon} style={variantStyles.icon} debugName={`${title} icon`} />
      </View>
    </CardBase>
  )
}

const styles = variantProvider.createComponentStyle(theme => ({
  border: {
    border: '1px solid red',
    alignItems: 'center',
  },
  cardWrapper: {
    '&:hover .disputeTypeCardText': {
      color: theme.colors.neutral1,
    },
    '&:hover .disputeTypeCardFakeButton': {
      ...theme.presets.row,
    },
  },
}), true)
