import { variantProvider } from '../theme'
import { includePresets } from '@codeleap/common'

export type LogoComposition = 'wrapper' | 'image'

const createLogoStyle = variantProvider.createVariantFactory<LogoComposition>()

const presets = includePresets((s) => createLogoStyle(() => ({ wrapper: s })))

const LOGO_SPLASH_SIZE = 230

export const LogoStyles = {
  ...presets,
  default: createLogoStyle(() => ({
    wrapper: {
      userSelect: 'none',
    },
    image: {
      width: '100%',
      userSelect: 'none',
    },
  })),
  splash: createLogoStyle(() => ({
    wrapper: {
      width: LOGO_SPLASH_SIZE,
      cursor: 'default'
    },
    image: {
      width: LOGO_SPLASH_SIZE,
      objectFit: 'contain',
      cursor: 'default'
    },
  })),
  header: createLogoStyle((theme) => ({
    image: {
      width: 'auto',
    }
  }))
}
