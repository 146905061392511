import {
  ActionIconComposition,
  ButtonComposition,
  TooltipComposition,
} from '@codeleap/web'
import { variantProvider, assignTextStyle } from '..'

export type DisputeItemOptionsComposition =
  | 'wrapper'
  | `actionIcon${Capitalize<ActionIconComposition>}`
  | `tooltip${Capitalize<TooltipComposition>}`
  | `optionButton${Capitalize<ButtonComposition>}`

const createDisputeItemOptionsStyle =
  variantProvider.createVariantFactory<DisputeItemOptionsComposition>()

export const DisputeItemOptionsStyles = {
  default: createDisputeItemOptionsStyle((theme) => ({
    // actionIconMinimal
    actionIconIcon: {
      color: theme.colors.primary3,
    },
    'actionIconIcon:disabled': {
      color: theme.colors.neutral5,
    },
    actionIconTouchableWrapper: {
      backgroundColor: 'transparent',
      ...theme.spacing.padding(0),
    },
    'actionIconTouchableWrapper:disabled': {
      backgroundColor: 'transparent',
    },

    // tooltip
    tooltipWrapper: {
      zIndex: 10,
      ...theme.spacing.paddingVertical(1),
      backgroundColor: theme.colors.neutral1,
      ...theme.presets.elevated,
    },

    // optionButton
    optionButtonText: {
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral8,
    },
  })),
}
