import { createForm, yup } from '@codeleap/common'

export const SignDocument = createForm('signDocument', {
  full_name: {
    type: 'text',
    required: true,
    label: 'Full name',
    validate: yup.string().required(),
  },
  acceptDocument: {
    type: 'checkbox',
    required: true,
    validate: (val) => ({ isValid: val, message: 'You must agree with the document terms' }),
  },
  conditions: {
    type: 'checkbox',
    required: true,
    validate: (val) => ({ isValid: val, message: 'You must accept the conditions' }),
  },
})
