import { variantProvider } from '@/app'
import { View, Text, ActivityIndicator, Button, SettlementItem, ActionIcon } from '@/components'
import { APIClient } from '@/services'
import { Dispute } from '@/types'
import { DateUtils, FileUtils, MiscUtils } from '@/utils'
import { TypeGuards } from '@codeleap/common'
import React from 'react'

const Separator = () => {
  return <View variants={['backgroundColor:neutral3', 'marginVertical:2']} style={styles.separator} />
}

const DisputeDetailRow = ({ label, value: _value, isColumn = false }) => {
  const isValueArray = TypeGuards.isArray(_value)
  const valueArray = isValueArray ? _value.filter((item) => item?.trim() !== '' && !TypeGuards.isNil(item)) : [_value]
  const hasMultipleValues = isValueArray && valueArray?.length > 1

  return (
    <View variants={[isColumn ? 'column' : 'row', 'justifySpaceBetween']}>
      <Text text={label} variants={['p2', 'color:neutral7']} />

      {isValueArray && hasMultipleValues ? (
        <View variants={['column', 'gap:2', 'alignEnd']}>
          {valueArray.map((item, index) => (
            <Text key={index} text={item} variants={['p3', 'color:neutral7']} />
          ))}
        </View>
      ) : (
        <Text text={valueArray[0]} variants={['p3', 'color:neutral7', 'wordBreak-word']} />
      )}
    </View>
  )
}

type DetailWrapperProps = {
  column?: boolean
  fields?: string[]
  dispute: Dispute
  children?: JSX.Element
}

const DisputeDetailWrapper = (props: DetailWrapperProps) => {
  const { fields, column = false, dispute, children } = props

  const detailValues = {
    description: { label: 'About', value: dispute.description },
    first_party_info_name: { label: 'First party', value: dispute.first_party_info?.full_name },
    first_party_info_email: { label: 'Email', value: dispute.first_party_info?.email },
    second_party_info_name: { label: 'Second party', value: dispute.second_party_info?.full_name },
    second_party_info_email: { label: 'Email', value: dispute.second_party_info?.email },
    second_party_info_phone: { label: 'Phone number', value: dispute.second_party_info?.phone },
    second_party_info_address: { label: 'Address', value: dispute.second_party_info?.address },
    status: { label: 'Status', value: MiscUtils.capitalizeFirstChar(dispute.status) },
    id: { label: 'Case number', value: dispute.id },
    category: { label: 'Type', value: dispute.category?.name },
    price: { label: 'Value', value: `£ ${dispute.price}` },
    created_datetime: { label: 'Created on', value: DateUtils.formatFullDate(dispute.created_datetime) },
  }

  return (
    <View variants={['padding:2', 'column', 'border-radius:small', 'backgroundColor:neutral1', 'fullWidth']}>
      {fields?.map((field, index) => {
        const isFirstField = index === 0

        if (!detailValues[field]?.value) return null
        return (
          <>
            {!isFirstField && <Separator />}
            <DisputeDetailRow
              key={index}
              label={detailValues[field]?.label}
              value={detailValues[field]?.value}
              isColumn={column}
            />
          </>
        )
      })}
      {children}
    </View>
  )
}

type DisputeDetailsPreviewProps = {
  dispute: Dispute
  onDelete: () => void
}

export const DisputeDetailsPreview = (props: DisputeDetailsPreviewProps) => {
  const { dispute, onDelete } = props

  const { isMediator } = APIClient.Session.useSession()

  if (!dispute) {
    return (
      <View variants={['flex', 'center']}>
        <ActivityIndicator debugName='Dispute Details indicator' />
      </View>
    )
  }

  return (
    <View variants={['flex', 'column', 'gap:2']}>
      <DisputeDetailWrapper fields={['description']} dispute={dispute} column />

      <DisputeDetailWrapper fields={['first_party_info_name', 'first_party_info_email']} dispute={dispute} />

      <DisputeDetailWrapper
        fields={[
          'second_party_info_name',
          'second_party_info_email',
          'second_party_info_phone',
          'second_party_info_address',
        ]}
        dispute={dispute}
      />

      <DisputeDetailWrapper fields={['status', 'id', 'category', 'price', 'created_datetime']} dispute={dispute} />

      {isMediator && dispute.file ? (
        <DisputeDetailWrapper dispute={dispute}>
          <View variants={['column']}>
            <Text variants={['p2', 'neutral-7']} text={`Attachments`} />
            <SettlementItem
              isOnly
              hideOptions
              footer={
                <ActionIcon
                  name='download'
                  variants={['minimal']}
                  styles={styles.download}
                  onPress={() => FileUtils.download(dispute.file)}
                  debugName={'Download settlement file in Dispute details'}
                />
              }
              variants={['caseDetails']}
              settlement={{
                file: dispute.file,
                require_signature: false,
                signed: false,
              }}
            />
          </View>
        </DisputeDetailWrapper>
      ) : null}

      {isMediator ? (
        <Button
          text={'Delete case'}
          variants={['destructive:minimal', 'small', 'marginLeft:auto']}
          onPress={onDelete}
          debugName='Delete case in Case details'
        />
      ) : null}
    </View>
  )
}

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    separator: {
      height: 1,
    },
    download: {
      icon: {
        color: theme.colors.primary3,
      },
      touchableWrapper: {
        ...theme.sized(2.5),
      },
    },
  }),
  true,
)
