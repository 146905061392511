import { variantProvider } from '../theme'
import { includePresets } from '@codeleap/common'
import { CenterWrapperComposition } from './CenterWrapper'
import { FooterComposition } from './Footer'

export type PageComposition =
  'wrapper' |
  'innerWrapper' |
  'backgroundImage' |
  'innerWrapper:backgroundImage' |
  `content${Capitalize<CenterWrapperComposition>}` |
  `footer${Capitalize<FooterComposition>}`

const createPageStyle = variantProvider.createVariantFactory<PageComposition>()

const presets = includePresets((s) => createPageStyle(() => ({ wrapper: s })))

export const PageStyles = {
  ...presets,
  default: createPageStyle((theme) => ({
    wrapper: {
      ...theme.presets.column,
      minHeight: '100svh',
      width: '100%',
      backgroundColor: theme.colors.background,
    },
    'contentWrapper': {
      flex: 1,
      minHeight: '90svh',
      zIndex: 1,
    },
    'contentInnerWrapper': {
      ...theme.presets.column,
    },
    'innerWrapper': {
      ...theme.presets.column,
      ...theme.presets.fullWidth,
    },
    'innerWrapper:backgroundImage': {
      ...theme.presets.absolute,
    },
    backgroundImage: {
      width: '100vw',
      minHeight: '100svh',
      position: 'relative',
      objectFit: 'cover',
    },
  })),
  noPadding: createPageStyle((theme) => ({
    contentInnerWrapper: {
      paddingLeft: theme.spacing.value(0),
      paddingRight: theme.spacing.value(0),

      [theme.media.up('huge')]: {
        paddingLeft: theme.spacing.value(0),
        paddingRight: theme.spacing.value(0),
      },
      [theme.media.down('huge')]: {
        paddingLeft: theme.spacing.value(0),
        paddingRight: theme.spacing.value(0),
      },
      [theme.media.down('xxlarge')]: {
        paddingLeft: theme.spacing.value(0),
        paddingRight: theme.spacing.value(0),
      },
      [theme.media.down('large')]: {
        paddingLeft: theme.spacing.value(0),
        paddingRight: theme.spacing.value(0),
      },
      [theme.media.down('largeish')]: {
        paddingLeft: theme.spacing.value(0),
        paddingRight: theme.spacing.value(0),
      },
      [theme.media.down('mid')]: {
        paddingLeft: theme.spacing.value(0),
        paddingRight: theme.spacing.value(0),
      },
      [theme.media.down('small')]: {
        paddingLeft: theme.spacing.value(0),
        paddingRight: theme.spacing.value(0),
      },
    },
  })),
  selfStart: createPageStyle((theme) => ({
    contentWrapper: {
      alignItems: 'flex-start',
    },
  })),
  primary2: createPageStyle((theme) => ({
    contentWrapper: {
      background: theme.colors.primary1,
    },
  })),
}
